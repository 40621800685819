/// <reference types="../../../definitions/window.d.ts" />
/// <reference types="vite/client" />
import { ordered } from '@tablecheck/locales';
import i18next, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { SUPPORTED_LOCALES } from './locales';

const NAMESPACE = 'translations';

const options: InitOptions = {
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  supportedLngs: SUPPORTED_LOCALES,

  ns: [NAMESPACE],
  defaultNS: NAMESPACE,
  detection: {
    order: ['cookie', 'navigator', 'htmlTag', 'path'],
    lookupFromPathIndex: 0,
    lookupCookie: 'locale',
    cookieMinutes: 10080,
    caches: ['cookie'],
  },

  debug: process.env.NODE_ENV === 'development',

  lng: typeof window === 'undefined' ? undefined : window.initialLanguage,
  resources:
    typeof window === 'undefined' ? undefined : window.initialI18nStore,

  interpolation: {
    skipOnVariables: false,
    escapeValue: false,
    formatSeparator: ',',
    format: (value: string, format?: string) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },
  react: {
    useSuspense: false,
  },
};

class Client {
  public get instance(): typeof i18next {
    return i18next;
  }

  public async addResourceBundles(instance: typeof i18next): Promise<void> {
    for (const locale of ordered) {
      // eslint-disable-next-line no-await-in-loop
      const module = await import(`./locales/${locale.code}.json`);
      instance.addResourceBundle(locale.code, NAMESPACE, module.default);
    }
  }

  public init(): typeof i18next {
    i18next
      .use(initReactI18next)
      .use({
        type: 'backend',
        read(
          language: string,
          _namespace: string,
          callback: (errorValue: unknown, translations: unknown) => void,
        ) {
          import(`./locales/${language}.json`)
            .then((resource) => {
              // eslint-disable-next-line promise/no-callback-in-promise
              callback(null, resource.default);
            })
            .catch((error) => {
              // eslint-disable-next-line promise/no-callback-in-promise
              callback(error, null);
            });
        },
      })
      .use(LanguageDetector)
      .init(options)
      .catch((e) => {
        console.error(e);
      });

    return i18next;
  }
}

export const client = new Client();
