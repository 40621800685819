import { Close } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { IconButton } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import { CREDIT_CARD_ICONS } from '@local/assets';
import { ICON_SIZE_24 } from '@local/constants';
import { PaymentProfile } from '@local/types';

const Wrapper = styled.div`
  align-items: flex-start;
  border: 1px solid var(--border-transparent);
  border-radius: var(--border-radius-small);
  display: flex;
  gap: 10px;
  padding: var(--spacing-l2);
`;

const Image = styled.img`
  border: 1px solid var(--border-transparent);
  border-radius: var(--border-radius-small);
  height: 24px;
  opacity: 1;
  width: 34px;
`;

const Details = styled.div`
  display: flex;
  gap: var(--spacing-l1);
`;

const InnerWrapper = styled.div`
  flex-grow: 1;
`;

const PrimaryLabel = styled.span`
  background-color: #f5d8ff;
  border-radius: var(--border-radius-small);
  color: var(--primary);
  font-size: 12px;
  font-weight: 700;
  padding: 0 var(--spacing-l2);
`;

const ExpiryDate = styled.p`
  font: var(--body-2);
`;

const CloseButton = styled(IconButton)`
  background-color: var(--surface);
  border-radius: 50%;
  color: var(--error-text);
  padding: 0;
`;

interface Props {
  creditCard: PaymentProfile;
  onRemoveClick?: (creditCard: PaymentProfile) => void;
}

export function CreditCard({ creditCard, onRemoveClick }: Props) {
  const shouldShowRemoveButton = !!onRemoveClick;

  const expiryDateYear = creditCard.expiry_date.slice(2, 4); // expiry_date is in the format YYYY-MM-DD
  const expiryDateMonth = creditCard.expiry_date.slice(5, 7);

  return (
    <Wrapper>
      <Image src={`${CREDIT_CARD_ICONS}${creditCard.brand}.svg`} />
      <InnerWrapper>
        <Details>
          <strong>
            {t('account.credit_cards.details', {
              brand:
                creditCard.brand.charAt(0).toUpperCase() +
                creditCard.brand.slice(1),
              last_four_digits: creditCard.card_digits,
            })}
          </strong>
          {creditCard.is_primary && (
            <PrimaryLabel>{t('account.credit_cards.primary')}</PrimaryLabel>
          )}
        </Details>
        <ExpiryDate>
          {t('account.credit_cards.expires')} {expiryDateMonth}/{expiryDateYear}
        </ExpiryDate>
      </InnerWrapper>
      {shouldShowRemoveButton && (
        <CloseButton
          type="button"
          data-variant="bare"
          data-testid="Credit Card Remove Btn"
          onClick={() => onRemoveClick(creditCard)}
        >
          <Close size={ICON_SIZE_24} />
        </CloseButton>
      )}
    </Wrapper>
  );
}
