import { styled } from '@linaria/react';
import { ToastProvider } from '@radix-ui/react-toast';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { matchPath, Outlet, useLocation } from 'react-router-dom';

import { Toast } from '@local/components';
import { TABLET_BREAKPOINT } from '@local/constants';
import { useRouting } from '@local/utils';

import { DesktopFooter } from '../Footers/DesktopFooter';
import { MobileFooter } from '../Footers/MobileFooter';
import { DesktopHeader } from '../Headers/DesktopHeader';
import { MobileHeader } from '../Headers/MobileHeader';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  min-height: 100svh;

  &[data-is-desktop='true'] {
    max-width: var(--desktop-content-width);
    margin: 0 auto;
  }

  &[data-is-desktop='false'][data-is-explore='true'] {
    flex-direction: row;
    overflow: hidden;
  }

  &[data-max-width='true'] {
    max-width: 100%;
    /* NOTE: Below is to hide dropshadow on explore / list pages */
    header {
      box-shadow: none;
    }
  }
`;

const FlexWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

// Also shares with paths that should not display desktop footer
const MAXW_HEADER_PATHS = [`/lists/:listId`, '/:universe/search'];
export function Layout() {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const [, { language }] = useTranslation();
  const location = useLocation();

  const isMaxWidthHeader = MAXW_HEADER_PATHS.some((path) =>
    matchPath(`/${language}${path}`, location.pathname),
  );
  const isFooterVisible = !MAXW_HEADER_PATHS.some((path) =>
    matchPath({ path: `/${language}${path}` }, location.pathname),
  );

  const { isExplorePage } = useRouting();

  return (
    <ToastProvider>
      <Container
        data-is-explore={isExplorePage}
        data-is-desktop={isDesktop}
        data-max-width={isMaxWidthHeader}
      >
        {isDesktop ? (
          <>
            <DesktopHeader data-max-width={isMaxWidthHeader} />
            <FlexWrapper>
              <Outlet />
            </FlexWrapper>
            {isFooterVisible && <DesktopFooter />}
          </>
        ) : (
          <>
            <MobileHeader />
            <Outlet />
            <MobileFooter />
          </>
        )}
      </Container>
      <Toast />
    </ToastProvider>
  );
}
