import { LocaleCode } from '@tablecheck/locales';
import maplibregl from 'maplibre-gl';
import { Protocol } from 'pmtiles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';

import { Spinner } from '@local/components';
import { VALID_UNIVERSES } from '@local/constants';
import { SUPPORTED_LOCALES, useSyncRouteWithLocale } from '@local/i18n';
import { Layout } from '@local/layouts';
import { usePageViews } from '@local/utils';

import {
  AccountPage,
  CommunicationsRoot,
  CreditCardsRoot,
  EditAccountRoot,
  ExploreRoot,
  HomeRoot,
  ListsPage,
  ListViewRoot,
  NotFound,
  PaymentsRoot,
  ReservationsPage,
  VenueRoot,
} from '../pages';

import { Head } from './Head';
import { useHandleUniverse } from './hook/useHandleUniverse';

function LocaleValidator() {
  const { locale } = useParams();
  const [, { language }] = useTranslation();
  if (locale && !SUPPORTED_LOCALES.includes(locale as LocaleCode)) {
    return <Navigate to={`/${language}`} replace />;
  }
  return <Outlet />;
}

export function App() {
  const { search } = useLocation();
  const [, { language }] = useTranslation();
  usePageViews();
  useSyncRouteWithLocale();
  const { defaultUniverse } = useHandleUniverse();

  React.useEffect(() => {
    const protocol = new Protocol();
    maplibregl.addProtocol('pmtiles', protocol.tile);
    return () => {
      maplibregl.removeProtocol('pmtiles');
    };
  }, []);

  return (
    <>
      <Head />
      <Routes>
        <Route element={<Layout />}>
          <Route path=":locale" element={<LocaleValidator />}>
            {/* Universe routes */}
            {VALID_UNIVERSES.map((universeName: string) => (
              <React.Fragment key={universeName}>
                <Route path={universeName} element={<HomeRoot />} />
                <Route
                  path={`${universeName}/search`}
                  element={<ExploreRoot />}
                />
              </React.Fragment>
            ))}

            {/* Venue route */}
            <Route path=":venueSlug" element={<VenueRoot />} />

            {/* Account routes */}
            <Route path="account">
              <Route
                index
                element={<Navigate to={`profile${search}`} replace />}
              />
              <Route path="profile" element={<AccountPage />} />
              <Route path="edit" element={<EditAccountRoot />} />
              <Route path="payments" element={<PaymentsRoot />} />
              <Route path="credit-cards" element={<CreditCardsRoot />} />
              <Route path="favorites" element={<CommunicationsRoot />} />
              <Route path="reservations">
                <Route path="upcoming" element={<ReservationsPage />} />
                <Route path="past" element={<ReservationsPage />} />
              </Route>
            </Route>

            {/* Lists routes */}
            <Route path="lists">
              <Route index element={<ListsPage />} />
              <Route path=":listId" element={<ListViewRoot />} />
            </Route>

            {/* Search route without universe redirects to homepage */}
            <Route path="search" element={<Navigate to="/" replace />} />
          </Route>
        </Route>

        {/* Fallback for all others */}
        <Route
          path="/"
          element={
            defaultUniverse ? (
              <Navigate to={`/${language}/${defaultUniverse.name}`} />
            ) : (
              <Spinner isFullPage />
            )
          }
        />
        <Route path="/:locale/not-found" element={<NotFound />} />
      </Routes>
    </>
  );
}
