import { styled } from '@linaria/react';
import * as React from 'react';

import type { Venue } from './VenueCardContext';
import { VenueCardContext } from './VenueCardContext';
import { Budget } from './components/Budget';
import { DateTable } from './components/DateTable';
import { Description } from './components/Description';
import { Header } from './components/Header';
import { Info } from './components/Info';
import { SidePicture } from './components/SidePicture';
import { TagLine } from './components/TagLine';
import { TimeSlots } from './components/TimeSlots';
import { TopPicture } from './components/TopPicture';

const Wrapper = styled.div`
  background-color: white;
  cursor: pointer;
`;

const CardInfo = styled.div`
  display: flex;
  gap: var(--spacing-l3);

  &[data-vertical='true'] {
    flex-direction: column;
  }
`;

const CardText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font: var(--body-2);
  gap: var(--spacing-l1);
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
`;

interface VenueCardProps
  extends React.ComponentPropsWithoutRef<typeof Wrapper> {
  venue: Venue;
  onClick?: () => void;
}

export function VenueCard({
  venue,
  onClick,
  children,
  ...props
}: VenueCardProps) {
  const contextValue = React.useMemo(() => ({ venue }), [venue]);
  const childArray = React.Children.toArray(children);

  // Filter out specific sub-components so they won't be rendered twice in CardText.
  const filteredChildren: React.ReactNode[] = [];
  let sidePicture;
  let topPicture;
  let timeSlots;
  let dateTable;

  childArray.forEach((child) => {
    if (!React.isValidElement(child)) return;
    switch (child.type) {
      case VenueCard.SidePicture:
        sidePicture = child;
        break;
      case VenueCard.TopPicture:
        topPicture = child;
        break;
      case VenueCard.TimeSlots:
        timeSlots = child;
        break;
      case VenueCard.DateTable:
        dateTable = child;
        break;
      default:
        filteredChildren.push(child);
    }
  });

  return (
    <VenueCardContext.Provider value={contextValue}>
      <Wrapper {...props}>
        <CardInfo
          data-vertical={!!topPicture}
          onClick={onClick}
          data-testid="Common Venue Card"
        >
          {sidePicture}
          {topPicture}
          <CardText>{filteredChildren}</CardText>
        </CardInfo>
        {timeSlots ?? dateTable}
      </Wrapper>
    </VenueCardContext.Provider>
  );
}

// Attach sub-components to the VenueCard component
VenueCard.Header = Header;
VenueCard.Info = Info;
VenueCard.TagLine = TagLine;
VenueCard.Budget = Budget;
VenueCard.SidePicture = SidePicture;
VenueCard.TopPicture = TopPicture;
VenueCard.Description = Description;
VenueCard.TimeSlots = TimeSlots;
VenueCard.DateTable = DateTable;
