import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LIST_EMPTY_VIEW_IMG } from '@local/assets';
import { CollapsibleText, EmptyView } from '@local/components';
import { useUniverse } from '@local/utils';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l4);
  gap: var(--spacing-l4);

  & > a {
    margin-top: calc(
      -1 * var(--spacing-l4)
    ); // cancels out the gap for collapsible text
  }
`;

interface Props {
  title: string;
  description: string;
}

export function EmptyListView({ title, description }: Props) {
  const { universeName } = useUniverse();
  const [, { language }] = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper data-testid="Empty List View Wrapper">
      <h1>{title}</h1>
      <CollapsibleText content={description} />
      <EmptyView
        title={t('list_feat.add_venues_to_list')}
        description={t('list_feat.save_list_info_msg')}
        imgSrc={LIST_EMPTY_VIEW_IMG}
        imgAlt={t('list_feat.img_alt.hand_with_food')}
        ctaAction={() => navigate(`/${language}/${universeName}/search`)}
        ctaButtonText={t('list_feat.explore_venues')}
      />
    </Wrapper>
  );
}
