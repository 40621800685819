import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  JAPAN_UNIVERSE,
  SINGAPORE_UNIVERSE,
  VALID_UNIVERSES,
} from '@local/constants';
import { useFetchGeolocation } from '@local/search';
import { cookieService } from '@local/services';
import { Universe, UniverseSlug } from '@local/types';
import { useRouting } from '@local/utils';

const getUniverseFromPath = (pathname: string) => {
  const pathParts = pathname.split('/');
  const universePathPart = pathParts[2] as Universe['name'];
  if (!universePathPart || !VALID_UNIVERSES.includes(universePathPart)) {
    return null;
  }
  return universePathPart === VALID_UNIVERSES[0]
    ? JAPAN_UNIVERSE
    : SINGAPORE_UNIVERSE;
};

const isRootOrLocalePath = (pathname: string) => {
  const pathParts = pathname.split('/').filter(Boolean);
  return pathParts.length <= 1; // path with only locale or less
};

export function useHandleUniverse() {
  const routing = useRouting();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, { language }] = useTranslation();
  const universeFromPath = getUniverseFromPath(pathname);
  const existingUniverseCookie = cookieService.getUniverse();
  const { data: geolocation, isLoading } = useFetchGeolocation();
  const isAnUniversePage = routing.isExplorePage || routing.isHomepage;

  const universe = React.useMemo(() => {
    // Universe from path takes highest priority
    if (universeFromPath) {
      return universeFromPath;
    }
    // Existing cookie takes second priority
    if (existingUniverseCookie) {
      return existingUniverseCookie === UniverseSlug.JP
        ? JAPAN_UNIVERSE
        : SINGAPORE_UNIVERSE;
    }

    // Geolocation-based default
    return geolocation?.country === UniverseSlug.SG
      ? SINGAPORE_UNIVERSE
      : JAPAN_UNIVERSE;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universeFromPath, existingUniverseCookie, geolocation, isLoading]);

  React.useEffect(() => {
    if (isLoading && !isAnUniversePage) return;

    // Path universe takes priority over cookie
    if (universeFromPath && existingUniverseCookie !== universeFromPath.slug) {
      cookieService.setUniverse(universe.slug);
      return;
    }

    // Add cookie if missing and if it is an universe page, redirect to the default universe
    if (!existingUniverseCookie) {
      cookieService.setUniverse(universe.slug);
      if (isAnUniversePage)
        navigate(`/${language}/${universe.name}`, { replace: true });
    }

    // Fallback URLs, redirect to the default universe
    if (isRootOrLocalePath(pathname)) {
      navigate(`/${language}/${universe.name}`, { replace: true });
    }
  }, [
    pathname,
    universe,
    navigate,
    language,
    routing,
    isLoading,
    existingUniverseCookie,
    geolocation,
    isAnUniversePage,
    universeFromPath,
  ]);

  return { defaultUniverse: universe };
}
