import { ChevronLeft, Close, Search } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { TABLET_BREAKPOINT } from '@local/constants';

import { useSearchInput } from '../../hooks/useSearchInput';
import { SearchSuggestions } from '../SearchSuggestions/SearchSuggestions';
import { SuggestionItem } from '../SearchSuggestions/SuggestionItem';

const SearchBarForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
  padding: 0 var(--spacing-l4);
  z-index: var(--zindex-card);
  width: auto;
  &[data-is-desktop='true'] {
    width: var(--search-input-width);
  }
`;
const SearchBarInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--spacing-l3);
  gap: var(--spacing-l2);
  height: 48px;
  background: var(--grey-150);
  border: 1px solid var(--grey-150);
  border-radius: var(--border-radius-small);
  button {
    color: var(--text);
  }
  &:hover {
    background: var(--grey-150);
    border: 1px solid var(--border-transparent);
  }
  z-index: var(--z-index-1);
  &[data-is-mobile='true']:not([data-no-shadow='true']) {
  }
  &[data-is-mobile='false'][data-has-dropdown='true'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &[data-has-focus='true'] {
    background: var(--surface);
    border: 1px solid var(--border);
  }
`;
const Input = styled.input`
  width: 100%;
  border: none;
  font-size: 14px;
  height: 35px;
  background: none;
  &::placeholder {
    color: var(--grey-600);
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
`;
const IconWrapper = styled.button`
  cursor: pointer;
  height: 100%;
  align-items: center;
  display: flex;
`;
const DesktopResult = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  top: calc(48px + var(--spacing-l4));
  transform: translateX(var(--spacing-l4));
  z-index: var(--zindex-blanket);
  min-width: 400px;
  border: 1px solid var(--border-transparent);
  border-top: 0;
  border-radius: var(--border-radius-large);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow-y: auto;
  max-height: 50svh;
  width: calc(var(--search-input-width) - 32px);
`;
const MobileResult = styled.div`
  overflow-y: auto;
  max-height: 100svh;
  margin-top: var(--spacing-l3);
  padding-bottom: calc(var(--mobile-footer-height) * 2.5);
`;

const Wrapper = styled.div`
  z-index: 1;
  width: auto;
`;

interface Props {
  dataTestId?: string;
  hasNoShadow?: boolean;
}

export const SearchInput = React.forwardRef<HTMLDivElement, Props>(
  (props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { dataTestId, hasNoShadow } = props;
    const isDesktop = useMediaQuery({
      query: `(min-width: ${TABLET_BREAKPOINT})`,
    });
    const {
      searchVal,
      handleReset,
      onBackClick,
      setSearchVal,
      handleSubmit,
      setSearchView,
      isInputFocused,
      shouldShowDropDown,
      searchSuggestionsQuery,
      shouldShowPlainTextSearch,
      onPlainTextSuggestionClick,
      shouldShowSearchSuggestions,
    } = useSearchInput();

    const shouldShowBackArrow = !isDesktop && isInputFocused;

    const resultContent = () => (
      <>
        {shouldShowPlainTextSearch && (
          <SuggestionItem
            onClick={onPlainTextSuggestionClick}
            description={t('search.search_for')}
            title={`"${searchVal}"`}
            iconName="prompt"
          />
        )}
        {shouldShowSearchSuggestions && (
          <SearchSuggestions
            isLoading={
              searchSuggestionsQuery.status === 'pending' ||
              searchSuggestionsQuery.fetchStatus === 'fetching'
            }
            suggestions={searchSuggestionsQuery.data?.suggestions}
          />
        )}
      </>
    );

    return (
      <Wrapper data-testid={dataTestId} ref={ref}>
        <SearchBarForm
          data-testid="Search Bar Form"
          data-is-desktop={isDesktop}
          onSubmit={handleSubmit}
        >
          <SearchBarInputWrapper
            data-has-focus={isInputFocused}
            data-is-mobile={!isDesktop}
            data-has-dropdown={shouldShowDropDown}
            data-no-shadow={hasNoShadow}
          >
            <IconWrapper>
              {shouldShowBackArrow ? (
                <ChevronLeft
                  size="20px"
                  onClick={(e) => {
                    e.preventDefault();
                    onBackClick();
                  }}
                  data-testid="Search Input Back Btn"
                />
              ) : (
                <Search size="20px" />
              )}
            </IconWrapper>
            <Input
              data-testid="Search Bar Input"
              name="search_text"
              placeholder={t('search.placeholder')}
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              onFocus={() => {
                setSearchView('focused');
              }}
            />
            {searchVal.length > 0 && (
              <IconWrapper onClick={handleReset}>
                <Close size="20px" />
              </IconWrapper>
            )}
          </SearchBarInputWrapper>
        </SearchBarForm>

        {shouldShowDropDown && (
          <>
            {isDesktop ? (
              <DesktopResult data-search-results>
                {resultContent()}
              </DesktopResult>
            ) : (
              <MobileResult>{resultContent()}</MobileResult>
            )}
          </>
        )}
      </Wrapper>
    );
  },
);
