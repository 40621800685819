import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { Avatar, MenuOptions, TermsConditionsContent } from '@local/account';
import { TABLECHECK_LOGO } from '@local/assets';
import { AuthContent, useGetUserQuery } from '@local/auth';
import { Panel, panelStateAtom, Spinner } from '@local/components';
import { AUTH_PANEL_STATES, TABLET_BREAKPOINT } from '@local/constants';

const AccountTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: xx-large;
`;

const AccountPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
  padding: var(--spacing-l4);
`;

const InfoSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-l5);
  gap: var(--spacing-l4);
  border: 1px lightgray solid;
  border-radius: var(--border-radius-large);

  & > img {
    width: 136px;
    height: 17px;
  }
  & > p {
    text-align: center;
    font: var(--body-1);
  }
  & > div {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-l4);
  }
`;

export function AccountPage() {
  const [, { language }] = useTranslation();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const { data: user, isLoading: userIsLoading } = useGetUserQuery();

  React.useEffect(() => {
    // this page doesn't exist on desktop, so redirect to edit account page
    if (isDesktop) navigate(`/${language}/account/edit`);
  }, [isDesktop, user, navigate, language]);

  if (userIsLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <AccountPageWrapper data-testid="Account Page Root">
        <AccountTitleWrapper>
          <Title>{t('account.title')}</Title>
        </AccountTitleWrapper>
        {!user ? (
          <InfoSection>
            <img src={TABLECHECK_LOGO} alt="TableCheck" />
            <p>{t('account.discover_and_book')}</p>
            <div>
              <Button
                onClick={() => {
                  setPanelState('login');
                }}
                data-testid="Account Proceed To Login Btn"
              >
                {t('auth.login')}
              </Button>
              <Button
                onClick={() => {
                  setPanelState('sign_up');
                }}
                data-testid="Account Proceed To Signup Btn"
                data-variant="tertiary"
              >
                {t('auth.sign_up')}
              </Button>
            </div>
          </InfoSection>
        ) : (
          <Avatar />
        )}
        <MenuOptions
          onTermsConditionsClick={() => {
            setPanelState('terms_conditions');
          }}
        />
      </AccountPageWrapper>

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {AUTH_PANEL_STATES.includes(panelState) && <AuthContent />}
        {panelState === 'terms_conditions' && (
          <TermsConditionsContent onClose={() => setPanelState(null)} />
        )}
      </Panel>
    </>
  );
}
