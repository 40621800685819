import { useAtom, useSetAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import {
  ChangePasswordContent,
  DeleteAccountContent,
  DeleteMembershipContent,
  DeleteSocialAccountContent,
  TermsConditionsContent,
} from '@local/account';
import { useGetUserQuery } from '@local/auth';
import {
  Panel,
  panelStateAtom,
  Spinner,
  toastContentAtom,
  toastIsOpenAtom,
  useToast,
} from '@local/components';
import { TABLET_BREAKPOINT } from '@local/constants';
import type { MembershipInfo, SocialAuthInfo } from '@local/types';
import { useUniverse } from '@local/utils';

import { DesktopContentWrapper } from '../DesktopContentWrapper';
import { MobileContentWrapper } from '../MobileContentWrapper';

import { ProfileContent } from './ProfileContent';

export function EditAccountRoot() {
  const { universeName } = useUniverse();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const setIsToastOpen = useSetAtom(toastIsOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const [selectedMembership, setSelectedMembership] =
    React.useState<MembershipInfo | null>(null);
  const [selectedSocialAuth, setSelectedSocialAuth] =
    React.useState<SocialAuthInfo | null>(null);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const navigate = useNavigate();
  const [t, { language }] = useTranslation();
  const { successToastContent } = useToast();
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();

  const handleOnSuccess = (content: string) => {
    setToastContent(successToastContent(content));
    setIsToastOpen(true);
  };

  React.useEffect(() => {
    if (!user && !isUserLoading) {
      navigate(`/${language}/${universeName}`);
    }
  }, [user, isUserLoading, navigate, language, universeName]);

  if (isUserLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <div data-testid="Edit Account Page Root">
        {isDesktop ? (
          <DesktopContentWrapper title={t('account.profile')}>
            <ProfileContent
              onSuccess={handleOnSuccess}
              setSelectedMembership={setSelectedMembership}
              setSelectedSocialAuth={setSelectedSocialAuth}
            />
          </DesktopContentWrapper>
        ) : (
          <MobileContentWrapper title={t('account.profile')}>
            <ProfileContent
              onSuccess={handleOnSuccess}
              setSelectedMembership={setSelectedMembership}
              setSelectedSocialAuth={setSelectedSocialAuth}
            />
          </MobileContentWrapper>
        )}
      </div>

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {panelState === 'terms_conditions' && (
          <TermsConditionsContent onClose={() => setPanelState(null)} />
        )}
        {panelState === 'change_pwd' && (
          <ChangePasswordContent
            email={user?.customer_user.email || ''}
            onSuccess={() => {
              handleOnSuccess(t('toast.updated_password'));
              setPanelState(null);
            }}
            onCancel={() => setPanelState(null)}
          />
        )}
        {panelState === 'delete_account' && (
          <DeleteAccountContent onCancel={() => setPanelState(null)} />
        )}
        {panelState === 'disconnect_social' && selectedSocialAuth && (
          <DeleteSocialAccountContent
            selectedSocialAuth={selectedSocialAuth}
            onSuccess={() => {
              handleOnSuccess(t('toast.deleted_social'));
              setPanelState(null);
            }}
            onCancel={() => {
              setPanelState(null);
              setSelectedSocialAuth(null);
            }}
          />
        )}
        {panelState === 'delete_membership' && selectedMembership && (
          <DeleteMembershipContent
            selectedMembership={selectedMembership}
            onSuccess={() => {
              handleOnSuccess(t('toast.deleted_membership'));
              setPanelState(null);
            }}
            onCancel={() => {
              setPanelState(null);
              setSelectedMembership(null);
            }}
          />
        )}
      </Panel>
    </>
  );
}
