import { CalendarAdd, Calendar as CalendarIcon } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { DEFAULT_ICON_SIZE } from '@local/constants';
import type { AvailabilityFormInputsType } from '@local/types';

import { Calendar } from '../Calendar/Calendar';
import { Chip } from '../Chip/Chip';
import { Panel } from '../Panel/Panel';

import {
  CalendarWrapper,
  CustomFooter,
  Form,
  InputWrapper,
  StyledSelect,
} from './AvailabilityChecker.styles';
import { useAvailChecker } from './useAvailChecker';

const FilterChip = styled(Chip)`
  &[data-has-value='true'] {
    background-color: var(--surface-raised-active);
    color: var(--text);
    border: 1px solid var(--primary);
  }
`;

interface AvailabilityFilterProps {
  values: AvailabilityFormInputsType;
  onSubmit: (data: AvailabilityFormInputsType) => void;
  onReset: () => void;
  containerId?: string;
}

export function AvailabilityChecker({
  values,
  onSubmit,
  onReset,
  containerId,
}: AvailabilityFilterProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    register,
    getValues,
    setValue,
    control,
    handleSubmit,
    reset,
    ...restProps
  } = useForm<AvailabilityFormInputsType>({ values });

  const {
    date,
    time,
    numPeople,
    handleFormSubmit,
    handleDateSelected,
    handleClose,
    handleReset,
    hasValue,
    chipContent,
    timeSelectProps,
    paxSelectProps,
  } = useAvailChecker({
    values,
    setValue,
    setIsOpen,
    onSubmit,
    onReset,
    reset,
    control,
    isOpen,
  });

  return (
    <>
      <FilterChip
        onClick={() => setIsOpen(true)}
        data-has-value={hasValue}
        data-testid="Availability Filter"
        data-size="small"
      >
        {hasValue ? (
          <CalendarIcon fill="var(--text)" size={DEFAULT_ICON_SIZE} />
        ) : (
          <CalendarAdd fill="var(--text)" size={DEFAULT_ICON_SIZE} />
        )}
        <span>{chipContent}</span>
      </FilterChip>

      {isOpen && (
        <Panel
          isOpen={isOpen}
          onClose={() => {
            handleClose();
            setIsOpen(false);
          }}
          title={t('availability.when_dine')}
          containerId={containerId}
        >
          <FormProvider
            {...{
              register,
              getValues,
              setValue,
              control,
              handleSubmit,
              reset,
              ...restProps,
            }}
          >
            <Form
              onSubmit={(event) => void handleSubmit(handleFormSubmit)(event)}
            >
              <CalendarWrapper>
                <input type="date" {...register('date')} hidden />
                <Calendar
                  date={date ? new Date(date) : undefined}
                  minDate={new Date()}
                  onDateSelected={handleDateSelected}
                  shouldCenterHeader
                  monthsToDisplay={12}
                />
              </CalendarWrapper>

              <InputWrapper>
                <input type="time" {...register('time')} hidden />
                <StyledSelect
                  {...timeSelectProps}
                  id={`time-${time}`}
                  data-time={time}
                />

                <input {...register('num_people')} hidden />
                <StyledSelect {...paxSelectProps} id={`pax-${numPeople}`} />
              </InputWrapper>
              <CustomFooter>
                <Button
                  type="submit"
                  data-variant="primary"
                  data-testid="Availability Filter Submit"
                >
                  {t('availability.find_venues')}
                </Button>
                <Button
                  type="button"
                  onClick={handleReset}
                  data-variant="ghost"
                  data-testid="Availability Explore All Btn"
                >
                  {t('availability.explore_venues')}
                </Button>
              </CustomFooter>
            </Form>
          </FormProvider>
        </Panel>
      )}
    </>
  );
}
