import { CONFIG } from '@local/configs';
import { apiService } from '@local/services';
import type {
  CuisinesApiSuccessResponseType,
  GeolocationResponseType,
  SearchSuggestionsResponseType,
  ShopSearchApiSuccessResponseType,
} from '@local/types';
import { Universe } from '@local/types';

// TODO: Current api takes too long and times out for getting availability dates, remove in DPOR-754
const shopSearchApi = apiService.extend({
  timeout: 20_000,
});

export const fetchSearchSuggestions = async (
  searchText: string,
  locale: string,
  universeId: Universe['id'],
) => {
  try {
    const data: SearchSuggestionsResponseType = await apiService
      .get(`v2/autocomplete`, {
        searchParams: {
          locale,
          text: searchText,
          shop_universe_id: universeId,
        },
        prefixUrl: CONFIG.VITE_BASE_SEARCH_AUTOCOMPLETE_API_URL,
      })
      .json();
    return data;
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const fetchShopBySearchParams = async (
  queryParams: URLSearchParams,
  universeId: Universe['id'],
): Promise<ShopSearchApiSuccessResponseType | null> => {
  try {
    const query = new URLSearchParams(queryParams);
    if (query.get('search_text')) {
      query.set('name', query.get('search_text')!);
      query.delete('search_text');
    }
    if (!query.get('shop_universe_id')) {
      query.set('shop_universe_id', universeId as string);
    }
    if (!query.get('include_ids')) {
      query.set('include_ids', 'true');
    }
    const data: ShopSearchApiSuccessResponseType = await shopSearchApi
      .get(`v2/shop_search`, {
        searchParams: query,
      })
      .json();

    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchCuisineList =
  async (): Promise<CuisinesApiSuccessResponseType> => {
    try {
      const data: CuisinesApiSuccessResponseType = await apiService
        .get(`v2/cuisines`)
        .json();
      return data;
    } catch (e) {
      console.error(e);
      return { cuisines: [] };
    }
  };

export const fetchGeolocation =
  async (): Promise<GeolocationResponseType | null> => {
    try {
      const data: GeolocationResponseType = await apiService
        .get(`v2/geolocation`)
        .json();
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  };
