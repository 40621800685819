import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';

import { deleteSocialAuth } from '../accountApi';

export const useDeleteSocialAuthQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSocialAuth,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY, 'social_auths'],
      });
    },
    onError: (error) => console.error(error),
  });
};
