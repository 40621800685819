import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import type { VenueFavorites } from '@local/types';

import { updateUserFavorites } from '../userApi';

export const useUpdateUserFavoritesQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      venueSlug: VenueFavorites['shop_slug'];
      isSubscribed: VenueFavorites['is_subscribed'];
    }) => updateUserFavorites({ data }),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [USER_QUERY_KEY, 'favorites'] })
        .catch(console.error);
    },
    onError: (error) => {
      console.error('Error updating user favorites:', error);
    },
  });
};
