import { styled } from '@linaria/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_MENU_ITEM_IMAGE } from '@local/assets';
import { Panel, Price } from '@local/components';
import type { MenuItem as MenuItemType } from '@local/types';
import { translate } from '@local/utils';

import { MenuItem } from './MenuItemContent';

const MenuItemWrapper = styled.div`
  width: 100%;
  max-height: -webkit-fill-available;
  display: grid;
  grid-template-columns: 0.3fr minmax(0, 1fr);
  margin-bottom: var(--spacing-l4);
  cursor: pointer;
`;

const ItemImg = styled.img`
  border-radius: var(--border-radius-small);
  object-fit: cover;
  height: 64px;
  max-width: -webkit-fill-available;
  min-width: -webkit-fill-available;
`;

const ItemDetails = styled.div`
  padding: 0px var(--spacing-l2);
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-l2);
  color: black;
  > div {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const Description = styled.div`
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  color: grey;
`;

export function MenuItemCard({ item }: { item: MenuItemType }): JSX.Element {
  const [, { language }] = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <MenuItemWrapper
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <ItemImg src={item.image_urls.original || DEFAULT_MENU_ITEM_IMAGE} />
        <ItemDetails>
          <ItemHeader>
            <div>{translate(item.name_translations, language)}</div>
            <Price amount={item.price} />
          </ItemHeader>

          <Description>
            {translate(item.description_translations, language)}
          </Description>
        </ItemDetails>
      </MenuItemWrapper>

      <Panel
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <MenuItem
          item={item}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </Panel>
    </>
  );
}
