import { LocaleCode } from '@tablecheck/locales';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { USER_QUERY_KEY } from '@local/constants';
import { cookieService } from '@local/services';
import type { LoginDataType } from '@local/types';

import { getUser, login } from '../authApi';

export const useLoginQuery = () => {
  const [, { language }] = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: LoginDataType) =>
      login({ ...data, locale: language as LocaleCode }),
    onSuccess: (authToken) => {
      cookieService.setAuthToken(authToken);
      void queryClient.prefetchQuery({
        queryKey: [USER_QUERY_KEY],
        queryFn: () => getUser(),
        staleTime: Infinity,
      });
    },
    onError: (error) => console.error(error),
  });
};
