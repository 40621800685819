import { t } from 'i18next';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { useGetUserQuery } from '@local/auth';
import {
  panelStateAtom,
  toastContentAtom,
  toastIsOpenAtom,
  useToast,
} from '@local/components';
import { TABLET_BREAKPOINT } from '@local/constants';
import {
  useCreateListQuery,
  useSaveOrUnsaveShopToListQuery,
} from '@local/list';
import type {
  CreateListData,
  SaveListsRequest,
  VenueFavorites,
  VenueInfo,
} from '@local/types';
import {
  useDeleteUserMemoQuery,
  useFetchUserFavoritesVenueQuery,
  useUpdateUserFavoritesVenueQuery,
  useUpdateUserMemoQuery,
} from '@local/user';
import { translate } from '@local/utils';

export const useActionBtns = (
  venueData: VenueInfo,
  setOnLoginSubmit: (onLoginSubmit: () => void) => void,
) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const [, setPanelState] = useAtom(panelStateAtom);
  const [, { language }] = useTranslation();
  const setIsToastOpen = useSetAtom(toastIsOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const {
    _id: id,
    slug,
    name_translations,
    google_place_ref,
    geocode,
  } = venueData;

  const {
    saveVenueToastContent,
    updateMemoToastContent,
    createVenueToastContent,
    subscribeVenueToastContent,
    errorToastContent,
  } = useToast();

  const { data: user } = useGetUserQuery();
  const { refetch } = useFetchUserFavoritesVenueQuery(slug, user);
  const { mutate: updateFavorites } = useUpdateUserFavoritesVenueQuery(slug);
  const { mutate: createList } = useCreateListQuery();
  const { mutate: saveOrUnsaveShopToList } = useSaveOrUnsaveShopToListQuery();
  const { mutate: saveUserMemo } = useUpdateUserMemoQuery(slug);
  const { mutate: deleteMemo } = useDeleteUserMemoQuery(slug);

  const onSubmitMemo = (memo: string) =>
    saveUserMemo(memo, {
      onSuccess: () => {
        setPanelState(null);
        setToastContent(updateMemoToastContent('save_memo'));
        setIsToastOpen(true);
      },
    });

  const onDeleteMemo = () =>
    deleteMemo(undefined, {
      onSuccess: () => {
        setPanelState(null);
        setToastContent(updateMemoToastContent('delete_memo'));
        setIsToastOpen(true);
      },
    });

  return {
    handleSave: () => {
      const onSaveClick = () => setPanelState('save_list');

      if (user) {
        onSaveClick();
      } else {
        setOnLoginSubmit(() => onSaveClick);
        setPanelState('login');
      }
    },
    handleSubscribe: (data: Partial<VenueFavorites>) => {
      const onSubscribeClick = () =>
        updateFavorites(data, {
          onSuccess: (response) => {
            setToastContent(
              subscribeVenueToastContent(
                response.favorites[0].is_subscribed,
                translate(name_translations, language),
              ),
            );
            setIsToastOpen(true);
            if (!user) setPanelState(null);
          },
        });

      if (user) {
        onSubscribeClick();
      } else {
        setOnLoginSubmit(() => onSubscribeClick);
        setPanelState('login');
      }
    },
    handleReview: () => {
      if (user) {
        // will be handled in later ticket
      } else {
        setPanelState('login');
      }
    },
    handleVisited: () => {
      if (user) {
        // will be handled in later ticket
      } else {
        setPanelState('login');
      }
    },
    handleShare: async () => {
      if (isDesktop) {
        setPanelState('share_venue');
      } else if (navigator.share) {
        await navigator
          .share({
            title: t('venue_feat.directions.venue_on_tablecheck', {
              venue: translate(name_translations, language),
            }),
            url: window.location.href,
          })
          .catch((error) => console.error('Error sharing link:', error));
      } else {
        setToastContent(errorToastContent(t('toast.unsuccessful_venue_share')));
        setIsToastOpen(true);
      }
    },
    handleDirections: () => {
      window.open(
        google_place_ref?.length > 0
          ? `https://www.google.com/maps/place/?q=place_id:${google_place_ref}`
          : `https://www.google.com/maps/dir/?api=1&destination=${geocode.lat},${geocode.lon}`,
        '_blank',
      );
    },
    handleCreateList: (newListData: CreateListData) => {
      createList(newListData, {
        onSuccess: () => {
          void refetch();
          setPanelState(null);
          setToastContent(
            createVenueToastContent(newListData.name, setPanelState),
          );
          setIsToastOpen(true);
        },
      });
    },
    handleSaveToList: (listsToUpdate: SaveListsRequest) => {
      saveOrUnsaveShopToList(
        { venueId: id, listIdsToUpdate: listsToUpdate },
        {
          onSuccess: (response) => {
            void refetch();
            setPanelState(null);
            setToastContent(
              saveVenueToastContent(response.lists, setPanelState),
            );
            setIsToastOpen(true);
          },
          onError: () => {
            setPanelState(null);
            setToastContent(
              errorToastContent(t('toast.unsuccessful_list_save')),
            );
            setIsToastOpen(true);
          },
        },
      );
    },
    onSubmitMemo,
    onDeleteMemo,
  };
};
