import { t } from 'i18next';
import { useAtom } from 'jotai';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetUserQuery } from '@local/auth';
import { useFetchAllListsQuery } from '@local/list';
import {
  getSearchParamsObject,
  transformFiltersToParams,
  transformParamsToFilters,
} from '@local/utils';

import { DEFAULT_SEARCH_FILTERS, SNAP_END_POINT } from '../searchConstants';
import { searchResultsPanelAtom, searchValAtom } from '../searchStore';

import { useFetchGeolocation } from './useFetchGeolocation';
import { useSearchFilters } from './useSearchFilters';
import { useShopSearchQuery } from './useShopSearchQuery';

export const useExplore = () => {
  const [allowShopQuery, setAllowShopQuery] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchFilters, setSearchFilters } = useSearchFilters();
  const shopSearchQuery = useShopSearchQuery(
    transformFiltersToParams(searchFilters),
    allowShopQuery,
  );
  const [, setSearchResultsPanel] = useAtom(searchResultsPanelAtom);

  const { data: user } = useGetUserQuery();
  const { data: geolocation } = useFetchGeolocation();
  const [searchVal] = useAtom(searchValAtom);
  const { data: allLists } = useFetchAllListsQuery(user);

  const isAvailabilitySearch = ['date', 'time', 'num_people'].some(
    (key) => key in searchFilters,
  );

  // TODO: check if record_count can be used after DPOR-739
  const searchCount = shopSearchQuery.data?.shops.length ?? 0;

  const getTitleKey = () => {
    if (shopSearchQuery.data?.meta?.last_page) {
      return searchVal ? 'found_venues_title' : 'nearby_venues_title';
    }
    return 'more_than_x';
  };

  const panelTitle = t(`search.search_results.${getTitleKey()}`, {
    count: searchCount,
  });

  const availabilityToShow: 'time' | 'date' | null = React.useMemo(() => {
    if (searchFilters.time && !searchFilters.date) {
      return 'date';
    }
    if (searchFilters.date || searchFilters.num_people) {
      return 'time';
    }
    return null;
  }, [searchFilters]);

  React.useEffect(() => {
    if (searchParams.toString() === '') return;
    const newSearchFilters = transformParamsToFilters(
      searchParams,
      DEFAULT_SEARCH_FILTERS,
    );
    setSearchFilters(newSearchFilters);
  }, [searchParams, setSearchFilters]);

  // makes sure default filters do not overwrite param filters on page load
  React.useEffect(() => {
    if (!geolocation) return;
    const existingParams = getSearchParamsObject(searchParams);
    const cleanedExistingParams: Record<string, string | string[]> = {};

    Object.entries(existingParams).forEach(([key, value]) => {
      const cleanedKey = key.endsWith('[]') ? key.slice(0, -2) : key;
      cleanedExistingParams[cleanedKey] = value;
    });

    const mergedFilters = {
      ...searchFilters,
      geo_longitude: searchFilters.geo_longitude ?? geolocation.longitude,
      geo_latitude: searchFilters.geo_latitude ?? geolocation.latitude,
      ...cleanedExistingParams,
    };
    setSearchFilters(mergedFilters);
    setSearchParams(transformFiltersToParams(mergedFilters), { replace: true });
    setAllowShopQuery(true); // activate shop query only after geolocation is fetched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geolocation]);

  // TODO: will be removed with DPOR-435
  const [allSavedShopsSet, setAllSavedShopsSet] = React.useState<Set<string>>(
    new Set<string>(),
  );
  React.useEffect(() => {
    if (allLists?.lists)
      setAllSavedShopsSet(
        new Set<string>(allLists.lists.flatMap((item) => item.shop_ids)),
      );
    else setAllSavedShopsSet(new Set<string>());
  }, [allLists, user]);

  React.useEffect(() => {
    // opens the search panel if there are no search results
    if (shopSearchQuery.data?.shops.length === 0) {
      setSearchResultsPanel((prev) => ({
        ...prev,
        snap: SNAP_END_POINT,
      }));
    }
  }, [setSearchResultsPanel, shopSearchQuery.data?.shops]);

  return {
    allSavedShopsSet,
    shopSearchQuery,
    panelTitle,
    isAvailabilitySearch,
    searchCount,
    availabilityToShow,
  };
};
