import Cookies, { CookieAttributes } from 'js-cookie';

import { CONFIG } from '@local/configs';
import { AuthToken, Cookie, UniverseSlug } from '@local/types';

const cookieAttributes: CookieAttributes = {
  path: '/',
  secure: CONFIG.VITE_APP_ENVIRONMENT !== 'development',
  sameSite: 'strict',
};

// these prevent the cookie from being doubly uri encoded which messes with auth api integration
const uriStringEncoder = Cookies.withConverter({
  write(value: string) {
    return encodeURIComponent(value);
  },
  read(value: string) {
    return decodeURIComponent(value);
  },
});

export const cookieService = {
  isTokenExpired(authToken: AuthToken): boolean {
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime >= authToken.created_at + authToken.expires_in;
  },
  getAuthToken(): AuthToken | null {
    const token = uriStringEncoder.get(Cookie.AuthenticationKey);
    if (!token) {
      return null;
    }
    const authToken = JSON.parse(token) as AuthToken;

    return authToken;
  },
  setAuthToken(authToken: AuthToken): void {
    const authTokenString = JSON.stringify(authToken);
    uriStringEncoder.set(
      Cookie.AuthenticationKey,
      authTokenString,
      cookieAttributes,
    );
  },
  removeAuthToken(): void {
    Cookies.remove(Cookie.AuthenticationKey, {
      path: '/',
      secure: CONFIG.VITE_APP_ENVIRONMENT !== 'development',
      sameSite: 'strict',
    });
  },
  getCsrfToken(): string | null {
    const csrfToken = uriStringEncoder.get(Cookie.CsrfToken);
    if (!csrfToken) {
      return null;
    }
    return csrfToken;
  },
  setUniverse(universe: UniverseSlug): void {
    uriStringEncoder.set(
      Cookie.TCUniverse,
      universe as string,
      cookieAttributes,
    );
  },
  getUniverse(): UniverseSlug | null {
    const universeCookie = uriStringEncoder.get(
      Cookie.TCUniverse,
    ) as UniverseSlug;
    if (!universeCookie) {
      return null;
    }
    return universeCookie;
  },
} as const;
