import { Book } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';

import { ICON_SIZE_16 } from '@local/constants';
import type { MenuItem } from '@local/types';

import { MenuItemCard } from './MenuItemCard';

const SectionTitle = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  padding-bottom: var(--spacing-l2);
`;

const ItemsSection = styled.section`
  position: relative;
`;

const ExpandButton = styled(Button)`
  background-color: white;
  font-size: 14px;
  height: 32px;
  margin-top: var(--spacing-l5);
`;

const ExpandSection = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 57px;
  text-align: -webkit-center;

  &[data-is-expanded='false'] {
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &[data-is-expanded='true'] {
    height: 30px;

    & button {
      margin-top: var(--spacing-l4);
    }
  }
`;

export function MenuItemsSection({
  items,
}: {
  items: MenuItem[];
}): JSX.Element {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const shownMenuItemsList = isExpanded ? items : items?.slice(0, 4);
  return (
    <ItemsSection data-testid="Menu Item Section">
      <SectionTitle>
        {t('venue_feat.menu_items.title.dining_courses')}
      </SectionTitle>
      {shownMenuItemsList.map((item) => (
        <MenuItemCard item={item} key={item.id} />
      ))}
      {items.length > 4 && (
        <ExpandSection
          data-is-expanded={isExpanded}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <ExpandButton data-variant="ghost">
            {isExpanded ? t('general.view_less') : t('general.view_more')}
            <Book size={ICON_SIZE_16} />
          </ExpandButton>
        </ExpandSection>
      )}
    </ItemsSection>
  );
}
