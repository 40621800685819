import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import { RESERVATIONS_EMPTY_VIEW_IMG } from '@local/assets';
import { AuthContent, useGetUserQuery } from '@local/auth';
import {
  EmptyView,
  LoginPrompt,
  Panel,
  Spinner,
  panelStateAtom,
} from '@local/components';
import { TABLET_BREAKPOINT } from '@local/constants';
import {
  ReservationCard,
  ScopeSelector,
  useFetchFutureReservations,
  useFetchPastReservations,
} from '@local/reservations';
import type { Scope } from '@local/types';
import { useInView, useUniverse } from '@local/utils';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;

  > h1 {
    padding: var(--spacing-l4);
    padding-bottom: 0;
    font-size: xx-large;
    line-height: 44px;
  }

  &[data-is-desktop='true'] {
    padding-top: 0;
    top: var(--desktop-header-content-height);
  }
`;
const CardsContainer = styled.section`
  padding: var(--spacing-l5) var(--spacing-l4);
  display: flex;
  flex-direction: column;
  margin-bottom: var(--mobile-footer-height);
  > div:not(:last-child) {
    margin-bottom: var(--spacing-l4);
  }
`;

const HiddenDiv = styled.div`
  visibility: hidden;
`;

export function ReservationsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const [, { language }] = useTranslation();
  const { universeName } = useUniverse();
  const { ref, isInView } = useInView();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const [scope, setScope] = React.useState<Scope>(
    location.pathname.endsWith('/upcoming') ? 'future' : 'past',
  );
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const {
    data: reservationsFutureData,
    isLoading: isLoadingFutureReservations,
    fetchNextPage: fetchMoreFutureReservations,
    hasNextPage: hasNextFuturePage,
    isFetchingNextPage: isFetchingNextFuturePage,
  } = useFetchFutureReservations(user, scope);
  const {
    data: reservationsPastData,
    isLoading: isLoadingPastReservations,
    fetchNextPage: fetchMorePastReservations,
    hasNextPage: hasNextPastPage,
    isFetchingNextPage: isFetchingNextPastPage,
  } = useFetchPastReservations(user, scope);

  React.useEffect(() => {
    if (isInView) {
      void (scope === 'future'
        ? fetchMoreFutureReservations()
        : fetchMorePastReservations());
    }
  }, [fetchMoreFutureReservations, fetchMorePastReservations, isInView, scope]);

  const { reservations, shops } =
    scope === 'future'
      ? reservationsFutureData ?? { reservations: [], shops: [] }
      : reservationsPastData ?? { reservations: [], shops: [] };

  const isInitialLoading =
    isUserLoading || (user && !reservationsFutureData && !reservationsPastData);

  const hasMorePagesToFetch =
    (hasNextFuturePage && scope === 'future') ||
    (hasNextPastPage && scope === 'past');

  if (isInitialLoading) return <Spinner isFullPage />;

  return (
    <Wrapper data-testid="Reservations Page Root">
      {user ? (
        <>
          <StickyHeader data-is-desktop={isDesktop}>
            <h1>{t('reservations_feat.title')}</h1>
            <ScopeSelector scope={scope} setScope={setScope} />
          </StickyHeader>

          {(isLoadingPastReservations || isLoadingFutureReservations) && (
            <Spinner isFullPage />
          )}

          {reservations.length > 0 ? (
            <CardsContainer>
              {reservations.map((reservation) => {
                const venue = shops?.find(
                  (shop) => shop.slug === reservation.shop_slug,
                );
                return venue ? (
                  <ReservationCard
                    key={reservation.slug}
                    reservation={reservation}
                    venue={venue}
                  />
                ) : null;
              })}

              {(isFetchingNextFuturePage || isFetchingNextPastPage) && (
                <Spinner />
              )}
            </CardsContainer>
          ) : (
            <EmptyView
              title={t(`reservations_feat.no_${scope}_bookings`)}
              description={t(`reservations_feat.book_now_${scope}`)}
              imgSrc={RESERVATIONS_EMPTY_VIEW_IMG}
              imgAlt={t('reservations_feat.img_alt.empty_bookings')}
              ctaAction={() => navigate(`/${language}/${universeName}/search`)}
              ctaButtonText={t('list_feat.explore_venues')}
            />
          )}

          {hasMorePagesToFetch && <HiddenDiv ref={ref} />}
        </>
      ) : (
        <LoginPrompt
          image={RESERVATIONS_EMPTY_VIEW_IMG}
          title={t('reservations_feat.login_to_use')}
          subtitle={t('reservations_feat.info_msg')}
          onClick={() => setPanelState('login')}
        />
      )}
      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        <AuthContent
          onSubmit={() => {
            setPanelState(null);
          }}
          subheaderContent={
            <Trans
              i18nKey="auth.login_feat_unlock"
              components={{ bold: <strong /> }}
            />
          }
        />
      </Panel>
    </Wrapper>
  );
}
