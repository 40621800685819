import { Store } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import * as React from 'react';

import { useVenueCardContext } from '../VenueCardContext';

const Wrapper = styled.div`
  width: 100%;
  height: var(--top-picture-height);
  border-radius: var(--border-radius-large);
  order: -1;
  & > img {
    border-radius: var(--border-radius-large);
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &[data-grid='true'] {
    display: grid;
    grid-template-columns: 264px 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'pic1 pic2'
      'pic1 pic3';
    gap: 2px;
    & > img:nth-child(1) {
      height: var(--top-picture-height);
      grid-area: pic1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    & > img:nth-child(2) {
      height: calc((var(--top-picture-height) / 2) - 1px);
      grid-area: pic2;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    & > img:nth-child(3) {
      height: calc((var(--top-picture-height) / 2) - 1px);
      grid-area: pic3;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const LargeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border-radius: var(--border-radius-small);
  background-color: var(--surface-raised-hover);
  order: -1;
`;

export function TopPicture() {
  const { venue } = useVenueCardContext();
  const { imageUrls } = venue;

  const imagesToRender = React.useMemo(() => {
    if (!imageUrls) return [];
    const finalImages: string[] = [];
    for (let i = 0; i < imageUrls.length && finalImages.length < 3; i += 1) {
      if (imageUrls[i]) {
        finalImages.push(imageUrls[i]!);
      }
    }
    return finalImages.length === 3 ? finalImages : finalImages.slice(0, 1);
  }, [imageUrls]);

  return (
    <Wrapper data-grid={imagesToRender.length >= 3}>
      {imagesToRender.length > 0 ? (
        imagesToRender.map((imageUrl) => (
          <img key={imageUrl} src={imageUrl} alt={venue.title} />
        ))
      ) : (
        <LargeIconWrapper>
          <Store fill="var(--text-placeholder)" size={40} />
        </LargeIconWrapper>
      )}
    </Wrapper>
  );
}
