const supportedImgFormat = () => {
  if (typeof window === 'undefined') return 'png';

  // Use canvas hack for webkit-based browsers
  // Kudos to Rui Marques: https://stackoverflow.com/a/27232658/7897049
  const e = document.createElement('canvas');
  if (e.toDataURL?.('image/webp').startsWith('data:image/webp')) {
    return 'webp';
  }

  let m = navigator.userAgent.match(/(Edg|Firefox)\/(\d+)\./);
  if (m) {
    if (
      // Firefox 65+ (Jan 2019): First version with WebP support
      (m[1] === 'Firefox' && parseInt(m[2], 10) >= 65) ||
      // Edge 18+ (Jan 2019): First version with WebP support
      (m[1] === 'Edge' && parseInt(m[2], 10) >= 18)
    ) {
      return 'webp';
    }
  }

  m = navigator.userAgent.match(/OS X\s?(\d+)?.+ Version\/(\d+\.\d+)/);
  if (m) {
    if (
      // Safari 14+ (Sept 2020): First version with WebP support
      parseFloat(m[2]) >= 14 &&
      // macOS 11+ (Big Sur): Required for WebP support
      // 99 is fallback when OS version can't be detected
      parseInt(m[1] || '99', 10) >= 11
    ) {
      return 'webp';
    }
  }

  return 'png';
};

export const imgFormat = supportedImgFormat();
