import { styled } from '@linaria/react';

import { CONFIG } from '@local/configs';
import type {
  GalleryResponse,
  MenuItem,
  MenuItemsResponse,
  VenueFavoritesResponse,
  VenueResponse,
} from '@local/types';
import {
  Availability,
  Description,
  Experiences,
  Features,
  ImgMosaic,
  Information,
  MenuItemsSection,
  Overview,
} from '@local/venue';

interface Props {
  venueSlug: string;
  venueData: VenueResponse;
  gallery: GalleryResponse | undefined;
  menuItems: MenuItem[];
  favoritesData: VenueFavoritesResponse | undefined;
  allSavedShopsSet: Set<string>;
  experiences: MenuItemsResponse | undefined;
  shouldShowMenuItems: boolean;
  shouldShowExperiences: boolean;
}

const VenueContent = styled.div`
  display: grid;
  gap: var(--spacing-l5);
  margin: 0 var(--spacing-l4);
  padding-bottom: 60px;
  grid-column: 1 / 2;
`;

const Content = styled.div`
  margin-top: var(--spacing-l4);
  display: grid;
  grid-template-columns: 790px auto;
  grid-template-rows: 430px auto;
  gap: var(--spacing-l7) var(--spacing-l5);
`;

const MosaicWrapper = styled.div`
  grid-column: 1 / 3;
`;

export function VenueDesktop({
  venueSlug,
  venueData,
  gallery,
  menuItems,
  favoritesData,
  allSavedShopsSet,
  experiences,
  shouldShowMenuItems,
  shouldShowExperiences,
}: Props): JSX.Element {
  return (
    <Content>
      <MosaicWrapper>
        <ImgMosaic images={gallery?.images} />
      </MosaicWrapper>

      <VenueContent>
        <Overview
          venueData={venueData.shops[0]}
          favoritesData={favoritesData?.favorites[0]}
          isSaved={allSavedShopsSet.has(venueData.shops[0]._id)}
        />
        <Description
          taglineTranslations={venueData.shops[0].content_title_translations}
          descriptionTranslations={venueData.shops[0].content_body_translations}
        />
        {CONFIG.VITE_IS_STORYBOOK && (
          <Features features={venueData.shops[0].features} />
        )}
        {shouldShowExperiences && (
          <Experiences venueSlug={venueSlug} experiences={experiences} />
        )}
        {shouldShowMenuItems && <MenuItemsSection items={menuItems} />}
        <Information venueInfo={venueData.shops[0]} />
      </VenueContent>

      <Availability venueSlug={venueSlug} />
    </Content>
  );
}
