import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useAtom } from 'jotai';

import { CreditCard, useFetchCreditCards } from '@local/account';
import { CARDS_EMPTY_VIEW_IMG } from '@local/assets';
import { EmptyView, Spinner, panelStateAtom } from '@local/components';
import { PaymentProfile } from '@local/types';

const NoCreditCardsText = styled.p`
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);
`;

interface Props {
  setSelectedCreditCard: React.Dispatch<
    React.SetStateAction<PaymentProfile | null>
  >;
}

export function CreditCardsContent({ setSelectedCreditCard }: Props) {
  const [, setPanelState] = useAtom(panelStateAtom);
  const { data, isLoading } = useFetchCreditCards();

  if (isLoading) {
    return <Spinner />;
  }

  if (!isLoading && !data?.payment_profiles?.length) {
    return (
      <NoCreditCardsText data-testid="No Credit Cards Text">
        <EmptyView
          title={t('account.credit_cards.none')}
          description={t('account.credit_cards.empty_description')}
          imgSrc={CARDS_EMPTY_VIEW_IMG}
          imgAlt={t('account.credit_cards.empty_description')}
        />
      </NoCreditCardsText>
    );
  }

  return (
    <Wrapper data-testid="Credit Cards">
      {data?.payment_profiles?.map((creditCard) => (
        <CreditCard
          key={creditCard.id}
          creditCard={creditCard}
          onRemoveClick={() => {
            setSelectedCreditCard(creditCard);
            setPanelState('delete_credit_card');
          }}
        />
      ))}
    </Wrapper>
  );
}
