import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import * as React from 'react';

import { CONFIG } from '@local/configs';
import {
  BackgroundImg,
  CampaignSection,
  Catchphrase,
  OverlayContent,
  StoryblokSection,
} from '@local/home';
import {
  SearchInput,
  isHomepageSearchVisibleAtom,
  useSearchInput,
} from '@local/search';
import {
  imgFormat,
  useInView,
  useOutsideClick,
  useUniverse,
} from '@local/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledBackgroundImg = styled(BackgroundImg)`
  height: 50svh;
`;

const StyledOverlay = styled(OverlayContent)`
  top: 25svh;
  padding: 0;
  width: auto;
  left: var(--spacing-l2);
`;

const Content = styled.div`
  display: grid;
  gap: var(--spacing-l8);
  margin: var(--spacing-l8) var(--spacing-l4);
`;

export function HomeDesktop(): JSX.Element {
  const [isHomepageSearchVisible, setIsHomepageSearchVisible] = useAtom(
    isHomepageSearchVisibleAtom,
  );
  const { ref, isInView } = useInView({
    rootMargin: '-83px 0px 0px 0px',
  });
  const { setSearchView } = useSearchInput();

  React.useEffect(() => {
    setIsHomepageSearchVisible(isInView);
  }, [isInView, setIsHomepageSearchVisible]);

  const searchInputRef = useOutsideClick<HTMLDivElement>(() => {
    if (isHomepageSearchVisible) setSearchView('default');
  }, ref);
  const heroBannerImg = `${CONFIG.VITE_BASE_CDN_IMAGES_URL}banners/hero-new-dpor/v1.0.0/hero-banner-japan-desktop.${imgFormat}`;
  const { isJapanUniverse } = useUniverse();

  return (
    <Wrapper data-testid="Home Page Root">
      <StyledBackgroundImg
        src={heroBannerImg}
        alt="TableCheck Diner Portal Home Banner"
      />
      <StyledOverlay>
        <Catchphrase>{t('homepage.catchphrase')}</Catchphrase>
        <SearchInput dataTestId="Home Search Bar" ref={searchInputRef} />
      </StyledOverlay>

      <Content>
        {isJapanUniverse && <CampaignSection />}
        <StoryblokSection type="cuisines" />
        <StoryblokSection type="area" />
      </Content>
    </Wrapper>
  );
}
