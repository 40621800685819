import { Close } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import * as Dialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { IconButton } from '@tablecheck/tablekit-react-css';
import { Drawer } from 'vaul';

import { ICON_SIZE_24 } from '@local/constants';

import { TcDrawer } from '../TcDrawer/TcDrawer';

const Overlay = styled(Drawer.Overlay)`
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.54);
  position: fixed;
  inset: 0;
  z-index: var(--zindex-dialog);
`;

const DrawerContent = styled(Drawer.Content)`
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  max-height: 80vh;
  z-index: var(--zindex-modal);
  width: 100%;
  bottom: 0;
  left: 0;
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
`;

const ChildrenContent = styled.div`
  overflow-x: hidden;
  &[data-has-title='true'] {
    margin-top: 66px;
  }
`;

const Header = styled(TcDrawer.Title)`
  position: fixed;
  z-index: var(--zindex-blanket);
  background-color: white;
  width: -webkit-fill-available;
  padding: var(--spacing-l4);
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: var(--spacing-l2);
  background-color: white;
  margin: var(--spacing-l1) var(--spacing-l2);
  z-index: var(--zindex-spotlight) !important;
  &:hover {
    background-color: var(--grey-200);
  }
`;

type PanelProps = {
  title?: string | null;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  containerId?: string;
} & Dialog.DialogProps;

export function PanelMobile({
  isOpen,
  title,
  children,
  onClose,
  containerId,
}: PanelProps): JSX.Element {
  return (
    <Drawer.Root
      onOpenChange={(open) => !open && onClose()}
      open={isOpen}
      modal
    >
      <Drawer.Portal
        container={
          containerId ? document.getElementById(containerId) : undefined
        }
      >
        <Overlay />
        <DrawerContent
          aria-describedby="" // Fixes the "Missing `Description` or `aria-describedby={undefined}` for {DialogContent}" warning
        >
          <CloseButton
            type="button"
            data-variant="bare"
            data-testid="Close Panel Btn"
            onClick={onClose}
          >
            <Close size={ICON_SIZE_24} />
          </CloseButton>
          <Dialog.Title />
          {title ? (
            <Header>{title}</Header>
          ) : (
            <VisuallyHidden asChild>
              <Dialog.Title />
            </VisuallyHidden>
          )}
          <ChildrenContent data-has-title={!!title}>{children}</ChildrenContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
