import { useMediaQuery } from 'react-responsive';

import { TABLET_BREAKPOINT } from '@local/constants';

import { HomeDesktop } from './HomeDesktop';
import { HomeMobile } from './HomeMobile';

export function HomeRoot() {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  return <>{isDesktop ? <HomeDesktop /> : <HomeMobile />}</>;
}
