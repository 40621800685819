import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CONFIG } from '@local/configs';
import { buildBookingFormUrl, getClosestTimeSlots } from '@local/utils';

import { useVenueCardContext } from '../VenueCardContext';

const Wrapper = styled.div`
  display: flex;
  gap: var(--spacing-l2);
  margin-top: var(--spacing-l3);
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  & > button {
    flex-basis: 20%;
  }
`;
interface Props {
  query: {
    date?: string;
    time?: string;
    pax?: string;
  };
}

export function TimeSlots({ query }: Props) {
  const { venue } = useVenueCardContext();
  const { availability, slug } = venue;
  const [, { language }] = useTranslation();

  if (!availability || availability.length === 0) {
    return null;
  }

  const currentDateTime = new Date();
  const closestTimesSlots = getClosestTimeSlots({
    availability,
    queriedTime: query.time,
    queriedDate: query.date,
    currentDateTime,
  });

  return (
    <Wrapper>
      {closestTimesSlots.map((dateTime: string) => {
        const parsedDateTime = new Date(dateTime);
        const time = format(parsedDateTime, 'HH:mm');
        const date = format(parsedDateTime, 'yyyy-MM-dd');
        const isPast = parsedDateTime < currentDateTime;

        return (
          <Button
            key={dateTime}
            data-testid="Common Venue Card Time Slot Btn"
            data-variant="primary"
            disabled={isPast}
            onClick={(e) => {
              e.stopPropagation();
              const bookingLink = buildBookingFormUrl(
                `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${slug}/reserve`,
                {
                  start_date: date,
                  start_time: time,
                  ...(query.pax && { num_people: query.pax }),
                },
              );
              window.open(bookingLink, '_blank', 'noreferrer');
            }}
          >
            {time}
          </Button>
        );
      })}
    </Wrapper>
  );
}
