import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { DEFAULT_MENU_ITEM_IMAGE } from '@local/assets';
import { Footer } from '@local/components';
import { CONFIG } from '@local/configs';
import type { MenuItem as MenuItemType } from '@local/types';
import {
  getPrice,
  getServiceFeeAndTaxTypeKey,
  translate,
  useUniverse,
} from '@local/utils';

const MenuItemImg = styled.img`
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
  height: 240px;
  width: 100%;
  object-fit: cover;
`;

const Wrapper = styled.div`
  padding: 0 var(--spacing-l4);
`;

const Subheader = styled.div`
  display: grid;
  grid-template-columns: fit-content(20%) auto;
  gap: var(--spacing-l2);
  margin: var(--spacing-l2) 0;

  & span {
    font-weight: 600;
  }

  & div {
    color: var(--text-subtle);
  }
`;

const TextContent = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export function MenuItem({
  item,
  onClose,
}: {
  item: MenuItemType;
  onClose: () => void;
}): JSX.Element {
  const [, { language }] = useTranslation();
  const { universeCurrency } = useUniverse();

  const {
    description_translations,
    image_urls,
    ingredients,
    price,
    quantity,
    tax_type,
    service_fee_type,
    name_translations,
  } = item;

  return (
    <>
      <MenuItemImg
        src={image_urls.original || DEFAULT_MENU_ITEM_IMAGE}
        alt={image_urls.id}
      />

      <Wrapper>
        <h2>{translate(name_translations, language)}</h2>
        <Subheader>
          <span>
            {getPrice({
              amount: price,
              showZero: true,
              currencyFormat: universeCurrency,
            })}
          </span>
          <div>{t(getServiceFeeAndTaxTypeKey(tax_type, service_fee_type))}</div>
        </Subheader>
        <TextContent>
          <div>{translate(description_translations, language)}</div>
          {CONFIG.VITE_IS_STORYBOOK && parseFloat(quantity) > 0 && (
            <div>
              {t('venue_feat.menu_items.grams', {
                quantity,
              })}
            </div>
          )}

          {CONFIG.VITE_IS_STORYBOOK && ingredients.length > 0 && (
            <>
              <div>{t('venue_feat.menu_items.includes')}</div>
              {ingredients.map((ingredient) => {
                const translatedIngredients = translate(ingredient, language);
                return (
                  <div key={translatedIngredients}>{translatedIngredients}</div>
                );
              })}
            </>
          )}
        </TextContent>

        <Footer>
          <Button
            data-variant="tertiary"
            type="button"
            onClick={() => onClose()}
          >
            {t('general.close')}
          </Button>
        </Footer>
      </Wrapper>
    </>
  );
}
