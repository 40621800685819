import { styled } from '@linaria/react';
import { t } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';

import { DEFAULT_EXPERIENCE_IMAGE } from '@local/assets';
import { Restrictions } from '@local/restrictions';
import type { MenuItem, Restriction } from '@local/types';
import { getPrice, translate, useUniverse } from '@local/utils';

const Wrapper = styled.div`
  width: var(--experience-card-height);
  max-height: -webkit-fill-available;
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px var(--border-transparent);
  border-radius: var(--spacing-l2);
  margin: var(--spacing-l1);
`;

const Image = styled.img`
  height: 160px;
  width: 100%;
  border-radius: var(--spacing-l2);
  object-fit: cover;
`;

const Title = styled.span`
  font-weight: 600;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-700);
`;

const ExperienceInfo = styled.div`
  padding: var(--spacing-l3);
  border-radius: 0px 0px var(--spacing-l2) var(--spacing-l2);
`;

const LineDetails = styled.div`
  color: var(--grey-700);
  font-size: 14px;
  strong {
    font-weight: 600;
    color: black;
  }
`;

export const CardRestriction = styled.span`
  display: flex;
  gap: var(--spacing-l1);
  overflow: hidden;
`;

export function ExperienceCard({
  cardData,
  venueTitle,
  onClickAction,
}: {
  cardData: MenuItem;
  venueTitle: string;
  onClickAction: () => void;
}): JSX.Element {
  const [, { language }] = useTranslation();
  const { universeCurrency } = useUniverse();

  const {
    description_translations,
    price,
    valid_date_ranges,
    valid_start_time,
    valid_end_time,
    days,
    is_holiday: isHoliday,
    image_urls,
  } = cardData;

  const restrictions = new Restrictions({
    validDateRanges: valid_date_ranges,
    days: days ?? [],
    validStartTime: valid_start_time,
    validEndTime: valid_end_time,
    isHoliday,
    language,
  }).build(({ times, datesDays }) => [times, datesDays]);

  return (
    <Wrapper data-testid="Experience Cards" onClick={onClickAction}>
      <Image
        src={image_urls?.original || DEFAULT_EXPERIENCE_IMAGE}
        alt={image_urls?.id}
      />
      <ExperienceInfo>
        <Title>{venueTitle}</Title>
        <Description>
          {translate(description_translations, language)}
        </Description>
        {restrictions.length > 0 && (
          <LineDetails>
            {restrictions.map((restriction: Restriction) => (
              <CardRestriction
                data-testid={restriction['data-testid']}
                key={restriction.id}
              >
                {restriction.getText(t)}
              </CardRestriction>
            ))}
          </LineDetails>
        )}
        <LineDetails>
          <Trans
            i18nKey="venue_feat.per_guest"
            values={{
              value: getPrice({
                amount: price,
                showZero: false,
                currencyFormat: universeCurrency,
              }),
            }}
            components={{ bold: <strong /> }}
          />
        </LineDetails>
      </ExperienceInfo>
    </Wrapper>
  );
}
