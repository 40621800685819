import { styled } from '@linaria/react';
// TODO: remove Tablekit Emotion version of DatePicker
import * as DatePicker from '@tablecheck/tablekit-react-datepicker';
import { Props as DateProps } from 'dayzed';
import * as React from 'react';

import { getFormattedMonths } from '@local/utils';

const DatePickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-l2) var(--spacing-l4);
  background-color: var(--background-secondary);
  &[data-center='true'] {
    place-content: center;
  }
`;

const DatePickerArrowWrapper = styled.div`
  display: flex;
  gap: var(--spacing-l2);
  align-self: flex-end;
`;

const CalendarWrapper = styled.div`
  width: -webkit-fill-available;
  max-width: 100%;
  table {
    text-align: center;
    width: -webkit-fill-available;
  }
  button[data-state='selected'] {
    cursor: pointer;
  }
`;
type CalendarProps = Omit<DateProps, 'children' | 'render'> & {
  shouldShowArrows?: boolean;
  shouldCenterHeader?: boolean;
  monthsToDisplay?: number;
};
export const Calendar = React.forwardRef<HTMLDivElement, CalendarProps>(
  (props, ref) => {
    const {
      date,
      minDate,
      onDateSelected,
      shouldShowArrows,
      shouldCenterHeader,
      monthsToDisplay,
      ...restProps
    } = props;
    const today = new Date();
    return (
      <CalendarWrapper>
        <DatePicker.Root
          ref={ref}
          date={new Date(date ? date.getMonth() : today.getMonth())}
          selected={date}
          minDate={minDate}
          onDateSelected={onDateSelected}
          id="tc-datepicker"
          {...restProps}
          data-testid="tc-datepicker"
          monthsToDisplay={monthsToDisplay}
        >
          <DatePicker.Months
            renderMonthHeader={(calendar) => (
              <DatePickerHeader data-center={shouldCenterHeader}>
                <h5>
                  {getFormattedMonths(calendar)} {calendar.year}
                </h5>
                {shouldShowArrows && (
                  <DatePickerArrowWrapper>
                    <DatePicker.PreviousMonth />
                    <DatePicker.NextMonth />
                  </DatePickerArrowWrapper>
                )}
              </DatePickerHeader>
            )}
          />
        </DatePicker.Root>
      </CalendarWrapper>
    );
  },
);

Calendar.displayName = 'Calendar';
