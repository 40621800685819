import Slider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';

export function RcSlider({ ...props }: SliderProps) {
  return (
    <Slider
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 var(--spacing-l3)',
      }}
      range
      allowCross={false}
      styles={{
        track: {
          backgroundColor: 'black',
          height: 'var(--spacing-l2)',
        },
        rail: {
          backgroundColor: 'lightgray',
          height: 'var(--spacing-l2)',
        },
        handle: {
          backgroundColor: 'white',
          width: 'var(--spacing-l5)',
          height: 'var(--spacing-l5)',
          alignSelf: 'center',
          opacity: 1,
          boxShadow: '0px 1px 12px 0px var(--lighter-grey-border)',
          border: '2px solid white',
        },
      }}
      {...props}
    />
  );
}
