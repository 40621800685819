import { RequestQuote } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useAtom } from 'jotai';

import { useGetUserQuery } from '@local/auth';
import { panelStateAtom } from '@local/components';
import { DEFAULT_ICON_SIZE } from '@local/constants';
import { useFetchUserMemoQuery } from '@local/user';

const MemoField = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  gap: var(--spacing-l2);
  padding: var(--spacing-l2) var(--spacing-l4);
  align-items: center;
  border: 1px solid var(--lighter-grey-border);
  border-radius: var(--border-radius-small);
  cursor: pointer;
  color: var(--grey-700);
  background-color: var(--grey-50);
  border: 1px dashed var(--grey-500);

  &[data-has-memo='true'] {
    color: var(--lighter-text-color);
  }
  &:hover {
    background-color: var(--grey-100);
  }
`;

const UnderlinedSpan = styled.span`
  text-decoration: underline;
`;

interface Props {
  venueSlug: string;
}

export function Memo({ venueSlug }: Props): JSX.Element {
  const [, setPanelState] = useAtom(panelStateAtom);
  const { data: user } = useGetUserQuery();
  const { data: userMemo } = useFetchUserMemoQuery(venueSlug, user);

  const hasMemo = userMemo && userMemo.memo?.length > 0;
  return (
    <MemoField
      onClick={() => setPanelState('memo')}
      data-has-memo={hasMemo}
      data-testid="Venue Memo Area"
    >
      <RequestQuote size={DEFAULT_ICON_SIZE} />
      {hasMemo ? (
        <span>{userMemo.memo}</span>
      ) : (
        <UnderlinedSpan>{t('venue_feat.memo.add_memo')}</UnderlinedSpan>
      )}
    </MemoField>
  );
}
