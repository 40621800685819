import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';

import { deleteList } from '../listApi';

export const useDeleteListQuery = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => deleteList(id),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [USER_QUERY_KEY, 'lists'],
        })
        .catch(console.error);
    },
    onError: (error) => {
      console.error('Error deleting list:', error);
    },
  });
};
