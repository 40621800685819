import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import type { VenueFavorites } from '@local/types';

import { updateUserFavoritesVenue } from '../userApi';

export const useUpdateUserFavoritesVenueQuery = (
  venueSlug: string | undefined,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Partial<VenueFavorites>) =>
      updateUserFavoritesVenue({ venueSlug, data }),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [USER_QUERY_KEY, 'favorites', venueSlug],
        })
        .catch(console.error);
    },
    onError: (error) => {
      console.error('Error updating user favorites:', error);
    },
  });
};
