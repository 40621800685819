import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SUBSCRIPTIONS_EMPTY_VIEW_IMG } from '@local/assets';
import { useGetUserQuery } from '@local/auth';
import { EmptyView, Spinner, panelStateAtom } from '@local/components';
import type { Subscription } from '@local/types';
import { useFetchUserSubscriptionsQuery } from '@local/user';
import { translate, useUniverse } from '@local/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subheading = styled.p`
  font: var(--body-1);
  font-weight: var(--label-font-weight);
  margin-bottom: var(--spacing-l2);
`;

const BodyText = styled.p`
  font: var(--body-2);
`;

const SubscriptionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-l5) 0;
  border-bottom: 1px solid var(--lighter-grey-border);
`;

interface Props {
  setSelectedSubscription: React.Dispatch<
    React.SetStateAction<Subscription | null>
  >;
}

export function CommunicationsContent({ setSelectedSubscription }: Props) {
  const navigate = useNavigate();
  const [, setPanelState] = useAtom(panelStateAtom);
  const { isLoading: isUserLoading, data: user } = useGetUserQuery();
  const { isLoading: isLoadingSubscriptions, data: subscriptionsData } =
    useFetchUserSubscriptionsQuery(user);
  const [, { language }] = useTranslation();
  const { universeName } = useUniverse();
  if (isUserLoading || isLoadingSubscriptions) return <Spinner isFullPage />;

  const subscriptionsContent = !subscriptionsData?.length ? (
    <BodyText data-testid="No Subscriptions Text">
      <EmptyView
        title={t('account.communications.subscriptions.none')}
        description={t(
          'account.communications.subscriptions.empty_description',
        )}
        imgSrc={SUBSCRIPTIONS_EMPTY_VIEW_IMG}
        imgAlt={t('account.communications.subscriptions.empty_description')}
        ctaAction={() => navigate(`/${language}/${universeName}/search`)}
        ctaButtonText={t('list_feat.explore_venues')}
      />
    </BodyText>
  ) : (
    <>
      <Subheading>
        {t('account.communications.subscriptions.your_subscriptions')}
      </Subheading>
      <BodyText>{t('account.communications.subscriptions.body_text')}</BodyText>
      {subscriptionsData?.map((subscription) => {
        const shopName = translate(
          subscription.shop_name_translations,
          language,
        );

        return (
          <SubscriptionItem
            key={subscription.shop_slug}
            data-testid="Subscription Item"
          >
            {shopName}
            <Button
              data-testid="Subscription Unsubscribe Btn"
              data-variant="danger"
              onClick={() => {
                setSelectedSubscription(subscription);
                setPanelState('delete_subscription');
              }}
            >
              {t('account.communications.subscriptions.unsubscribe')}
            </Button>
          </SubscriptionItem>
        );
      })}
    </>
  );

  return <Wrapper data-testid="Communications">{subscriptionsContent}</Wrapper>;
}
