import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import type {
  CuisineSearchSuggestionsResponseType,
  LocationSearchSuggestionsResponseType,
  ShopSearchSuggestionsResponseType,
} from '@local/types';
import {
  buildUrl,
  useDebounce,
  useOutsideClick,
  useRouting,
  useUniverse,
} from '@local/utils';

import {
  SEARCH_SUGGESTIONS_DEBOUNCE_TIME,
  SEARCH_SUGGESTIONS_MIN_LENGTH,
} from '../searchConstants';
import { searchValAtom, searchViewAtom } from '../searchStore';

import { useFetchSearchSuggestionsQuery } from './useFetchSearchSuggestionsQuery';
import { useSearchFilters } from './useSearchFilters';

export function useSearchInput() {
  const navigate = useNavigate();
  const [, { language }] = useTranslation();
  const [searchParams] = useSearchParams();
  const { updateSearchFilters } = useSearchFilters();
  const [searchVal, setSearchVal] = useAtom(searchValAtom);
  const [searchView, setSearchView] = useAtom(searchViewAtom);
  const isNotExplorePage = !useRouting().isExplorePage;
  const { universeName } = useUniverse();

  const isInputFocused = searchView === 'focused';
  const debouncedSearchVal = useDebounce(
    searchVal.trim(),
    SEARCH_SUGGESTIONS_DEBOUNCE_TIME,
  );
  const shouldShowSearchSuggestions =
    searchVal.length >= SEARCH_SUGGESTIONS_MIN_LENGTH;
  // TODO: change `shouldShowPlainTextSearch = searchVal.length > 0;` when AI search is implemented
  const shouldShowPlainTextSearch = false;
  const searchSuggestionsQuery = useFetchSearchSuggestionsQuery(
    debouncedSearchVal,
    language,
  );
  const shouldShowDropDown =
    (shouldShowSearchSuggestions ||
      shouldShowPlainTextSearch ||
      searchSuggestionsQuery.isLoading) &&
    searchView === 'focused';
  const searchInputRef = useOutsideClick<HTMLDivElement>(() =>
    setSearchView('default'),
  );

  const handleSuggestionClick = (
    suggestion?:
      | ShopSearchSuggestionsResponseType
      | CuisineSearchSuggestionsResponseType
      | LocationSearchSuggestionsResponseType,
  ) => {
    switch (suggestion?.type) {
      case 'shops':
        navigate(
          buildUrl(
            `/${language}/${suggestion.payload.shop_slug}`,
            searchParams,
          ),
        );
        break;
      case 'cuisines':
        updateSearchFilters({
          cuisines: [suggestion.payload.term.toLowerCase()],
        });
        setSearchVal(suggestion.text);
        if (isNotExplorePage) navigate(`/${language}/${universeName}/search`);
        break;
      case 'locations':
        updateSearchFilters(
          {
            geo_latitude: suggestion.payload.geo.lat,
            geo_longitude: suggestion.payload.geo.lon,
            auto_geolocate: false,
          },
          { reset: true },
        );
        setSearchVal(suggestion.text);
        if (isNotExplorePage) navigate(`/${language}/${universeName}/search`);

        break;
      default:
        navigate(`/${language}/${universeName}/search`);
        setSearchVal('');
        break;
    }
    setSearchView('default');
  };

  return {
    searchVal,
    setSearchVal,
    searchInputRef,
    setSearchView,
    isInputFocused,
    shouldShowDropDown,
    searchSuggestionsQuery,
    shouldShowPlainTextSearch,
    shouldShowSearchSuggestions,
    onPlainTextSuggestionClick: () => {
      updateSearchFilters({ search_text: searchVal }, { reset: true });
      setSearchView('default');
      if (isNotExplorePage) navigate(`/${language}/${universeName}/search`);
    },
    handleSuggestionClick,
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (searchSuggestionsQuery.data?.suggestions[0]) {
        handleSuggestionClick(searchSuggestionsQuery.data.suggestions[0]);
      } else {
        handleSuggestionClick();
      }

      // TODO: uncomment `if` block below when AI search is implemented. And remove the `if` block above
      // // resets other filters except for search_text
      // if (searchVal.length > 0) {
      //   updateSearchFilters({ search_text: searchVal }, { reset: true });
      //   setSearchView('default');
      //   if (isNotExplorePage) navigate(`/${language}/${universeName}/search`);
      // }
    },
    handleReset: () => {
      setSearchVal('');
    },
    onBackClick: () => {
      updateSearchFilters({ search_text: '' }, { reset: true });
      setSearchView('default');
    },
  };
}
