/// <reference types="../../../definitions/vite-env.d.ts" />

import { APP_DEPLOY_TARGETS } from '@local/constants';

import { baseSchema } from './baseSchema';
import { developmentConfig } from './development';
import { pilotConfig } from './pilot';
import { productionConfig } from './production';
import { stagingQaConfig, stagingSnapConfig } from './staging';
import { testingConfig } from './testing';

function getConfigForTarget(deployTarget: (typeof APP_DEPLOY_TARGETS)[number]) {
  switch (deployTarget) {
    case 'pilot':
    case 'pilot-2':
      return pilotConfig;
    case 'production':
      return productionConfig;
    case 'staging':
    case 'staging-qa':
    case 'staging-qa-2':
    case 'staging-qa-3':
    case 'staging-ci':
      return stagingQaConfig;
    case 'staging-snap':
      return stagingSnapConfig;
    case 'testing':
      return testingConfig;
    default:
      return developmentConfig;
  }
}

const env = typeof window === 'undefined' ? process.env : import.meta.env;

const result = baseSchema.safeParse(env);

if (!result.success) {
  console.error(
    'Invalid environment variables for deploy target:',
    env.VITE_APP_DEPLOY_TARGET,
  );
  console.error('Errors:', result.error.format());
  process?.exit(-1);
}

const envConfig = getConfigForTarget(result.data.VITE_APP_DEPLOY_TARGET);

export const CONFIG = { ...result.data, ...envConfig };
