import { styled } from '@linaria/react';
import { useAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_LIST_IMAGE, LIST_EMPTY_VIEW_IMG } from '@local/assets';
import { AuthContent, useGetUserQuery } from '@local/auth';
import { LoginPrompt, Panel, Spinner, panelStateAtom } from '@local/components';
import { AUTH_PANEL_STATES, TABLET_BREAKPOINT } from '@local/constants';
import {
  CreateNewListCard,
  CreateNewListContent,
  ListCard,
  useCreateListQuery,
  useFetchAllListsQuery,
} from '@local/list';

const Title = styled.h1`
  font-size: xx-large;
  padding-top: var(--spacing-l4);
`;
const ListPageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l4);

  &[data-is-desktop='true'] {
    margin-top: 0;
  }

  & > h1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: xx-large;
    line-height: 44px;
  }
`;

const CardContainer = styled.main`
  display: flex;
  flex-direction: column;
`;

export function ListsPage() {
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const [t, { language }] = useTranslation();
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const {
    data: listsData,
    status: listsStatus,
    isLoading: isListsLoading,
  } = useFetchAllListsQuery(user);
  const { mutate: createList } = useCreateListQuery();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const navigate = useNavigate();

  const listsContent = () => {
    if (!!listsData && listsStatus === 'success') {
      return (
        <>
          <Title>{t('list_feat.title')}</Title>
          <CardContainer data-testid="Lists Card Container">
            <CreateNewListCard
              onClick={() => {
                setPanelState('create_list');
              }}
            />
            {listsData.lists.map((list) => (
              <ListCard
                key={list.id}
                type="clickable"
                name={list.name}
                shopIds={list.shop_ids}
                isFavorite={list.is_favorite}
                imageUrl={list.image_url}
                onClick={() => {
                  navigate(`/${language}/lists/${list.id}`);
                }}
              />
            ))}
          </CardContainer>
        </>
      );
    }
    if (isListsLoading || isUserLoading) {
      return (
        <CardContainer>
          <Spinner isFullPage />
        </CardContainer>
      );
    }
    return (
      <LoginPrompt
        image={LIST_EMPTY_VIEW_IMG}
        title={t('list_feat.login_to_use')}
        subtitle={t('list_feat.info_msg')}
        onClick={() => setPanelState('login')}
      />
    );
  };

  return (
    <>
      <ListPageWrapper
        data-testid="Lists Page Root"
        data-is-desktop={isDesktop}
      >
        {listsContent()}
      </ListPageWrapper>

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {AUTH_PANEL_STATES.includes(panelState) && (
          <AuthContent
            subheaderContent={
              <Trans
                i18nKey="auth.login_feat_unlock"
                components={{ bold: <strong /> }}
              />
            }
          />
        )}

        {panelState === 'create_list' && (
          <CreateNewListContent
            imageUrl={DEFAULT_LIST_IMAGE}
            onSubmit={(newListData) => {
              createList(newListData, {
                onSuccess: () => {
                  setPanelState(null);
                },
              });
            }}
            onCancel={() => {
              setPanelState(null);
            }}
          />
        )}
      </Panel>
    </>
  );
}
