import { t } from 'i18next';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { toastContentAtom, toastIsOpenAtom, useToast } from '@local/components';
import { CONFIG } from '@local/configs';
import type { ReservationVenueInfo, Reservation } from '@local/types';
import { buildBookingFormUrl, translate } from '@local/utils';

// TODO: DPOR-666 merge this hook with libs/venue/src/components/ActionBtns/useActionBtns.tsx
export const useActionBtns = (
  reservation: Reservation,
  venueData: ReservationVenueInfo,
) => {
  const [, { language }] = useTranslation();
  const setIsToastOpen = useSetAtom(toastIsOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const { name_translations, google_place_ref, geocode, phone } = venueData;

  const { errorToastContent } = useToast();

  return {
    handleManageReservation: () => {
      const path =
        reservation.booking_page_mode === 'v1' ? 'reservations' : 'reservation';
      const url = buildBookingFormUrl(
        `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${path}/${reservation.slug}`,
      );
      window.open(url, '_blank');
    },

    handleShare: async () => {
      if (navigator.share) {
        await navigator
          .share({
            title: t('venue_feat.directions.venue_on_tablecheck', {
              venue: translate(name_translations, language),
            }),
            url: window.location.href,
          })
          .catch((error) => console.error('Error sharing link:', error));
      } else {
        setToastContent(errorToastContent(t('toast.unsuccessful_venue_share')));
        setIsToastOpen(true);
      }
    },

    handleDirections: () => {
      window.open(
        google_place_ref?.length > 0
          ? `https://www.google.com/maps/place/?q=place_id:${google_place_ref}`
          : `https://www.google.com/maps/dir/?api=1&destination=${geocode.lat},${geocode.lon}`,
        '_blank',
      );
    },

    handleCall: () => {
      window.open(`tel:${phone}`);
    },
  };
};
