import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const editProfileValidation = z.object({
  first_name: z.string().trim().min(1),
  last_name: z.string().trim().min(1),
  email: z.string().trim().email(),
  sex: z.number().int().min(0).max(2),
  phone: z.string().refine((value) => isValidPhoneNumber(value)),
});

export type EditProfileSchema = z.infer<typeof editProfileValidation>;
