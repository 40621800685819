import { z } from 'zod';

import { baseSchema } from './baseSchema';

export const productionConfig: Partial<z.infer<typeof baseSchema>> = {
  VITE_APP_ENVIRONMENT: 'production',
  VITE_BASE_API_URL: 'https://production.tablecheck.com/',
  VITE_BASE_INTERNAL_API_URL: `http://tc-v4-api-ap-northeast-1-production.monolith/`,
  VITE_BASE_AUTH_API_URL: 'https://production.tablecheck.com/',
  VITE_BASE_SEARCH_AUTOCOMPLETE_API_URL: 'https://production.tablecheck.com/',
  VITE_BASE_TABLECHECK_URL: 'https://tablecheck.com/',
  VITE_BASE_PROTOMAP_API_URL: `https://maps.ingress.production.tablecheck.com/tiles/20241111.json`,
};
