import { styled } from '@linaria/react';
import { TranslationsArray } from '@tablecheck/i18n';
import { useTranslation } from 'react-i18next';

import { CollapsibleText } from '@local/components';
import { translate } from '@local/utils';

const Title = styled.div`
  font-weight: 600;
  margin-bottom: var(--spacing-l2);
`;

export const DescriptionContent = styled.div`
  font-weight: 400;
`;

export const Wrapper = styled.section`
  overflow-wrap: anywhere;
`;

export function Description({
  taglineTranslations,
  descriptionTranslations,
}: {
  taglineTranslations: TranslationsArray;
  descriptionTranslations: TranslationsArray;
}): JSX.Element {
  const [, { language }] = useTranslation();

  return (
    <Wrapper>
      {taglineTranslations[0] && (
        <Title>{taglineTranslations[0].translation}</Title>
      )}
      <CollapsibleText content={translate(descriptionTranslations, language)} />
    </Wrapper>
  );
}
