import { styled } from '@linaria/react';
import { Button, ButtonProps } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

const defaultWrapperStyle = `
  border-radius: var(--border-radius-large);
  border: 1px solid var(--grey-400);
  display: grid;
  min-height: 160px;
  width: max-content;
  cursor: pointer;
  height: 100%;
`;

const DefaultWrapper = styled.div`
  ${defaultWrapperStyle}
  grid-template-columns: 9fr 2fr;

  & img {
    height: 100%;
    width: 120px;
    object-fit: cover;
    border-top-right-radius: calc(var(--border-radius-large) - 1px);
    border-bottom-right-radius: calc(var(--border-radius-large) - 1px);
  }
`;

const GradientWrapper = styled.div`
  ${defaultWrapperStyle}

  & section {
    width: 70%;
  }
`;

const TextSection = styled.section`
  display: flex;
  gap: var(--spacing-l1);
  flex-direction: column;
  padding: var(--spacing-l4);
  justify-content: flex-start;
  border-top-left-radius: var(--border-radius-large);
  border-bottom-left-radius: var(--border-radius-large);
  & button {
    width: min-content;
    margin-top: auto;
  }
`;

const Title = styled.p`
  font: var(--label);
`;

const Description = styled.p`
  margin-bottom: var(--spacing-l2);
  font: var(--body-2);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const StyledButton = styled(Button)`
  border: none;
`;

export type CardType = 'default' | 'gradient';
interface Props {
  type?: CardType;
  backgroundColor?: string;
  textColor?: string;
  buttonVariant?: ButtonProps['data-variant'];
  title: string;
  description: string;
  imageUrl: string;
  link: string;
}

export function CampaignCard({
  title,
  description,
  imageUrl,
  link,
  type = 'default',
  backgroundColor = 'var(--grey-100)',
  textColor = 'black',
  buttonVariant = 'primary',
}: Props) {
  const isDefaultType = type === 'default';
  const customStyle = !isDefaultType && {
    backgroundImage: `
        linear-gradient(to right, ${backgroundColor} 30%, rgba(0, 0, 0, 0) 100%),
        url(${imageUrl})
      `,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };
  const Wrapper = isDefaultType ? DefaultWrapper : GradientWrapper;
  return (
    <Wrapper
      data-testid="Campaign Card"
      // For reference: https://github.com/tablecheck/diner-frontend-next/pull/245#discussion_r1783706357
      style={{ ...customStyle, backgroundColor }}
      onClick={() => window.open(link, '_blank', 'noreferrer')}
    >
      <TextSection style={{ color: textColor }}>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <StyledButton
          style={{ background: textColor }}
          data-variant={buttonVariant}
          data-size="small"
          type="button"
        >
          {t('general.learn_more')}
        </StyledButton>
      </TextSection>

      {isDefaultType && <img src={imageUrl} alt={title} />}
    </Wrapper>
  );
}
