import { styled } from '@linaria/react';
import { useAtom } from 'jotai';
import * as React from 'react';

import { Avatar, MenuOptions } from '@local/account';
import { panelStateAtom } from '@local/components';

const Wrapper = styled.main`
  margin: var(--spacing-l5) 0;
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: var(--desktop-gutter);
`;

const SideBar = styled.aside`
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: var(--spacing-l4);
  position: sticky;
  top: calc(var(--desktop-header-content-height) + var(--spacing-l5));
`;

const Content = styled.main`
  max-width: var(--desktop-account-max-width);
  width: 100%;
  & > h1 {
    font-size: 32px;
    line-height: 42px;
  }

  & > div {
    margin-top: var(--spacing-l4);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l6);
  }
`;

interface Props {
  children: React.ReactNode;
  title: string;
}

export function DesktopContentWrapper({ children, title }: Props) {
  const [, setPanelState] = useAtom(panelStateAtom);

  return (
    <Wrapper>
      <SideBar>
        <Avatar />
        <MenuOptions
          onTermsConditionsClick={() => setPanelState('terms_conditions')}
        />
      </SideBar>
      <Content>
        <h1>{title}</h1>
        <div>{children}</div>
      </Content>
    </Wrapper>
  );
}
