import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button, IconButton } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';

import {
  DEFAULT_ICON_SIZE,
  ICON_SIZE_24,
  TABLET_BREAKPOINT,
} from '@local/constants';
import type { CardCarouselProps } from '@local/types';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const CarouselTitle = styled.div`
  margin-bottom: var(--spacing-l3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  & button {
    font-size: 14px !important;
    line-height: 16px !important;
  }
`;

const StyledSwiper = styled(Swiper)`
  width: -webkit-fill-available;
`;

const Carousel = styled.div`
  position: relative;
  display: flex;
  &[data-is-mobile='true'] {
    margin: 0 -17px;
    & .swiper-slide:first-child {
      margin: 0 17px;
    }
  }
  & .swiper-slide {
    width: auto;
    max-width: min-content;
  }
`;

const SeeMoreWrapper = styled.div`
  cursor: pointer;
  font-weight: 600;
  align-content: center;
  text-align: -webkit-center;
  margin: 70px;
  width: max-content;
`;

const SeeMoreArrow = styled(IconButton)`
  background-color: white;
  border: 1px solid var(--lighter-grey-border);
  border-radius: 50%;
  margin-bottom: var(--spacing-l3);
`;

const Arrow = styled(SeeMoreArrow)`
  position: absolute;
  margin: 0 var(--spacing-l4);
  z-index: var(--z-index-1);
  top: 50%;
  transform: translateY(-50%);

  &[class*='arrow-right'] {
    right: calc(-1 * var(--spacing-l4));
    margin: 0;
  }
  &[class*='arrow-left'] {
    left: calc(-1 * var(--spacing-l4));
    margin: 0;
  }
  &.swiper-button-disabled {
    display: none;
  }
  &[data-hidden='true'] {
    display: none;
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  align-content: center;
`;

const SLIDE_GAP = 12;
const MOMENTUM_RATIO = 0.5;
const MOMENTUM_VELOCITY_RATIO = 0.7;

export function CardCarousel({
  id,
  children,
  showViewMore,
  title,
  showMoreAction,
}: CardCarouselProps): JSX.Element {
  const [swiperIndex, setSwiperIndex] = React.useState(0);
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });

  const sliderSetting: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: SLIDE_GAP,
    pagination: { clickable: true },
    modules: [FreeMode],
    freeMode: {
      enabled: true,
      momentum: true,
      momentumRatio: MOMENTUM_RATIO,
      momentumVelocityRatio: MOMENTUM_VELOCITY_RATIO,
    },
    ...(isDesktop && {
      modules: [FreeMode, Navigation],
      navigation: {
        nextEl: `.arrow-right-${id}`,
        prevEl: `.arrow-left-${id}`,
      },
    }),
  };

  return (
    <section data-testid="Card Carousel">
      {!!title && (
        <CarouselTitle>
          <h3>{title}</h3>
          {showViewMore && (
            <Button data-variant="ghost" type="button" onClick={showMoreAction}>
              {t('general.view_all')}
            </Button>
          )}
        </CarouselTitle>
      )}
      <Carousel data-is-mobile={!isDesktop}>
        {isDesktop && (
          <Arrow
            type="button"
            data-variant="bare"
            className={`arrow-left-${id} arrow`}
          >
            <ChevronLeft size={DEFAULT_ICON_SIZE} />
          </Arrow>
        )}
        <StyledSwiper
          {...sliderSetting}
          onSlideChange={(swiper) => {
            setSwiperIndex(swiper.activeIndex);
          }}
        >
          {children}
          {showViewMore && (
            <StyledSwiperSlide>
              <SeeMoreWrapper onClick={showMoreAction}>
                <SeeMoreArrow>
                  <ChevronRight
                    size={ICON_SIZE_24}
                    fill="var(--lighter-text-color)"
                  />
                </SeeMoreArrow>
                <span>{t('general.view_more')}</span>
              </SeeMoreWrapper>
            </StyledSwiperSlide>
          )}
        </StyledSwiper>
        {isDesktop && (
          <Arrow
            type="button"
            data-variant="bare"
            data-hidden={swiperIndex}
            className={`arrow-right-${id} arrow`}
          >
            <ChevronRight size={DEFAULT_ICON_SIZE} />
          </Arrow>
        )}
      </Carousel>
    </section>
  );
}
