import { styled } from '@linaria/react';
import { t } from 'i18next';
import * as React from 'react';

const Content = styled.p`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

const ShowMoreButton = styled.a`
  color: var(--primary);
  cursor: pointer;
  margin-top: var(--spacing-l1);
`;

export function CollapsibleText({
  linesToShow = 3,
  content,
}: {
  linesToShow?: number;
  content: string;
}) {
  const contentRef = React.useRef<null | HTMLDivElement>(null);
  const [shouldShowReadMore, setShowReadMore] = React.useState(false);
  const [isContentExpanded, setIsContentExpanded] = React.useState(false);

  React.useLayoutEffect(() => {
    if (contentRef.current) {
      const { lineHeight } = getComputedStyle(contentRef.current);
      const { clientHeight, scrollHeight } = contentRef.current;
      const maxHeight = parseFloat(lineHeight) * linesToShow;
      setShowReadMore(
        clientHeight !== scrollHeight && clientHeight >= maxHeight,
      );
      contentRef.current.style.webkitLineClamp = linesToShow.toString();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Content ref={contentRef}>{content}</Content>
      {shouldShowReadMore && (
        <ShowMoreButton
          onClick={() => {
            setIsContentExpanded(!isContentExpanded);
            if (contentRef.current)
              contentRef.current.style.webkitLineClamp = isContentExpanded
                ? linesToShow.toString()
                : '';
          }}
          type="button"
        >
          {isContentExpanded ? t('general.show_less') : t('general.show_more')}
        </ShowMoreButton>
      )}
    </>
  );
}
