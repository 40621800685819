import { styled } from '@linaria/react';
import { Button, TextArea } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import * as React from 'react';

import { Footer, panelStateAtom } from '@local/components';
import { INPUT_MAX_CHAR_LENGTH } from '@local/constants';

const Wrapper = styled.div`
  display: grid;
  padding: 0 var(--spacing-l4);
  gap: var(--spacing-l4);

  & > textarea {
    resize: none;
  }
`;
const Input = styled(TextArea)`
  height: 80px;
`;
const CharacterLimit = styled.span`
  margin-bottom: calc(-1 * var(--spacing-l2));
  font: var(--small);
  text-align: end;
`;
interface Props {
  userMemo: string;
  onSubmit: (memo: string) => void;
  onDelete: () => void;
}

export function MemoContent({ userMemo, onSubmit, onDelete }: Props) {
  const [newUserMemo, setNewUserMemo] = React.useState<string>(userMemo);
  const [, setPanelState] = useAtom(panelStateAtom);
  const isNewMemoEmpty = newUserMemo.length === 0;

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setNewUserMemo(e.target.value);
  };

  return (
    <Wrapper>
      <CharacterLimit>
        {newUserMemo.length} / {INPUT_MAX_CHAR_LENGTH}
      </CharacterLimit>
      <Input
        value={newUserMemo}
        onChange={handleOnChange}
        id="name"
        name="name"
        aria-label="User memo"
        data-stretch
        placeholder={t('venue_feat.memo.placeholder')}
        data-testid="Venue Memo Input"
        maxLength={INPUT_MAX_CHAR_LENGTH}
      />
      <Footer>
        <Button
          data-variant="ghost"
          type="button"
          onClick={() => setPanelState(null)}
        >
          {t('general.cancel')}
        </Button>
        <Button
          data-variant="primary"
          type="button"
          onClick={() => (isNewMemoEmpty ? onDelete() : onSubmit(newUserMemo))}
          disabled={userMemo === newUserMemo}
          data-testid="Save Memo Btn"
        >
          {t('general.save')}
        </Button>
      </Footer>
    </Wrapper>
  );
}
