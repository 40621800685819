import { CONFIG } from '@local/configs';
import { apiService } from '@local/services';
import type {
  AuthToken,
  CreateAccountDataType,
  LoginDataType,
  ResetPasswordDataType,
  UserResponseDataType,
} from '@local/types';

const authApi = apiService.extend({
  prefixUrl: CONFIG.VITE_BASE_AUTH_API_URL,
});

export const login = async (data: LoginDataType): Promise<AuthToken> =>
  authApi.post(`v2/user/token`, { json: data }).json<AuthToken>();

export const logout = async (data: AuthToken | null): Promise<void> =>
  authApi.post(`v2/user/revoke`, { json: data }).json();

export const resetPassword = async (
  data: ResetPasswordDataType,
): Promise<void> => authApi.post(`v2/user/password`, { json: data }).json();

export const createAccount = async (
  data: CreateAccountDataType,
  locale: string,
): Promise<AuthToken> => {
  try {
    return await authApi
      .post(`v2/user?locale=${locale}`, { json: data })
      .json<AuthToken>();
  } catch (error: unknown) {
    const { response } = error as {
      response?: { json: () => Promise<unknown> };
    };
    if (response) {
      const errorData = await response.json();
      throw errorData;
    } else {
      throw new Error(
        'Create Account: Network error or unexpected error occurred',
      );
    }
  }
};

export const getUser = async (): Promise<UserResponseDataType> =>
  authApi.get(`v2/user`).json();

export const refreshToken = async (data: AuthToken): Promise<AuthToken> =>
  authApi
    .post(`v2/user/token`, {
      json: {
        grant_type: 'refresh_token',
        refresh_token: data.refresh_token,
      },
    })
    .json<AuthToken>();

export const getCsrfToken = async () => authApi.get(`v2/csrf_token`);
