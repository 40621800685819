import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CONFIG } from '@local/configs';
import { MobileSubHeader } from '@local/layouts';
import type {
  GalleryResponse,
  MenuItem,
  MenuItemsResponse,
  VenueFavoritesResponse,
  VenueResponse,
} from '@local/types';
import { buildBookingFormUrl, translate } from '@local/utils';
import {
  Description,
  Experiences,
  Features,
  ImageCarousel,
  Information,
  MenuItemsSection,
  Overview,
} from '@local/venue';

const VenueContent = styled.div`
  display: grid;
  gap: var(--spacing-l5);
  margin: var(--spacing-l4);
  padding-bottom: 60px;
`;

const Footer = styled.div`
  bottom: 0;
  position: fixed;
  width: 100%;
  display: flex;
  padding: var(--spacing-l4);
  background-color: white;
  z-index: 1;
  & button {
    width: 100%;
    padding: var(--spacing-l3);
  }
`;

const HeaderWrapper = styled.div`
  position: fixed;
  width: -webkit-fill-available;
  z-index: var(--z-index-1);
`;

export function VenueMobile({
  venueSlug,
  venueData,
  gallery,
  menuItems,
  favoritesData,
  allSavedShopsSet,
  experiences,
  shouldShowMenuItems,
  shouldShowExperiences,
}: {
  venueSlug: string;
  venueData: VenueResponse;
  gallery: GalleryResponse | undefined;
  menuItems: MenuItem[];
  favoritesData: VenueFavoritesResponse | undefined;
  allSavedShopsSet: Set<string>;
  experiences: MenuItemsResponse | undefined;
  shouldShowMenuItems: boolean;
  shouldShowExperiences: boolean;
}): JSX.Element {
  const navigate = useNavigate();
  const [t, { language }] = useTranslation();
  const headerTitle = translate(venueData.shops[0].name_translations, language);
  const [searchParams] = useSearchParams();

  const onFindTableClick = () => {
    const searchParamsObject = Object.fromEntries(searchParams);
    const bookingFormURL = buildBookingFormUrl(
      `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${venueSlug}/reserve`,
      searchParamsObject,
    );
    return window.open(bookingFormURL, '_blank');
  };

  return (
    <>
      <HeaderWrapper>
        <MobileSubHeader
          title={headerTitle}
          data-variant="transparent"
          onBackBtnClick={() => navigate(-1)}
        />
      </HeaderWrapper>
      <ImageCarousel images={gallery?.images} />

      <VenueContent>
        <Overview
          venueData={venueData.shops[0]}
          favoritesData={favoritesData?.favorites[0]}
          isSaved={allSavedShopsSet.has(venueData.shops[0]._id)}
        />
        <Description
          taglineTranslations={venueData.shops[0].content_title_translations}
          descriptionTranslations={venueData.shops[0].content_body_translations}
        />
        {CONFIG.VITE_IS_STORYBOOK && (
          <Features features={venueData.shops[0].features} />
        )}
        {shouldShowExperiences && (
          <Experiences venueSlug={venueSlug} experiences={experiences} />
        )}
        {shouldShowMenuItems && <MenuItemsSection items={menuItems} />}
        <Information venueInfo={venueData.shops[0]} />
      </VenueContent>
      <Footer>
        <Button
          data-variant="primary"
          type="button"
          onClick={onFindTableClick}
          data-testid="Venue Find Table Btn"
        >
          {t('venue_feat.find_table')}
        </Button>
      </Footer>
    </>
  );
}
