import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { format } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { TransformedMembershipAuthsResponse } from '@local/types';
import { DateFormat, getDateFormat } from '@local/utils';

const Table = styled.table`
  font: var(--small);
  border-collapse: collapse;
  border-radius: var(--border-radius-small);
  overflow: hidden;

  col:first-child {
    background-color: var(--surface-raised);
  }
  col:last-child {
    width: 100%;
  }

  th {
    font-weight: 600;
  }

  td {
    position: relative;

    & > button {
      position: absolute;
      right: var(--spacing-l2);
      top: var(--spacing-l2);
    }
  }

  th,
  td {
    white-space: nowrap;
    padding: 15px 12px;
    text-align: left;
    border: 1px solid var(--border);
  }
`;

interface Props {
  membership: TransformedMembershipAuthsResponse;
  onClick: () => void;
}

export function MembershipTable({ membership, onClick }: Props) {
  const [, { language }] = useTranslation();
  const { providerName, membershipCode, startDate, locationsUsed } = membership;
  const membershipStartDate = startDate
    ? format(
        new Date(startDate),
        getDateFormat(language, DateFormat.YearMonthDay),
        {
          locale: dateFnsLocales[language as keyof typeof dateFnsLocales],
        },
      )
    : '-';

  return (
    <Table
      data-testid="Membership Table"
      aria-label={`${providerName} membership details`}
    >
      <colgroup>
        <col span={1} />
        <col span={1} />
      </colgroup>
      <tbody>
        <tr>
          <th scope="row">{t('account.membership.program')}</th>
          <td>
            {providerName || '-'}
            <Button
              data-testid="Membership Remove Btn"
              data-variant="danger"
              data-size="small"
              onClick={onClick}
            >
              {t('account.action_btns.remove')}
            </Button>
          </td>
        </tr>
        <tr>
          <th scope="row">{t('account.membership.number')}</th>
          <td>{membershipCode}</td>
        </tr>
        <tr>
          <th scope="row">{t('account.membership.joined')}</th>
          <td>{membershipStartDate}</td>
        </tr>
        <tr>
          <th scope="row">{t('account.membership.locations_used')}</th>
          <td>{locationsUsed.length > 0 ? locationsUsed.join(', ') : '-'}</td>
        </tr>
      </tbody>
    </Table>
  );
}
