import { useAtom } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import type {
  SearchFilterFormType,
  SearchFilterViewType,
  ServiceMode,
  ShopSearchQueryParamsType,
} from '@local/types';
import { transformFiltersToParams } from '@local/utils';

import { DEFAULT_SEARCH_FILTERS } from '../searchConstants';
import { searchFiltersAtom } from '../searchStore';

import { useFetchGeolocation } from './useFetchGeolocation';

interface FilterOptionsType {
  reset?: boolean;
}

export function useSearchFilters() {
  const [, setSearchParams] = useSearchParams();

  const [searchFilters, setSearchFilters] =
    useAtom<ShopSearchQueryParamsType>(searchFiltersAtom);
  const { data: geolocation } = useFetchGeolocation();

  const updateSearchFilters = (
    newSearchFilters: Partial<
      ShopSearchQueryParamsType & { auto_geolocate?: boolean }
    > = {},
    filterOptions: FilterOptionsType = {},
  ) => {
    let updatedFilters: ShopSearchQueryParamsType;
    if (filterOptions.reset) {
      updatedFilters = {
        ...DEFAULT_SEARCH_FILTERS,
        geo_latitude: geolocation?.latitude,
        geo_longitude: geolocation?.longitude,
        ...newSearchFilters,
      };
    } else {
      updatedFilters = {
        ...searchFilters,
        ...newSearchFilters,
      };
    }
    setSearchFilters(updatedFilters);
    setSearchParams(transformFiltersToParams(updatedFilters), {
      replace: true,
    });
  };

  const { getValues, control, setValue, handleSubmit, reset } =
    useForm<SearchFilterFormType>({
      defaultValues: DEFAULT_SEARCH_FILTERS,
      values: {
        cuisines: searchFilters.cuisines,
        service_mode: searchFilters.service_mode,
        shop_universe_id: searchFilters.shop_universe_id,
        budget_dinner_avg_min: searchFilters.budget_dinner_avg_min,
        budget_dinner_avg_max: searchFilters.budget_dinner_avg_max,
        is_smartpay: searchFilters.is_smartpay,
        hub_shop_list_ids: searchFilters.hub_shop_list_ids,
      },
    });

  const handleReset = (view: SearchFilterViewType) => {
    switch (view) {
      case 'cuisines': {
        updateSearchFilters({
          cuisines: DEFAULT_SEARCH_FILTERS.cuisines,
        });
        reset({
          ...searchFilters,
          cuisines: DEFAULT_SEARCH_FILTERS.cuisines,
        });
        break;
      }
      case 'service_mode': {
        updateSearchFilters({
          service_mode: DEFAULT_SEARCH_FILTERS.service_mode,
        });
        reset({
          ...searchFilters,
          service_mode: DEFAULT_SEARCH_FILTERS.service_mode,
        });
        break;
      }
      case 'smartpay': {
        updateSearchFilters({
          is_smartpay: DEFAULT_SEARCH_FILTERS.is_smartpay,
        });
        reset({
          ...searchFilters,
          is_smartpay: DEFAULT_SEARCH_FILTERS.is_smartpay,
        });
        break;
      }
      case 'budget': {
        updateSearchFilters({
          budget_dinner_avg_min: DEFAULT_SEARCH_FILTERS.budget_dinner_avg_min,
          budget_dinner_avg_max: DEFAULT_SEARCH_FILTERS.budget_dinner_avg_max,
        });
        reset({
          ...searchFilters,
          budget_dinner_avg_min: DEFAULT_SEARCH_FILTERS.budget_dinner_avg_min,
          budget_dinner_avg_max: DEFAULT_SEARCH_FILTERS.budget_dinner_avg_max,
        });
        break;
      }
      case 'lists': {
        updateSearchFilters({
          hub_shop_list_ids: DEFAULT_SEARCH_FILTERS.hub_shop_list_ids,
        });
        reset({
          ...searchFilters,
          hub_shop_list_ids: DEFAULT_SEARCH_FILTERS.hub_shop_list_ids,
        });
        break;
      }
      default: {
        const {
          geo_distance,
          search_text,
          name,
          shop_universe_id,
          sort_by,
          ...rest
        } = DEFAULT_SEARCH_FILTERS;
        updateSearchFilters({
          ...rest,
        });
        reset();
        break;
      }
    }
  };

  // TODO: Refactor and DRY up these handlers. REF: https://tablecheck.atlassian.net/browse/DPOR-692
  const handleCuisineClick = (cuisine: string) => {
    const selectedCuisines = getValues().cuisines;
    if (!selectedCuisines) return;

    const newSelectedCuisines = selectedCuisines.includes(cuisine)
      ? selectedCuisines.filter(
          (c) => c.toLowerCase() !== cuisine.toLowerCase(),
        )
      : [...selectedCuisines, cuisine];
    setValue('cuisines', newSelectedCuisines);
  };

  const handleServiceModeClick = (serviceMode: ServiceMode) => {
    const selectedServiceMode = getValues().service_mode;
    if (!selectedServiceMode) return;
    setValue('service_mode', serviceMode);
  };

  const handleSmartpayChange = () => {
    setValue('is_smartpay', !getValues().is_smartpay);
  };

  const handleHubShopListSelect = (hubShopListId: string) => {
    const selectedHubShopListIds = getValues().hub_shop_list_ids;
    if (!selectedHubShopListIds) return;

    const newSelectedHubShopListIds = selectedHubShopListIds.includes(
      hubShopListId,
    )
      ? selectedHubShopListIds.filter(
          (selectedHubShopListId) => selectedHubShopListId !== hubShopListId,
        )
      : [...selectedHubShopListIds, hubShopListId];
    setValue('hub_shop_list_ids', newSelectedHubShopListIds);
  };

  const onSubmit: SubmitHandler<SearchFilterFormType> = (data) => {
    const {
      budget_dinner_avg_max,
      budget_dinner_avg_min,
      is_smartpay: isSmartPay,
      cuisines,
      service_mode,
      shop_universe_id,
      hub_shop_list_ids,
    } = data;
    updateSearchFilters({
      budget_dinner_avg_max,
      budget_dinner_avg_min,
      is_smartpay: isSmartPay,
      cuisines,
      service_mode,
      shop_universe_id,
      hub_shop_list_ids,
    });
  };

  return {
    control,
    getValues,
    handleSmartpayChange,
    handleCuisineClick,
    handleHubShopListSelect,
    handleReset,
    handleServiceModeClick,
    handleSubmit,
    searchFilters,
    setSearchFilters,
    onSubmit,
    setValue,
    updateSearchFilters,
    reset,
  };
}
