import { CONFIG } from '@local/configs';

const { VITE_BASE_CDN_IMAGES_URL } = CONFIG;

export const META_FAVICON = `${VITE_BASE_CDN_IMAGES_URL}favicons/tc/v1.1.0/favicon.ico`;

export const META_IMAGE = `${VITE_BASE_CDN_IMAGES_URL}favicons/tc/v1.1.0/android-chrome-192x192.png`;

export const META_APPLE_TOUCH_ICON = `${VITE_BASE_CDN_IMAGES_URL}favicons/tc/v1.1.0/apple-touch-icon.png`;

export const DEFAULT_VENUE_IMAGE = `${VITE_BASE_CDN_IMAGES_URL}apps/bf/v1.0.0/experience-default-v2.png`;

export const DEFAULT_MENU_ITEM_IMAGE = `${VITE_BASE_CDN_IMAGES_URL}apps/bf/v1.0.0/experience-default-v2.png`;

export const DEFAULT_EXPERIENCE_IMAGE = `${VITE_BASE_CDN_IMAGES_URL}apps/bf/v1.0.0/experience-default-v2.png`;

export const DEFAULT_LIST_IMAGE = `${VITE_BASE_CDN_IMAGES_URL}apps/bf/v1.0.0/experience-default-v2.png`;

export const DEFAULT_MAP_MARKER_IMAGE = `${VITE_BASE_CDN_IMAGES_URL}apps/bf/v1.0.0/experience-default-v2.png`;

export const DEFAULT_PAYMENT_ITEM_IMAGE = `${VITE_BASE_CDN_IMAGES_URL}apps/bf/v1.0.0/experience-default-v2.png`;

export const TABLECHECK_LOGO = `${VITE_BASE_CDN_IMAGES_URL}logos/tc/v1.0.0/logo.svg`;

export const TABLECHECK_LOGO_SYMBOL = `${VITE_BASE_CDN_IMAGES_URL}logos/tc/v1.0.0/logo-symbol.svg`;

export const DOWNLOAD_APP_STORE_LOGO = `${VITE_BASE_CDN_IMAGES_URL}logos/app-stores/v1.0.0/apple.svg`;

export const DOWNLOAD_GOOGLE_PLAY_LOGO = `${VITE_BASE_CDN_IMAGES_URL}logos/app-stores/v1.0.0/google.svg`;

export const YAHOO_JAPAN_LOGO = `${VITE_BASE_CDN_IMAGES_URL}logos/yahoo/yahoo-japan-logo-icon.svg`;

export const GOOGLE_LOGO = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDUzMy41IDU0NC4zIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik01MzMuNSAyNzguNGMwLTE4LjUtMS41LTM3LjEtNC43LTU1LjNIMjcyLjF2MTA0LjhoMTQ3Yy02LjEgMzMuOC0yNS43IDYzLjctNTQuNCA4Mi43djY4aDg3LjdjNTEuNS00Ny40IDgxLjEtMTE3LjQgODEuMS0yMDAuMnoiIGZpbGw9IiM0Mjg1ZjQiLz48cGF0aCBkPSJNMjcyLjEgNTQ0LjNjNzMuNCAwIDEzNS4zLTI0LjEgMTgwLjQtNjUuN2wtODcuNy02OGMtMjQuNCAxNi42LTU1LjkgMjYtOTIuNiAyNi03MSAwLTEzMS4yLTQ3LjktMTUyLjgtMTEyLjNIMjguOXY3MC4xYzQ2LjIgOTEuOSAxNDAuMyAxNDkuOSAyNDMuMiAxNDkuOXoiIGZpbGw9IiMzNGE4NTMiLz48cGF0aCBkPSJNMTE5LjMgMzI0LjNjLTExLjQtMzMuOC0xMS40LTcwLjQgMC0xMDQuMlYxNTBIMjguOWMtMzguNiA3Ni45LTM4LjYgMTY3LjUgMCAyNDQuNGw5MC40LTcwLjF6IiBmaWxsPSIjZmJiYzA0Ii8+PHBhdGggZD0iTTI3Mi4xIDEwNy43YzM4LjgtLjYgNzYuMyAxNCAxMDQuNCA0MC44bDc3LjctNzcuN0M0MDUgMjQuNiAzMzkuNy0uOCAyNzIuMSAwIDE2OS4yIDAgNzUuMSA1OCAyOC45IDE1MGw5MC40IDcwLjFjMjEuNS02NC41IDgxLjgtMTEyLjQgMTUyLjgtMTEyLjR6IiBmaWxsPSIjZWE0MzM1Ii8+PC9zdmc+`;

export const CREDIT_CARD_ICONS = `${VITE_BASE_CDN_IMAGES_URL}logos/credit-cards/v1.0.0/`;

export const LIST_EMPTY_VIEW_IMG = `${VITE_BASE_CDN_IMAGES_URL}apps/din/v3.0.0/empty-lists.png`;

export const RESERVATIONS_EMPTY_VIEW_IMG = `${VITE_BASE_CDN_IMAGES_URL}apps/din/v3.0.0/empty-bookings.png`;

export const TRANSACTIONS_EMPTY_VIEW_IMG = `${VITE_BASE_CDN_IMAGES_URL}apps/din/v3.0.0/empty-transactions.png`;

export const CARDS_EMPTY_VIEW_IMG = `${VITE_BASE_CDN_IMAGES_URL}apps/din/v3.0.0/empty-cards.png`;

export const ACCOUNT_VIEW_IMG = `${VITE_BASE_CDN_IMAGES_URL}apps/din/v3.0.0/account.png`;

export const ACCOUNT_ALT_VIEW_IMG = `${VITE_BASE_CDN_IMAGES_URL}apps/din/v3.0.0/account-alt.png`;

export const SUBSCRIPTIONS_EMPTY_VIEW_IMG = `${VITE_BASE_CDN_IMAGES_URL}apps/din/v3.0.0/empty-subscriptions.png`;

export const RESULTS_EMPTY_VIEW_IMG = `${VITE_BASE_CDN_IMAGES_URL}apps/din/v3.0.0/empty-results.png`;

export const SEARCH_EMPTY_VIEW_IMG = `${VITE_BASE_CDN_IMAGES_URL}apps/din/v3.0.0/empty-search.png`;

export const QR_CODE = `${VITE_BASE_CDN_IMAGES_URL}apps/din/v3.0.0/qr-code.png`;
