import {
  Calendar,
  CheckmarkFilled,
  Hourglass,
  Misuse,
  Timer,
  UserMultiple,
} from '@carbon/icons-react';
import { styled } from '@linaria/react';
import * as dateFnsLocales from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import { DEFAULT_ICON_SIZE } from '@local/constants';
import type { ReservationVenueInfo, Reservation } from '@local/types';
import { DateFormat, getDateFormat, translate } from '@local/utils';

import { ActionBtns } from '../ActionBtns/ActionBtns';

export const ReservationCardWrapper = styled.div`
  padding: var(--spacing-l4);
  border: 1px var(--lighter-grey-border) solid;
  border-radius: var(--border-radius-large);
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-l2);
  margin-bottom: var(--spacing-l2);

  > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l2);
  }

  > img {
    width: 88px;
    object-fit: cover;
    border-radius: var(--border-radius-small);
  }
`;

export const Detail = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-l1);
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-l1);
  margin-bottom: var(--spacing-l3);

  &[data-status='accepted'],
  &[data-status='confirmed'],
  &[data-status='waitlist_called'],
  &[data-status='tentative'] {
    color: var(--success);
  }
  &[data-status='pending'] {
    color: var(--light-blue);
  }
  &[data-status='attended'] {
    color: var(--brand-primary);
  }
  &[data-status='request'],
  &[data-status='waiting'] {
    color: var(--warning-text);
  }
  &[data-status='cancelled'],
  &[data-status='rejected'] {
    color: var(--error-text);
  }
  &[data-status='expired'],
  &[data-status='noshow'] {
    color: var(--lighter-text-color);
  }
`;

const statusIconMap = {
  accepted: <CheckmarkFilled size={DEFAULT_ICON_SIZE} />,
  confirmed: <CheckmarkFilled size={DEFAULT_ICON_SIZE} />,
  tentative: <CheckmarkFilled size={DEFAULT_ICON_SIZE} />,
  waitlist_called: <CheckmarkFilled size={DEFAULT_ICON_SIZE} />,
  pending: <Timer size={DEFAULT_ICON_SIZE} />,
  attended: <CheckmarkFilled size={DEFAULT_ICON_SIZE} />,
  request: <Timer size={DEFAULT_ICON_SIZE} />,
  cancelled: <Misuse size={DEFAULT_ICON_SIZE} />,
  rejected: <Misuse size={DEFAULT_ICON_SIZE} />,
  expired: <Misuse size={DEFAULT_ICON_SIZE} />,
  noshow: <Misuse size={DEFAULT_ICON_SIZE} />,
  waiting: <Hourglass size={DEFAULT_ICON_SIZE} />,
};

export function ReservationCard({
  reservation,
  venue,
}: {
  reservation: Reservation;
  venue: ReservationVenueInfo;
}): JSX.Element {
  const [t, { language }] = useTranslation();

  return (
    <ReservationCardWrapper data-testid="Reservation Card">
      <Details>
        <div>
          <h5>{translate(venue.name_translations, language)}</h5>
          <Detail data-testid="Reservation Date">
            <Calendar size={DEFAULT_ICON_SIZE} />
            <span>
              {formatInTimeZone(
                new Date(reservation.start_at),
                venue.time_zone,
                getDateFormat(language, DateFormat.MonthDayTime),
                {
                  locale:
                    dateFnsLocales[language as keyof typeof dateFnsLocales],
                },
              )}
            </span>
          </Detail>
          <Detail data-testid="Reservation Pax">
            <UserMultiple size={DEFAULT_ICON_SIZE} />
            <span>{reservation.num_people}</span>
          </Detail>
        </div>
        <img
          src={venue.search_image ?? DEFAULT_VENUE_IMAGE}
          alt={venue.slug}
          data-testid="Reservation Venue Image"
        />
      </Details>

      <Status
        data-testid="Reservation Status"
        data-status={reservation.customer_status}
      >
        {statusIconMap[reservation.customer_status]}
        <span>
          {t(`reservations_feat.status.${reservation.customer_status}`)}
        </span>
      </Status>

      <ActionBtns reservation={reservation} venue={venue} />

      {/* TODO: add Bill component DPOR-656 */}
    </ReservationCardWrapper>
  );
}
