import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';

import { deleteUserMemoVenue } from '../userApi';

export const useDeleteUserMemoQuery = (venueSlug: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteUserMemoVenue(venueSlug),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [USER_QUERY_KEY, 'memo', venueSlug],
        })
        .catch(console.error);
    },
    onError: (error) => {
      console.error('Error deleting user memo:', error);
    },
  });
};
