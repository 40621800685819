import { Edit } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { useAtomValue, useSetAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetUserQuery } from '@local/auth';
import { panelStateAtom, VenueCard, ViewSwitcherBtn } from '@local/components';
import {
  DEFAULT_ICON_SIZE,
  DEFAULT_LATITUDE_TOKYO,
  DEFAULT_LONGITUDE_TOKYO,
} from '@local/constants';
import { MobileSubHeader } from '@local/layouts';
import { listFiltersAtom, useListView } from '@local/list';
import {
  DEFAULT_ZOOM_LEVEL,
  selectedVenueAtom,
  useMapService,
} from '@local/map-system';
import { buildUrl, translate } from '@local/utils';

import { ListViewContent } from './ListViewContent';

const MobilePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const Map = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;

  &[data-hidden='true'] {
    visibility: hidden;
  }
`;
const MapVenueCard = styled(VenueCard)`
  position: absolute;
  width: 95svw;
  max-width: var(--map-card-width);
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--border-radius-large);
  padding: var(--spacing-l2);
`;

export function ListViewMobile() {
  const [, { language }] = useTranslation();
  const [searchParams] = useSearchParams();
  const setPanelState = useSetAtom(panelStateAtom);
  const listFilters = useAtomValue(listFiltersAtom);
  const selectedVenue = useAtomValue(selectedVenueAtom);
  const [shouldShowMap, setShouldShowMap] = React.useState(false);
  const navigate = useNavigate();
  const { data: user } = useGetUserQuery();
  const {
    listDetail: { data: listData },
    mapVenues,
    availabilityToShow,
  } = useListView();
  const { venue, shouldShowMapVenueCard } = selectedVenue;
  const shouldShowViewSwitch =
    user && !shouldShowMap && listData && listData.list?.shops.length > 0;
  const listName = shouldShowMap ? listData?.list?.name : '';

  useMapService({
    venues: mapVenues,
    language,
    center: [
      mapVenues[0]?.geocode?.lng ?? DEFAULT_LONGITUDE_TOKYO,
      mapVenues[0]?.geocode?.lat ?? DEFAULT_LATITUDE_TOKYO,
    ],
    zoom: DEFAULT_ZOOM_LEVEL,
    cameraTransition: 'jumpTo',
  });

  return (
    <>
      {user && (
        <MobileSubHeader
          title={listName}
          onBackBtnClick={() => {
            if (shouldShowMap) {
              setShouldShowMap(false);
            } else navigate(-1);
          }}
          onRightIconClick={() => setPanelState('list_options')}
          icon={<Edit size={DEFAULT_ICON_SIZE} />}
          withGradient={false}
        />
      )}
      <MobilePageWrapper data-testid="List View Page Root">
        <ListViewContent />
      </MobilePageWrapper>

      {shouldShowViewSwitch && (
        <ViewSwitcherBtn onClick={() => setShouldShowMap(true)} />
      )}

      <Map
        id="map"
        data-testid="List View Page Map"
        style={{ '--geolocate-control-bottom': '80px' } as React.CSSProperties}
        data-hidden={!shouldShowMap}
      >
        {venue && shouldShowMapVenueCard && shouldShowMap && (
          <MapVenueCard
            data-testid="Map Venue Card"
            venue={{
              slug: venue.slug,
              title: translate(venue.name_translations, language),
              cuisines: venue.cuisines,
              lunchAvg: venue.budget_lunch_avg,
              dinnerAvg: venue.budget_dinner_avg,
              imageUrls: [venue.search_image],
              availability: venue.availability,
            }}
            onClick={() =>
              navigate(buildUrl(`/${language}/${venue.slug}`, searchParams))
            }
          >
            <VenueCard.Header
              icon="bookmarkFilled"
              onIconClick={() => setPanelState(user ? 'save_list' : 'login')}
              buttonTestId="Map Venue Card Bookmark Btn"
            />
            <VenueCard.Info />
            <VenueCard.Budget />
            <VenueCard.SidePicture />
            {availabilityToShow() === 'date' && (
              <VenueCard.DateTable
                query={{
                  pax: listFilters.num_people,
                  time: listFilters.time,
                }}
              />
            )}
            {availabilityToShow() === 'time' && (
              <VenueCard.TimeSlots
                query={{
                  date: listFilters.date,
                  pax: listFilters.num_people,
                  time: listFilters.time,
                }}
              />
            )}{' '}
          </MapVenueCard>
        )}
      </Map>
    </>
  );
}
