import { zodResolver } from '@hookform/resolvers/zod';
import { styled } from '@linaria/react';
import { Anchor, Button, Input } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  Alert,
  DEFAULT_SELECTED_COUNTRY,
  Footer,
  PhoneField,
} from '@local/components';

import { useCreateAccountQuery } from '../../hooks/useCreateAccountQuery';
import { PasswordField } from '../PasswordField/PasswordField';
import { PasswordRequirements } from '../PasswordRequirements/PasswordRequirements';

import { signUpSchema, SignUpSchemaType } from './signUpSchema';

const Form = styled.form`
  display: grid;
  gap: var(--spacing-l4);
  font: var(--body-1);
  padding: 0 var(--spacing-l4);

  & > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l2);
  }

  & > fieldset {
    display: grid;
    gap: var(--spacing-l4);
    grid-template-columns: repeat(2, 1fr);
  }

  & > fieldset > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l2);
  }
`;

export const StyledParagraph = styled.p`
  color: var(--text-alt);
  font: var(--body-2);
  & > a {
    color: var(--secondary);
  }
`;

export function SignUpContent({ onSubmit }: { onSubmit: () => void }) {
  const [, { language }] = useTranslation();

  const form = useForm<SignUpSchemaType>({
    resolver: zodResolver(signUpSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      phone: DEFAULT_SELECTED_COUNTRY.code,
      locale: language,
    },
  });

  const {
    formState: { errors, dirtyFields, isSubmitted },
    setError,
  } = form;
  const { mutate: createAccount, isPending } = useCreateAccountQuery(language);
  const handleSubmit = form.handleSubmit((fields) => {
    createAccount(fields, {
      onSuccess: () => onSubmit(),
      onError: (error) => {
        const emailErrorMessage = error.errors?.email?.[0];
        if (emailErrorMessage) {
          setError('email', {
            type: 'manual',
            message: emailErrorMessage,
          });
        }
      },
    });
  });

  return (
    <Form
      data-testid="Sign Up Modal"
      autoComplete="off"
      onSubmit={(e) => void handleSubmit(e)}
    >
      <div>
        <Input
          {...form.register('email')}
          data-variant={errors.email ? 'error' : undefined}
          aria-label="email"
          data-stretch
          placeholder={t('auth.email')}
          data-testid="Account Sign Up Email Input"
        />
        <Alert
          data-testid="Error Email"
          data-variant="error"
          data-layout="icon"
          isShow={!!errors.email}
        >
          {errors.email?.message
            ? errors.email.message
            : t('auth.error.email_required')}
        </Alert>
      </div>

      <fieldset>
        <div>
          <Input
            {...form.register('first_name')}
            data-variant={errors.first_name ? 'error' : undefined}
            aria-label="first name"
            data-stretch
            placeholder={t('auth.first_name')}
            data-testid="Account Sign Up First Name Input"
          />
          <Alert
            data-testid="Error First Name"
            data-variant="error"
            data-layout="icon"
            isShow={!!errors.first_name}
          >
            {t('auth.error.first_name_required')}
          </Alert>
        </div>
        <div>
          <Input
            {...form.register('last_name')}
            data-variant={errors.last_name ? 'error' : undefined}
            aria-label="last name"
            data-stretch
            placeholder={t('auth.last_name')}
            data-testid="Account Sign Up Last Name Input"
          />
          <Alert
            data-testid="Error Last Name"
            data-variant="error"
            data-layout="icon"
            isShow={!!errors.last_name}
          >
            {t('auth.error.last_name_required')}
          </Alert>
        </div>
      </fieldset>

      <Controller
        name="phone"
        control={form.control}
        render={({ field: { onChange } }) => (
          <div>
            <PhoneField
              onChange={onChange}
              defaultCountry={DEFAULT_SELECTED_COUNTRY}
              hasError={!!errors.phone}
            />
            <Alert
              data-testid="Error Phone"
              data-variant="error"
              data-layout="icon"
              isShow={!!errors.phone}
            >
              {t('auth.error.phone_number_required')}
            </Alert>
          </div>
        )}
      />

      <Controller
        name="password"
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <>
            <PasswordField
              value={value}
              onChangeValue={onChange}
              data-variant={errors.password ? 'error' : undefined}
              name="password"
              aria-label="password"
              data-stretch
              placeholder={t('auth.create_password')}
              data-testid="Account Sign Up Pwd Input"
            />
            <PasswordRequirements
              password={value}
              email={form.watch('email')}
              isDirty={!!dirtyFields.password}
              isSubmitted={isSubmitted}
            />
          </>
        )}
      />

      <StyledParagraph>
        <Trans
          i18nKey="auth.terms_and_policy"
          components={{
            bold: (
              <Anchor
                target="_blank"
                href={`https://www.tablecheck.com/${language}/policy/privacy/`}
                {...({} as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
              />
            ),
          }}
        />
      </StyledParagraph>
      <Footer>
        <Button
          data-testid="Account Signup Btn"
          data-variant="primary"
          aria-busy={isPending}
          disabled={isPending}
          type="submit"
        >
          {t('auth.sign_up')}
        </Button>
      </Footer>
    </Form>
  );
}
