import { LocaleCode } from '@tablecheck/locales';
import { Calendar } from '@tablecheck/tablekit-react-datepicker';
import currency from 'currency.js';
import {
  format,
  getDate,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
// eslint-disable-next-line import/order
import * as dateFnsLocales from 'date-fns/locale';
import { t } from 'i18next';
import { isNaN } from 'lodash-es';

import { ServiceFee, TaxFee } from '@local/types';

import currencyFormats from './currencies.json';

export type CurrencyFormat = keyof typeof currencyFormats;

type CurrencyFormats = {
  [key in CurrencyFormat]?: {
    precision: number;
    pattern: string;
    negativePattern: string;
    symbol: string;
  };
};

const orderedMenuDays = ['su', 'm', 'tu', 'w', 'th', 'f', 'sa'];
const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const CURRENCY_FORMATS = Object.keys(
  currencyFormats as unknown as CurrencyFormats,
).reduce<CurrencyFormats>((result, currencyKey) => {
  const {
    unit,
    precision,
    format: currencyFormat,
  } = currencyFormats[currencyKey as CurrencyFormat];
  const pattern = currencyFormat
    .replace('%u', '!')
    .replace('%n', '#')
    .replace(/ /gi, '');
  result[currencyKey as CurrencyFormat] = {
    precision,
    pattern,
    negativePattern: `-${pattern}`,
    symbol: unit,
  };
  return result;
}, {} as CurrencyFormats);

export const formatCurrency = (
  value: number,
  currencyFormat: CurrencyFormat,
): string => {
  const options = { ...CURRENCY_FORMATS[currencyFormat as CurrencyFormat] };
  if (value % 1.0 === 0) {
    // By default do not show decimal values unless they exist
    // $40, $40.50 is correct. $40.00 is not correct
    options.precision = 0;
  }
  return currency(value, options).format();
};

export const formatPrice = (value?: string | number | null): number => {
  if (!value) {
    return 0;
  }
  if (typeof value === 'string') {
    const formattedValue = parseFloat(value);
    return isNaN(formattedValue) ? 0 : formattedValue;
  }
  return value;
};

export const formatDistance = (value: string): string => {
  const distance = parseFloat(value);

  if (!distance) {
    return '';
  }

  if (distance < 1000) {
    return `${Math.floor(distance)}m`;
  }

  return `${Math.floor(distance / 100) / 10}km`;
};

export function getFormattedDays(days: string[], language: string): string {
  if (!days?.length) {
    return '';
  }

  if (days.length === 7) {
    return t('general.daily');
  }

  return days
    .map((day) => WEEKDAYS[orderedMenuDays.indexOf(day)])
    .join(language === LocaleCode.Arabic ? '، ' : ', ');
}

export const getFormattedTime = ({
  date,
  time,
  timeFormat,
}: {
  date: string;
  time: number;
  timeFormat: string;
}): string => {
  const setTime = setSeconds(setMinutes(setHours(new Date(date), 0), 0), time);
  return format(setTime, timeFormat);
};

export const getFormattedDay = ({ date }: { date: string }): string =>
  getDate(parseISO(date)).toString();

export const getFormattedTimeFromDate = ({
  date,
  timezone,
}: {
  date: string;
  timezone: string;
}): string => format(utcToZonedTime(date, timezone), 'HH:mm');

export const getServiceFeeAndTaxTypeKey = (
  taxType: TaxFee,
  serviceFeeType: ServiceFee,
): string => {
  const serviceFeeCaption =
    [ServiceFee.None, ServiceFee.Included].indexOf(serviceFeeType) > -1
      ? serviceFeeType
      : ServiceFee.Excluded;

  return `service_fee_and_tax_type.service_fee_${serviceFeeCaption}_tax_${taxType}`;
};

export const getPrice = ({
  amount,
  showZero,
  amountLimit,
  currencyFormat,
}: {
  amount: string;
  showZero: boolean;
  amountLimit?: number;
  currencyFormat: CurrencyFormat;
}): string => {
  const formattedAmount = formatPrice(amount);
  if (!(formattedAmount || (showZero && formattedAmount === 0))) {
    return '-';
  }
  if (amountLimit && formattedAmount > amountLimit) {
    return t('general.more_than_amount', {
      amount: formatCurrency(amountLimit, currencyFormat),
    });
  }
  return formatCurrency(formattedAmount, currencyFormat);
};

export const getFormattedApiDate = (dateStr: string): string =>
  format(new Date(dateStr), 'yyyy-MM-dd');

export const getFormattedDisplayDate = (dateStr: string) => {
  const date = new Date(dateStr);
  //  only month and day is shown when its current year and full date is shown when its not
  const today = new Date();
  if (date.getFullYear() === today.getFullYear()) {
    return format(date, 'd MMM', { locale: dateFnsLocales.enUS });
  }
  return format(date, 'd MMM yyyy', { locale: dateFnsLocales.enUS });
};
export const getFormattedMonths = (calendar: Calendar) =>
  format(new Date(calendar.year, calendar.month), 'MMMM', {
    locale: dateFnsLocales.enUS,
  });
