import { styled } from '@linaria/react';

export const Location = styled.div`
  font: var(--body-2);
`;

export const Details = styled.div`
  font: var(--body-2);
  display: flex;
  overflow: hidden;
  white-space: nowrap;
`;

export const AveragePrice = styled.div`
  display: flex;
  & svg {
    align-self: center;
  }

  & span:first-child {
    margin: 0 var(--spacing-l1);
    align-content: center !important;
  }
`;

export const Smartpay = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  padding: var(--spacing-l1) 6px;
  background-color: var(--neutral-surface);
  color: var(--grey-800);
  border-radius: var(--border-radius-small);
  width: fit-content;
  font-size: 12px;
  line-height: 18px;
`;

export const SmartpayLogo = styled.img`
  align-self: center;
  width: 12px;
  height: 12px;
  filter: brightness(0) saturate(100%) invert(9%) sepia(11%) saturate(20%)
    hue-rotate(327deg) brightness(109%) contrast(86%);
`;

export const VenueDetails = styled.div`
  display: grid;
  grid-template-rows: none;
  gap: var(--spacing-l2);
`;

export const OverviewSection = styled.section`
  display: grid;
  gap: var(--spacing-l4);
`;

export const StyledPrice = styled.div`
  & span {
    font-weight: 600;
    color: black;
  }
  color: var(--text-subtle);
`;
