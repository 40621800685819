import { useQuery } from '@tanstack/react-query';

import { useUniverse } from '@local/utils';

import { fetchStoryblokContent } from '../homeApi';

const JAPAN_INDEX = 0;
const SINGAPORE_INDEX = 1;

export const useFetchStoryblokContent = () => {
  const { isJapanUniverse } = useUniverse();
  return useQuery({
    queryKey: ['storyblokContent'],
    queryFn: () => fetchStoryblokContent(),
    select: (data) =>
      data.stories[isJapanUniverse ? JAPAN_INDEX : SINGAPORE_INDEX].content,
    staleTime: Infinity,
  });
};
