import { t } from 'i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';

import { AuthContent } from '@local/auth';
import {
  nextPanelActionAtom,
  Panel,
  panelStateAtom,
  toastContentAtom,
  toastIsOpenAtom,
  useToast,
} from '@local/components';
import {
  AUTH_PANEL_STATES,
  DEFAULT_LATITUDE_TOKYO,
  DEFAULT_LONGITUDE_TOKYO,
  TABLET_BREAKPOINT,
} from '@local/constants';
import {
  CreateNewListContent,
  SaveToListContent,
  useCreateListQuery,
  useSaveOrUnsaveShopToListQuery,
} from '@local/list';
import {
  DEFAULT_ZOOM_LEVEL,
  selectedVenueAtom,
  useMapService,
} from '@local/map-system';
import {
  searchFiltersAtom,
  useExplore,
  useFetchGeolocation,
} from '@local/search';
import type { GeolocationResponseType, MapVenue } from '@local/types';

import { ExploreDesktop } from './ExploreDesktop';
import { ExploreMobile } from './ExploreMobile';

export function ExploreRoot() {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const [searchParams] = useSearchParams();
  const [, { language }] = useTranslation();
  const selectedVenue = useAtomValue(selectedVenueAtom);
  const searchFilters = useAtomValue(searchFiltersAtom);
  const { venue } = selectedVenue;
  const { mutate: createList } = useCreateListQuery();
  const { mutate: saveOrUnsaveShopToList } = useSaveOrUnsaveShopToListQuery();
  const { data: geolocation } = useFetchGeolocation();
  const { saveVenueToastContent, errorToastContent, createVenueToastContent } =
    useToast();
  const setIsToastOpen = useSetAtom(toastIsOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const { shopSearchQuery } = useExplore();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const [nextPanelAction, setNextPanelAction] = useAtom(nextPanelActionAtom);
  const shouldAutoGeolocate = searchParams.get('auto_geolocate') !== 'false';

  const venues = React.useMemo(() => {
    if (shopSearchQuery.data?.shops) {
      return shopSearchQuery.data.shops
        .filter((shop) => shop.geocode)
        .map((shop) => ({
          id: shop._id,
          slug: shop.slug,
          geocode: {
            lng: shop.geocode.lon,
            lat: shop.geocode.lat,
          },
          name_translations: shop.name_translations,
          location_name_translations: shop.location_name_translations,
          cuisines: shop.cuisines,
          budget_dinner_avg: shop.budget_dinner_avg,
          budget_lunch_avg: shop.budget_lunch_avg,
          search_image: shop.search_image,
          availability: shop.availability,
        })) as MapVenue[];
    }
    return [];
  }, [shopSearchQuery.data]);

  const map = useMapService({
    venues,
    language,
    zoom: DEFAULT_ZOOM_LEVEL,
    center: [
      searchFilters.geo_longitude ??
        geolocation?.longitude ??
        DEFAULT_LONGITUDE_TOKYO,
      searchFilters.geo_latitude ??
        geolocation?.latitude ??
        DEFAULT_LATITUDE_TOKYO,
    ],
    geolocateControl: {
      auto: shouldAutoGeolocate,
      hideOnSelect: !isDesktop,
      usersLocation: geolocation as GeolocationResponseType,
    },
  });

  return (
    <>
      {isDesktop ? <ExploreDesktop map={map} /> : <ExploreMobile map={map} />}

      <Panel
        isOpen={!!panelState}
        onClose={() => {
          setPanelState(null);
          setNextPanelAction(null);
        }}
      >
        {AUTH_PANEL_STATES.includes(panelState) && (
          <AuthContent
            onSubmit={() => {
              setPanelState(null);
              if (nextPanelAction) {
                nextPanelAction();
                setNextPanelAction(null);
              }
            }}
            subheaderContent={
              <Trans
                i18nKey="auth.login_feat_unlock"
                components={{ bold: <strong /> }}
              />
            }
          />
        )}

        {panelState === 'save_list' && venue && (
          <SaveToListContent
            venueId={venue.id}
            onCreateListClick={() => setPanelState('create_list')}
            onSubmit={(listsToUpdate: { save: string[]; unsave: string[] }) => {
              saveOrUnsaveShopToList(
                {
                  venueId: venue.id,
                  listIdsToUpdate: listsToUpdate,
                },
                {
                  onSuccess: (response) => {
                    setPanelState(null);
                    setToastContent(
                      saveVenueToastContent(response.lists, setPanelState),
                    );
                    setIsToastOpen(true);
                  },
                  onError: () => {
                    setPanelState(null);
                    setToastContent(
                      errorToastContent(t('toast.unsuccessful_list_save')),
                    );
                    setIsToastOpen(true);
                  },
                },
              );
            }}
            onCancel={() => setPanelState(null)}
          />
        )}

        {panelState === 'create_list' && venue && (
          <CreateNewListContent
            venueId={venue.id}
            imageUrl={venue.search_image}
            onSubmit={(newListData) => {
              createList(newListData, {
                onSuccess: () => {
                  setToastContent(
                    createVenueToastContent(newListData.name, setPanelState),
                  );
                  setIsToastOpen(true);
                  setPanelState(null);
                },
              });
            }}
            onCancel={() => setPanelState(null)}
          />
        )}
      </Panel>
    </>
  );
}
