import { styled } from '@linaria/react';
import { DialogProps } from '@radix-ui/react-alert-dialog';
import * as Dialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useAtom } from 'jotai';
import * as React from 'react';
import { Drawer } from 'vaul';

import { TcDrawer } from '@local/components';

import { useExplore } from '../../hooks/useExplore';
import { SNAP_POINTS } from '../../searchConstants';
import { searchResultsPanelAtom } from '../../searchStore';
import { SortBy } from '../SortBy/SortBy';

const SearchResultsDrawerContent = styled(TcDrawer.Content)`
  bottom: var(--mobile-footer-height);
  z-index: var(--zindex-dialog);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  max-height: 49.5svh;
  overflow: hidden;

  &[data-is-max-height='true'] {
    overflow: scroll;
    max-height: 100%;
    position: relative;
    border-radius: 0;
    box-shadow: none;
    border: none;
    display: flex;
    bottom: 0;
    padding-bottom: 0;
    margin-top: calc(-1 * var(--spacing-l4));
    transition: none !important;
    align-items: flex-start;
  }
`;

const SearchResultsHeader = styled(TcDrawer.Header)`
  &:not([data-is-max-height='true']) {
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: var(--spacing-l3);
  }
`;

const SearchResultsTitle = styled(TcDrawer.Title)`
  font: var(--label);
  text-align: center;
`;

type Props = {
  children: React.ReactNode;
} & DialogProps;

export function SearchResultsPanel({ children }: Props) {
  const { searchCount, panelTitle, shopSearchQuery } = useExplore();
  const { data, isLoading } = shopSearchQuery;
  const [{ isOpen, snap }, setSearchResultsPanel] = useAtom(
    searchResultsPanelAtom,
  );
  const [searchContainer, setSearchContainer] =
    React.useState<HTMLElement | null>(null);

  const shouldShowSortBy = !isLoading && !!data && searchCount > 0;
  const isFullHeight = snap === 1;

  React.useEffect(() => {
    setSearchContainer(document.getElementById('search'));
  }, []);

  return (
    <Drawer.Root
      onClose={() =>
        setSearchResultsPanel((prev) => ({ ...prev, isOpen: false }))
      }
      open={isOpen}
      fixed
      modal={false}
      snapPoints={SNAP_POINTS}
      dismissible={false}
      activeSnapPoint={snap}
      setActiveSnapPoint={(snapPoint) =>
        setSearchResultsPanel((prev) => ({ ...prev, snap: snapPoint }))
      }
    >
      <Drawer.Portal container={searchContainer}>
        <SearchResultsDrawerContent
          data-is-max-height={isFullHeight}
          data-testid="Search Results Panel"
          aria-describedby="" // Fixes the "Missing `Description` or `aria-describedby={undefined}` for {DialogContent}" warning
        >
          <SearchResultsHeader data-is-max-height={isFullHeight}>
            {!isFullHeight && (
              <TcDrawer.Handle data-testid="Search Results Handle" />
            )}
            {searchCount > 0 && (
              <SearchResultsTitle data-testid="Search Results Title">
                {panelTitle}
              </SearchResultsTitle>
            )}
            {shouldShowSortBy && isFullHeight && <SortBy />}
          </SearchResultsHeader>
          <TcDrawer.Body data-testid="Search Results Body">
            {children}
            <VisuallyHidden asChild>
              <Dialog.Title />
            </VisuallyHidden>
          </TcDrawer.Body>
        </SearchResultsDrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
