import { styled } from '@linaria/react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { TABLET_BREAKPOINT } from '@local/constants';

interface Props {
  children: React.ReactNode;
}

const FooterWrapper = styled.div`
  bottom: 0;
  position: sticky;
  width: 100%;
  display: flex;
  padding: var(--spacing-l4) 0;
  background-color: white;
  z-index: var(--z-index-1);
  & button {
    width: 100%;
    padding: var(--spacing-l3);
  }

  &[data-grid='true'] {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-l4);
  }

  &[data-is-desktop='true'] {
    max-width: -webkit-fill-available;
    grid-template-columns: min-content min-content;
    justify-content: end;
    align-items: self-end;
    & button {
      max-width: min-content;
    }
  }
`;

export function Footer({ children }: Props): JSX.Element {
  const validChildren = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  );
  const numOfButtons = validChildren.length;
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  return (
    <FooterWrapper data-is-desktop={isDesktop} data-grid={numOfButtons > 1}>
      {children}
    </FooterWrapper>
  );
}
