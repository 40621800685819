import { styled } from '@linaria/react';
import { Badge } from '@tablecheck/tablekit-react-css';
import { useTranslation } from 'react-i18next';

import { TABLECHECK_LOGO_SYMBOL } from '@local/assets';
import { CurrencyFormat } from '@local/utils';

import { Price } from '../../Price/Price';
import { useVenueCardContext } from '../VenueCardContext';

const StyledParagraph = styled.p`
  display: flex;
  gap: var(--spacing-l3);
  align-items: center;
  font: var(--body-2);
  font-weight: 600;
  div {
    display: flex;
    gap: var(--spacing-l1);
  }
  & div > span[data-text] {
    color: var(--text-subtle);
    font: var(--body-2);
  }
`;

export const TableCheckPay = styled(Badge)`
  span {
    font: var(--body-2);
  }
`;

export const TableCheckPayLogo = styled.img`
  align-self: center;
  width: 12px;
  height: 12px;
`;

export function Budget() {
  const { venue } = useVenueCardContext();
  const { lunchAvg, dinnerAvg, currency, hasTableCheckPay } = venue;
  const [t] = useTranslation();
  return (
    <StyledParagraph>
      {lunchAvg && (
        <div>
          <Price
            amount={lunchAvg}
            currency={currency as CurrencyFormat}
            withAmountLimit
          />
          <span data-text>{t('general.lunch')}</span>
        </div>
      )}
      {dinnerAvg && (
        <div>
          <Price
            amount={dinnerAvg}
            currency={currency as CurrencyFormat}
            withAmountLimit
          />
          <span data-text>{t('general.dinner')}</span>
        </div>
      )}
      {hasTableCheckPay && (
        <TableCheckPay data-size="x-small">
          <TableCheckPayLogo
            src={TABLECHECK_LOGO_SYMBOL}
            alt="TableCheck pay logo"
          />
          <span>{t('search.smartpay')}</span>
        </TableCheckPay>
      )}
    </StyledParagraph>
  );
}
