import {
  Bookmark,
  Bullhorn,
  Calendar,
  Chat,
  ChevronRight,
  Document,
  Help,
  Purchase,
  Receipt,
  Store,
  UserAvatar,
} from '@carbon/icons-react';
import { t } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { useGetUserQuery } from '@local/auth';
import { CONFIG } from '@local/configs';
import {
  BASE_SUPPORT_EMAIL,
  BASE_SUPPORT_SITE_URL,
  ICON_SIZE_24,
  TABLET_BREAKPOINT,
} from '@local/constants';

type UserOptions =
  | {
      title: string;
      icon: JSX.Element;
      onClick: () => void;
      dataTestId?: string;
      rightIcon?: JSX.Element | null;
      path?: string;
    }
  | 'separator';

interface Props {
  onTermsConditionsClick: () => void;
}

export const useMenuOptions = ({
  onTermsConditionsClick,
}: Props): UserOptions[] => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const navigate = useNavigate();
  const { data: user } = useGetUserQuery();
  const [, { language }] = useTranslation();

  const loggedInOptions: UserOptions[] = React.useMemo(
    () => [
      {
        title: t('account.profile'),
        icon: <UserAvatar size={ICON_SIZE_24} />,
        onClick: () => navigate(`/${language}/account/edit`),
        dataTestId: 'Account Profile Btn',
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
        path: '/edit',
      },
      {
        title: t('navbar.bookings'),
        icon: <Calendar size={ICON_SIZE_24} />,
        onClick: () => {
          navigate(`/${language}/account/reservations/upcoming`);
        },
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
      },
      {
        title: t('navbar.lists'),
        icon: <Bookmark size={ICON_SIZE_24} />,
        onClick: () => {
          navigate(`/${language}/lists`);
        },
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
      },
      {
        title: t('account.transactions'),
        icon: <Receipt size={ICON_SIZE_24} />,
        onClick: () => {
          navigate(`/${language}/account/payments`);
        },
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
        dataTestId: 'Account Payments Btn',
        path: '/payments',
      },
      {
        title: t('account.saved_cards'),
        icon: <Purchase size={ICON_SIZE_24} />,
        onClick: () => {
          navigate(`/${language}/account/credit-cards`);
        },
        dataTestId: 'Account Credit Cards Btn',
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
        path: '/credit-cards',
      },
      {
        title: t('account.communications.title'),
        icon: <Bullhorn size={ICON_SIZE_24} />,
        onClick: () => navigate(`/${language}/account/favorites`),
        dataTestId: 'Account Communications Btn',
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
        path: '/favorites',
      },
      'separator',
    ],
    [isDesktop, language, navigate],
  );

  const commonOptions: UserOptions[] = React.useMemo(
    () => [
      {
        title: t('account.help'),
        icon: <Help size={ICON_SIZE_24} />,
        onClick: () => {
          window.open(`${BASE_SUPPORT_SITE_URL}hc/${language}`, '_blank');
        },
      },
      {
        title: t('account.share_feedback'),
        icon: <Chat size={ICON_SIZE_24} />,
        onClick: () => {
          window.location.href = `mailto:${BASE_SUPPORT_EMAIL}?subject=[DPOR] User feedback on beta`;
        },
      },
      {
        title: t('account.for_restaurateurs'),
        icon: <Store size={ICON_SIZE_24} />,
        onClick: () => {
          window.open(
            `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/join/`,
            '_blank',
          );
        },
      },
      {
        title: t('account.terms_conditions.title'),
        icon: <Document size={ICON_SIZE_24} />,
        onClick: onTermsConditionsClick,
      },
    ],
    [language, onTermsConditionsClick],
  );

  return React.useMemo(
    () => (user ? [...loggedInOptions, ...commonOptions] : commonOptions),
    [user, loggedInOptions, commonOptions],
  );
};
