/* eslint-disable @typescript-eslint/naming-convention */
import {
  Locale,
  LocaleCode,
  getDefaultLocale,
  locales,
  ordered,
} from '@tablecheck/locales';
import i18next from 'i18next';
import { uniq } from 'lodash-es';

import { SUPPORTED_LOCALES } from './locales';
import { PRIORITIZED_LANGUAGES_BY_COUNTRY } from './prioritizedLanguagesByCountry';

if (!Array.isArray(SUPPORTED_LOCALES) || SUPPORTED_LOCALES.length === 0) {
  console.error('SUPPORTED_LOCALES is empty or not properly imported');
}

const ASIAN_LOCALES = [
  LocaleCode.Japanese,
  LocaleCode.Korean,
  LocaleCode.ChineseSimplified,
  LocaleCode.ChineseTraditional,
];

export function isAsianLocale(
  locale: string,
): locale is Extract<
  LocaleCode,
  | LocaleCode.Japanese
  | LocaleCode.Korean
  | LocaleCode.ChineseSimplified
  | LocaleCode.ChineseTraditional
> {
  return ASIAN_LOCALES.includes(locale as LocaleCode);
}

const CHINESE_SIMPLIFIED = locales[LocaleCode.ChineseSimplified];
const CHINESE_TRADITIONAL = locales[LocaleCode.ChineseTraditional];
const ENGLISH = locales[LocaleCode.English];

const localeRegex = /^[a-z]{2}(-Han[ts]|)(-[a-z]{2}|)$/i;

export class Locales {
  country: keyof typeof PRIORITIZED_LANGUAGES_BY_COUNTRY;

  selectableLocales: {
    map: Map<LocaleCode, true>;
    list: LocaleCode[];
  };

  i18n: typeof i18next;

  public static isValidLocale(locale: string): locale is LocaleCode {
    return SUPPORTED_LOCALES.indexOf(locale as LocaleCode) !== -1;
  }

  public static getUrlWithNewLocale(
    {
      pathname,
      search = '',
      hash = '',
    }: {
      pathname: string;
      search?: string;
      hash?: string;
    },
    newLocale: LocaleCode,
  ): string {
    const [, currentLocale, ...restPath] = pathname.split('/');
    const pathRemainder =
      localeRegex.test(currentLocale) && Locales.isValidLocale(currentLocale)
        ? restPath.join('/')
        : [currentLocale, ...restPath].join('/');
    return `/${newLocale}/${pathRemainder}${hash}${search}`;
  }

  constructor({
    country,
    i18n,
    selectableLocales,
  }: {
    country: keyof typeof PRIORITIZED_LANGUAGES_BY_COUNTRY;
    i18n: typeof i18next;
    selectableLocales: LocaleCode[];
  }) {
    if (!country || !PRIORITIZED_LANGUAGES_BY_COUNTRY[country]) {
      console.warn(`Invalid country: ${country}. Falling back to default.`);
      this.country = 'US' as keyof typeof PRIORITIZED_LANGUAGES_BY_COUNTRY; // or your default country
    } else {
      this.country = country;
    }

    if (!i18n) {
      throw new Error('Invalid i18n instance provided');
    }
    if (!i18n.language) {
      i18n.language = LocaleCode.English;
    }
    this.i18n = i18n;

    if (!Array.isArray(selectableLocales) || selectableLocales.length === 0) {
      throw new Error('selectableLocales must be a non-empty array');
    }
    this.selectableLocales = {
      map: this._buildSelectableLocalesMap(selectableLocales),
      list: selectableLocales,
    };
  }

  private get _language(): LocaleCode {
    if (!this.i18n.language || !Locales.isValidLocale(this.i18n.language)) {
      console.warn(
        `Invalid language: ${this.i18n.language}. Falling back to default.`,
      );
      return LocaleCode.English; // or your default locale
    }
    return this.i18n.language as LocaleCode;
  }

  private _buildSelectableLocalesMap(
    selectableLocales: LocaleCode[],
  ): Map<LocaleCode, true> {
    const map = new Map();
    selectableLocales.forEach((code) => map.set(code, true));
    return map as Map<LocaleCode, true>;
  }

  private get _supportedPriotizedLocales(): Locale[] {
    const prioritizedLocales =
      PRIORITIZED_LANGUAGES_BY_COUNTRY[this.country] || [];

    return prioritizedLocales.filter(({ code }) =>
      this.selectableLocales.map.has(code),
    );
  }

  private get _commonlyUsedLocales(): Locale[] {
    const isChineseTraditional = this._language === CHINESE_TRADITIONAL.code;
    const isChineseSimplified = this._language === CHINESE_SIMPLIFIED.code;

    return uniq(
      [locales[this._language as LocaleCode]]
        .concat(isChineseTraditional ? [CHINESE_SIMPLIFIED] : [])
        .concat(isChineseSimplified ? [CHINESE_TRADITIONAL] : [])
        .concat(this._supportedPriotizedLocales)
        .concat(ENGLISH),
    );
  }

  private get _alphabeticallyOrderedLocales() {
    return ordered
      .filter((item: Locale) => this.selectableLocales.map.has(item.code))
      .sort((a, b) => {
        const languageIndex = ordered.findIndex(
          (locale) => locale.code === this._language,
        );
        if (languageIndex === -1) {
          console.warn(`Language ${this._language} not found in ordered array`);
          return 0; // Don't change order if language not found
        }
        const currentLanguageLocale = ordered[languageIndex];
        if (!currentLanguageLocale?.translations) {
          console.warn(`Translations not found for language ${this._language}`);
          return 0; // Don't change order if translations not found
        }
        const aTranslation = currentLanguageLocale.translations[a.code];
        const bTranslation = currentLanguageLocale.translations[b.code];
        if (!aTranslation || !bTranslation) {
          console.warn(
            `Translation not found for code ${!aTranslation ? a.code : b.code}`,
          );
          return 0; // Don't change order if translation not found
        }
        return aTranslation.localeCompare(bTranslation);
      });
  }
  /* eslint-enable @typescript-eslint/naming-convention */

  public get dividerIndex(): number {
    return this._commonlyUsedLocales.length + 1;
  }

  public get displayedLocales(): Locale[] {
    return uniq(
      [this._commonlyUsedLocales, this._alphabeticallyOrderedLocales].flat(),
    );
  }

  public toCodes(): LocaleCode[] {
    return this.displayedLocales.map(({ code }) => code);
  }

  public get currentLocale(): Locale {
    return (
      locales[this._language] || getDefaultLocale(this.selectableLocales.list)
    );
  }

  public get isAsianLocale(): boolean {
    return isAsianLocale(this._language);
  }
}
