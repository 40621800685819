import {
  AddLarge,
  Bookmark,
  BookmarkFilled,
  CheckmarkFilled,
  DirectionBearRight_02 as Direction,
  FaceAdd,
  LocationStar,
  LocationStarFilled,
  Phone,
  Share,
} from '@carbon/icons-react';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import { AuthContent, useGetUserQuery } from '@local/auth';
import { Panel, panelStateAtom } from '@local/components';
import { CONFIG } from '@local/configs';
import { AUTH_PANEL_STATES, DEFAULT_ICON_SIZE } from '@local/constants';
import { CreateNewListContent, SaveToListContent } from '@local/list';
import type { VenueFavorites, VenueInfo } from '@local/types';
import { useFetchUserMemoQuery } from '@local/user';
import { translate } from '@local/utils';

import { MemoContent } from '../Memo/MemoContent';
import { ShareContent } from '../Share/ShareContent';

import { ActionBtn, ReviewEmoji, Wrapper } from './ActionBtns.styles';
import { useActionBtns } from './useActionBtns';

const reviewEmojis = ['😠', '😔', '😐', '🙂', '😍'];

export function ActionBtns({
  venueData,
  favoritesData,
  isSaved,
}: {
  venueData: VenueInfo;
  favoritesData?: VenueFavorites;
  isSaved: boolean;
}): JSX.Element {
  const [, { language }] = useTranslation();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const [onLoginSubmit, setOnLoginSubmit] = React.useState<() => void>(() =>
    setPanelState(null),
  );
  const {
    phone,
    phone_natl,
    slug,
    search_image,
    name_translations,
    address,
    cuisines,
  } = venueData;
  const title = translate(name_translations, language);

  const {
    handleSave,
    handleSubscribe,
    handleReview,
    handleVisited,
    handleShare,
    handleDirections,
    handleCreateList,
    handleSaveToList,
    onSubmitMemo,
    onDeleteMemo,
  } = useActionBtns(venueData, setOnLoginSubmit);
  const { data: user } = useGetUserQuery();
  const { data: userMemo } = useFetchUserMemoQuery(slug, user);

  return (
    <>
      <Wrapper>
        <ActionBtn
          onClick={handleSave}
          data-active={isSaved}
          data-testid="Venue Save Btn"
        >
          {/* TODO: will be fixed with DPOR-435 */}
          {isSaved ? (
            <>
              <BookmarkFilled size={DEFAULT_ICON_SIZE} />
              <span>{t(`venue_feat.action_btns.saved`)}</span>
            </>
          ) : (
            <>
              <Bookmark size={DEFAULT_ICON_SIZE} />
              <span>{t(`venue_feat.action_btns.save`)}</span>
            </>
          )}
        </ActionBtn>

        <ActionBtn
          onClick={() =>
            handleSubscribe({ is_subscribed: !favoritesData?.is_subscribed })
          }
          data-active={favoritesData?.is_subscribed}
        >
          {favoritesData?.is_subscribed ? (
            <>
              <CheckmarkFilled size={DEFAULT_ICON_SIZE} />
              <span>{t(`venue_feat.action_btns.subscribed`)}</span>
            </>
          ) : (
            <>
              <AddLarge size={DEFAULT_ICON_SIZE} />
              <span>{t(`venue_feat.action_btns.subscribe`)}</span>
            </>
          )}
        </ActionBtn>

        {favoritesData?.has_visited && (
          <ActionBtn
            onClick={handleReview}
            data-active={
              favoritesData?.reviews && favoritesData?.reviews.length > 0
            }
          >
            {favoritesData?.reviews && favoritesData?.reviews.length > 0 ? (
              <ReviewEmoji>
                {reviewEmojis[favoritesData.reviews[0].rating - 1]}
              </ReviewEmoji>
            ) : (
              <FaceAdd size={DEFAULT_ICON_SIZE} />
            )}
            <span>{t(`venue_feat.action_btns.review`)}</span>
          </ActionBtn>
        )}

        {CONFIG.VITE_IS_STORYBOOK && (
          <ActionBtn
            onClick={handleVisited}
            data-active={favoritesData?.has_visited}
          >
            {favoritesData?.has_visited ? (
              <LocationStarFilled size={DEFAULT_ICON_SIZE} />
            ) : (
              <LocationStar size={DEFAULT_ICON_SIZE} />
            )}
            <span>{t(`venue_feat.action_btns.visited`)}</span>
          </ActionBtn>
        )}

        <ActionBtn
          onClick={() => void handleShare()}
          data-testid="Venue Share Btn"
        >
          <Share size={DEFAULT_ICON_SIZE} />
          <span>{t(`venue_feat.action_btns.share`)}</span>
        </ActionBtn>

        <ActionBtn onClick={handleDirections}>
          <Direction size={DEFAULT_ICON_SIZE} />
          <span>{t(`venue_feat.information.directions`)}</span>
        </ActionBtn>

        {phone && (
          <ActionBtn
            onClick={() => {
              window.open(`tel:${phone}`);
            }}
          >
            <Phone size={DEFAULT_ICON_SIZE} />
            <span>{phone_natl || t(`venue_feat.action_btns.call`)}</span>
          </ActionBtn>
        )}
      </Wrapper>

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {panelState === 'save_list' && (
          <SaveToListContent
            venueId={venueData._id}
            onCreateListClick={() => setPanelState('create_list')}
            onSubmit={handleSaveToList}
            onCancel={() => setPanelState(null)}
          />
        )}

        {panelState === 'share_venue' && (
          <ShareContent
            image={search_image ?? DEFAULT_VENUE_IMAGE}
            title={title}
            address={address}
            cuisines={cuisines}
          />
        )}

        {panelState === 'create_list' && (
          <CreateNewListContent
            venueId={venueData._id}
            imageUrl={venueData.banner_image}
            onSubmit={handleCreateList}
            onCancel={() => setPanelState(null)}
          />
        )}

        {AUTH_PANEL_STATES.includes(panelState) && (
          <AuthContent
            onSubmit={onLoginSubmit}
            subheaderContent={
              <Trans
                i18nKey="auth.login_feat_unlock"
                components={{ bold: <strong /> }}
              />
            }
          />
        )}

        {panelState === 'memo' && (
          <MemoContent
            userMemo={userMemo?.memo ?? ''}
            onSubmit={onSubmitMemo}
            onDelete={onDeleteMemo}
          />
        )}
      </Panel>
    </>
  );
}
