import { TranslationsArray } from '@tablecheck/i18n';
import { LocaleCode } from '@tablecheck/locales';
import { debounce } from 'lodash-es';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import Supercluster from 'supercluster';

// TODO: upload new placeholder image for map markers in static repo
import { DEFAULT_MAP_MARKER_IMAGE } from '@local/assets';
import type {
  CameraTransitionOptions,
  ClusterOrPoint,
  CustomClusterFeature,
  CustomPointFeature,
  CustomSupercluster,
  DebouncedFunc,
  MapMovementDetail,
  MapServiceOptions,
  MapVenue,
} from '@local/types';
import { translate } from '@local/utils';

import { mapStyle } from './mapStyle';
import './styles.css';

const DEFAULT_CONTAINER_ID = 'map';
// TODO: May need to make this more dynamic by increasing sensitivity for low zoom levels and decreasing for high zoom levels
const RADIUS_DISTANCE_PERCENTAGE = 0.3;
const DEBOUNCED_CHECK_MAP_MOVEMENT_TIME = 500;
const VENUE_MARKER_CLASS_NAME = 'custom-marker';
const CLUSTER_MARKER_CLASS_NAME = 'custom-cluster-marker';

// TODO: Remove this function once we have more shop data to use
export const venueGenerator = (
  { lng, lat, searchImage }: { lng: number; lat: number; searchImage: string },
  amount: number,
) => {
  const venues: MapVenue[] = [];
  for (let i = 0; i < amount; i += 1) {
    const signLng = Math.random() < 0.5 ? -1 : 1;
    const signLat = Math.random() < 0.5 ? -1 : 1;

    venues.push({
      id: Math.random().toString(32),
      slug: Math.random().toString(32),
      name_translations: [
        { translation: `Location ${i}`, locale: 'en' },
        { translation: `ロケ ${i}`, locale: 'ja' },
      ],
      location_name_translations: [{ translation: 'Tokyo', locale: 'en' }],
      geocode: {
        lng: lng + signLng * Math.random() * 0.3,
        lat: lat + signLat * Math.random() * 0.3,
      },
      cuisines: ['sushi', 'japanese'],
      search_image: searchImage,
    });
  }
  return venues;
};

export class MapService {
  private map!: maplibregl.Map;

  private geolocateControl!: maplibregl.GeolocateControl;

  private superCluster!: CustomSupercluster;

  private markerMap = new Map<string | number, maplibregl.Marker>();

  private languageInitialized = false;

  private previousStatus!: {
    searchedZoom: number;
    searchedCenter: maplibregl.LngLat;
  };

  private debouncedCheckMapMovement!: DebouncedFunc;

  private selectedMarker: HTMLElement | null = null;

  private options: MapServiceOptions;

  private isMapInteractive: boolean;

  private hideControlsOnSelect: boolean;

  private static JAPAN_COORDINATE_BOUNDARIES: {
    southWest: maplibregl.LngLatLike;
    northEast: maplibregl.LngLatLike;
  } = {
    southWest: [122.93457, 24.396308],
    northEast: [153.986672, 45.551483],
  };

  public eventTarget = new EventTarget();

  constructor(options: MapServiceOptions) {
    this.options = options;
    this.isMapInteractive = options.interactive ?? true;
    this.hideControlsOnSelect = options.hideControlsOnSelect ?? true;
    this.initializeMap();
  }

  private initializeMap(): void {
    this.map = new maplibregl.Map({
      container: this.options.containerId ?? DEFAULT_CONTAINER_ID,
      style: mapStyle as maplibregl.StyleSpecification,
      center: this.options.center,
      zoom: this.options.zoom,
      interactive: this.isMapInteractive,
      attributionControl: false,
    });

    this.map.on('load', () => {
      this.initializeSupercluster();
      this.updateClustersAndMarkers();
      this.changeLanguage(this.options.language ?? LocaleCode.English);

      if (this.isMapInteractive) {
        this.previousStatus = {
          searchedZoom: this.options.zoom,
          searchedCenter: new maplibregl.LngLat(
            (this.options.center as [number, number])[0],
            (this.options.center as [number, number])[1],
          ),
        };
        this.debouncedCheckMapMovement = debounce(() => {
          this.checkMapMovement();
        }, DEBOUNCED_CHECK_MAP_MOVEMENT_TIME);

        this.initializeGeolocationControl();
        this.addEventListeners();
        this.setBounds(
          this.options.bounds ?? MapService.JAPAN_COORDINATE_BOUNDARIES,
        );

        // Uncomment this to allow auto focus on users current location (user has to allow location permissions)
        // setTimeout(() => {
        //   if (!this.geolocateControl.trigger()) {
        //     console.error('Error triggering geolocate control');
        //   }
        // }, 1000);
      }
      this.eventTarget.dispatchEvent(new Event('mapLoaded'));
    });
  }

  private initializeSupercluster(): void {
    const geoJsonFeatures = this.createGeoJSONFeatures();
    this.superCluster = new Supercluster({
      radius: 40,
      maxZoom: 16,
      reduce: (accumulated, properties) => {
        if (!accumulated.cluster_name_translations) {
          accumulated.cluster_name_translations = properties.name_translations;
          accumulated.cluster_search_image = properties.search_image;
        }
      },
    }).load(geoJsonFeatures) as CustomSupercluster;
  }

  private initializeGeolocationControl(): void {
    this.geolocateControl = new maplibregl.GeolocateControl({
      fitBoundsOptions: { maxZoom: this.options.zoom },
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
    });

    this.geolocateControl.on('outofmaxbounds', (e) => {
      // eslint-disable-next-line no-console
      console.log('out of bounds', e);
    });
    this.map.addControl(this.geolocateControl, 'bottom-right');
  }

  private setBounds({
    southWest,
    northEast,
  }: {
    southWest: maplibregl.LngLatLike;
    northEast: maplibregl.LngLatLike;
  }): void {
    this.map.setMaxBounds([
      southWest,
      northEast,
    ] as maplibregl.LngLatBoundsLike);
  }

  private updateClustersAndMarkers(): void {
    const bounds = this.map.getBounds();
    const bbox: GeoJSON.BBox = [
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth(),
    ];

    const allVisibleFeatures = this.superCluster.getClusters(
      bbox,
      this.map.getZoom(),
    ) as ClusterOrPoint[];
    const currentVenueMarkerIds = new Set<string>();
    const currentClusterMarkerIds = new Set<number>();

    allVisibleFeatures.forEach((feature) => {
      if (MapService.isCluster(feature)) {
        const clusterId = feature.properties.cluster_id;
        currentClusterMarkerIds.add(clusterId);

        // create/unhide cluster markers
        if (!this.markerMap.has(clusterId)) {
          const clusterMarker = this.createClusterMarker(feature);
          clusterMarker
            .setLngLat([
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[1],
            ])
            .addTo(this.map);
          this.markerMap.set(clusterId, clusterMarker);
        } else {
          const clusterMarker = this.markerMap.get(clusterId);
          if (clusterMarker) {
            clusterMarker.getElement().style.display = 'block';
          }
        }
        // create/unhide venue unclustered markers
      } else {
        const { properties } = feature;
        const venueId = properties.id;
        currentVenueMarkerIds.add(venueId);

        if (!this.markerMap.has(venueId)) {
          const venueMarker = this.createVenueMarker(properties);
          venueMarker
            .setLngLat([properties.geocode.lng, properties.geocode.lat])
            .addTo(this.map);
          this.markerMap.set(venueId, venueMarker);
        } else {
          const venueMarker = this.markerMap.get(venueId);
          if (venueMarker) {
            venueMarker.getElement().style.display = 'block';
          }
        }
      }
    });

    // Hide venue markers that are now clustered and cluster markers that are now unclustered
    this.markerMap.forEach((marker, id) => {
      if (
        (typeof id === 'string' && !currentVenueMarkerIds.has(id)) ||
        (typeof id === 'number' && !currentClusterMarkerIds.has(id))
      ) {
        marker.getElement().style.display = 'none';
      }
    });
  }

  private createVenueMarker(venue: CustomPointFeature['properties']) {
    const markerDiv = document.createElement('div');
    markerDiv.className = VENUE_MARKER_CLASS_NAME;
    markerDiv.setAttribute('data-testid', 'Map Venue Marker');
    markerDiv.setAttribute('data-shop-slug', venue.slug);
    markerDiv.setAttribute('data-name', venue.translated_name);
    markerDiv.style.backgroundImage = `url(${venue.search_image})`;

    if (this.isMapInteractive) {
      markerDiv.addEventListener('click', (event) => {
        event.stopPropagation();
        this.handleMarkerClick(venue, markerDiv);
      });
    }

    return new maplibregl.Marker({ element: markerDiv });
  }

  private createClusterMarker(cluster: CustomClusterFeature) {
    const clusterDiv = document.createElement('div');
    const pointCount = cluster.properties.point_count - 1;
    clusterDiv.className = CLUSTER_MARKER_CLASS_NAME;

    clusterDiv.setAttribute('data-testid', 'Map Cluster Marker');
    clusterDiv.setAttribute(
      'data-name',
      `${translate(cluster.properties.cluster_name_translations, this.options.language!)} +${pointCount}`,
    );
    clusterDiv.setAttribute(
      'data-translations',
      JSON.stringify(cluster.properties.cluster_name_translations),
    );
    clusterDiv.setAttribute('data-point-count', pointCount.toString());
    clusterDiv.style.backgroundImage = `url(${cluster.properties.cluster_search_image})`;

    clusterDiv.addEventListener(
      'click',
      this.handleClusterClick.bind(this, cluster),
    );

    return new maplibregl.Marker({ element: clusterDiv });
  }

  private removeAllMarkers(): void {
    this.markerMap.forEach((marker) => marker.remove());
    this.markerMap.clear();
  }

  private addEventListeners(): void {
    this.map.on('moveend', () => {
      this.updateClustersAndMarkers();
    });
    this.map.on('dragend', () => {
      this.debouncedCheckMapMovement();
    });
    this.map.on('click', () => {
      this.deselectMarker();
      if (this.hideControlsOnSelect) {
        this.showControlGroup(true);
      }
      const event = new CustomEvent('venueSelected', { detail: null });
      this.eventTarget.dispatchEvent(event);
    });
  }

  private handleClusterClick(cluster: CustomClusterFeature): void {
    const clusterId = cluster.properties.cluster_id;
    const clusterZoom = this.superCluster.getClusterExpansionZoom(clusterId);

    const coordinates: maplibregl.LngLatLike = [
      cluster.geometry.coordinates[0],
      cluster.geometry.coordinates[1],
    ];

    this.map.easeTo({
      center: coordinates,
      zoom: clusterZoom,
    });
  }

  private handleMarkerClick(
    venue: CustomPointFeature['properties'],
    markerElement: HTMLElement,
  ) {
    if (this.selectedMarker === markerElement) {
      return;
    }

    // Stop highlighting the previous marker and highlight the new one
    this.deselectMarker();
    markerElement.classList.add('selected');
    this.selectedMarker = markerElement;
    if (this.hideControlsOnSelect) {
      this.showControlGroup(false);
    }

    const event = new CustomEvent('venueSelected', { detail: venue.id });
    this.eventTarget.dispatchEvent(event);
    this.map.easeTo({
      center: [venue.geocode.lng, venue.geocode.lat],
      zoom: this.map.getZoom(),
    });
  }

  private deselectMarker() {
    if (this.selectedMarker) {
      this.selectedMarker.classList.remove('selected');
      this.selectedMarker = null;
    }
  }

  private showControlGroup(show: boolean): void {
    const controlGroupContainer = this.map
      .getContainer()
      .querySelector('.maplibregl-ctrl-group');
    if (controlGroupContainer) {
      (controlGroupContainer as HTMLElement).style.display = show
        ? 'block'
        : 'none';
    }
  }

  public get instance(): maplibregl.Map {
    return this.map;
  }

  public updateVenues(newVenues: MapVenue[]): void {
    this.options.venues = newVenues;
    const newGeoJSONFeatures = this.createGeoJSONFeatures();
    this.superCluster.load(newGeoJSONFeatures);
    this.removeAllMarkers();
    this.updateClustersAndMarkers();
    this.previousStatus.searchedCenter = this.map.getCenter();
    this.previousStatus.searchedZoom = this.map.getZoom();
    this.checkMapMovement();
  }

  public changeLanguage(languageCode: string): void {
    if (this.languageInitialized && this.options.language === languageCode) {
      return;
    }

    const style = this.map.getStyle();

    if (style.layers) {
      style.layers.forEach((layer) => {
        if (layer.type === 'symbol' && layer.layout) {
          this.map.setLayoutProperty(layer.id, 'text-field', [
            'get',
            `name:${languageCode}`,
          ]);
        }
      });
    }

    this.options.language = languageCode;
    this.languageInitialized = true;

    this.updateMarkerNameTranslations(languageCode);
  }

  private updateMarkerNameTranslations(languageCode: string): void {
    this.superCluster.points.forEach((feature) => {
      if (!MapService.isCluster(feature)) {
        const { properties } = feature;
        properties.translated_name = translate(
          properties.name_translations,
          languageCode,
        );

        const marker = this.markerMap.get(String(properties.id));
        if (marker) {
          marker
            .getElement()
            .setAttribute('data-name', properties.translated_name);
        }
      }
    });

    this.markerMap.forEach((marker, id) => {
      if (typeof id === 'number') {
        const markerEle = marker.getElement();
        const translations: TranslationsArray = JSON.parse(
          markerEle.getAttribute('data-translations') || '[]',
        );
        const pointCount = markerEle.getAttribute('data-point-count');
        const clusterName = `${translate(translations, languageCode)} +${pointCount}`;
        markerEle.setAttribute('data-name', clusterName);
      }
    });
  }

  private checkMapMovement() {
    const currentCenter = this.map.getCenter();
    const currentZoom = this.map.getZoom();

    const distanceThresholdInMetres =
      this.getRadiusFromCenter() * RADIUS_DISTANCE_PERCENTAGE;
    const isMoveSignificant =
      currentCenter.distanceTo(this.previousStatus.searchedCenter) >
      distanceThresholdInMetres;
    const isZoomOutSignificant = currentZoom < this.previousStatus.searchedZoom;
    const shouldPerformSearch = isMoveSignificant || isZoomOutSignificant;

    const event = new CustomEvent<MapMovementDetail>('mapMovement', {
      detail: {
        shouldPerformSearch,
        center: currentCenter,
        radiusInKm: this.getRadiusFromCenter() / 1000,
      },
    });
    this.eventTarget.dispatchEvent(event);
  }

  public getRadiusFromCenter(): number {
    const centerPoint = this.map.getCenter();
    const northEastCorner = this.map.getBounds().getNorthEast();
    const distanceToCorner = centerPoint.distanceTo(northEastCorner);
    return distanceToCorner;
  }

  public cleanup(): void {
    if (this.map) {
      if (this.isMapInteractive && this.debouncedCheckMapMovement) {
        this.debouncedCheckMapMovement.cancel();
      }
      this.map.remove();
    }
  }

  public fitBoundsToMarkers(transitionOption: CameraTransitionOptions): void {
    if (this.options.venues.length === 0) {
      return;
    }
    const allPoints: [number, number][] = this.options.venues.map((venue) => [
      venue.geocode.lng,
      venue.geocode.lat,
    ]);

    const bbox = MapService.calculateBbox(allPoints);
    const cameraBounds = this.map.cameraForBounds(
      bbox as maplibregl.LngLatBoundsLike,
      {
        padding: 50,
        maxZoom: this.map.getZoom(),
      },
    );
    if (cameraBounds) {
      this.map[transitionOption](cameraBounds);
    }
  }

  public triggerGeolocate(): void {
    if (this.geolocateControl) {
      this.geolocateControl.trigger();
    }
  }

  private static isCluster(
    feature: ClusterOrPoint,
  ): feature is CustomClusterFeature {
    return !!(feature as CustomClusterFeature).properties.cluster;
  }

  private static calculateBbox(points: [number, number][]): GeoJSON.BBox {
    const bbox = points.reduce(
      (acc, point) => {
        const [lng, lat] = point;

        acc[0] = Math.min(acc[0], lng); // minLng
        acc[1] = Math.min(acc[1], lat); // minLat
        acc[2] = Math.max(acc[2], lng); // maxLng
        acc[3] = Math.max(acc[3], lat); // maxLat

        return acc;
      },
      [Infinity, Infinity, -Infinity, -Infinity],
    );
    return bbox as GeoJSON.BBox;
  }

  private createGeoJSONFeatures(): CustomPointFeature[] {
    return this.options.venues.map((venue) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [venue.geocode.lng, venue.geocode.lat],
      },
      properties: {
        id: venue.id,
        slug: venue.slug,
        name_translations: venue.name_translations,
        translated_name: translate(
          venue.name_translations,
          this.options.language!,
        ),
        geocode: venue.geocode,
        search_image:
          venue.search_image && venue.search_image?.length > 0
            ? venue.search_image
            : DEFAULT_MAP_MARKER_IMAGE,
      },
    }));
  }
}
