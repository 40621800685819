import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { cookieService } from '@local/services';
import type { AuthToken } from '@local/types';

import { logout } from '../authApi';

export const useLogoutQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const authToken: AuthToken | null = cookieService.getAuthToken();
      return logout(authToken);
    },
    onSuccess: async () => {
      cookieService.removeAuthToken();
      queryClient.setQueriesData({ queryKey: [USER_QUERY_KEY] }, null);
      await queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });
    },
    onError: (error) => console.error(error),
  });
};
