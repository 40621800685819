import { LocaleCode } from '@tablecheck/locales';
import { format, formatISO, isBefore } from 'date-fns';
import { TFunction } from 'i18next';

import type { Fields, Restriction, RestrictionType } from '@local/types';
import {
  DateFormat,
  getFormattedDays,
  getFormattedTime,
  getDateFormat,
  getTimeFormat,
} from '@local/utils';

export class Restrictions {
  private isArabic: boolean;

  private fields: Fields;

  constructor(fields: Fields) {
    this.fields = fields;
    this.isArabic = fields.language === LocaleCode.Arabic;
  }

  public build(
    cb: (
      restrictions: Record<RestrictionType, Restriction | null>,
    ) => (Restriction | null)[],
  ): Restriction[] {
    const composed = cb({
      times: this.times(this.fields),
      datesDays: this.datesDays(this.fields),
    });

    return composed.filter((c) => c) as Restriction[];
  }

  private getValidDateRanges({ validDateRanges = [], language }: Fields) {
    const dateRanges = validDateRanges.reduce<string[]>(
      (
        acc: string[],
        { startDate, endDate }: { startDate: string; endDate: string },
      ) => {
        if ((!startDate && !endDate) || isBefore(new Date(endDate), new Date()))
          return acc;

        const formattedStartDate = format(
          new Date(startDate),
          getDateFormat(language, DateFormat.MonthDay),
        );
        const formattedEndDate = format(
          new Date(endDate),
          getDateFormat(language, DateFormat.MonthDay),
        );

        if (!startDate) {
          return acc.concat(`~ ${formattedEndDate}`);
        }
        if (!endDate) {
          return acc.concat(`${formattedStartDate} ~`);
        }
        return acc.concat(
          formattedStartDate !== formattedEndDate
            ? `${formattedStartDate} - ${formattedEndDate}`
            : `${formattedStartDate}`,
        );
      },
      [],
    );

    return this.isArabic ? dateRanges.join(' ,') : dateRanges.join(', ');
  }

  private getDaysTextFn({ days, isHoliday, language }: Fields) {
    return (t: TFunction) => {
      let daysText = getFormattedDays(days, language);
      if (isHoliday) {
        daysText = daysText
          ? `${daysText}, ${t('general.holidays')}`
          : t('general.holidays');
      }
      return daysText.replace(/, /g, this.isArabic ? '، ' : ', ');
    };
  }

  private times({
    validStartTime,
    validEndTime,
    journeyDate,
    language,
  }: Fields): Restriction | null {
    if (validStartTime !== null || validEndTime !== null) {
      return {
        id: 'times',
        getLabel: (t: (key: string) => string) =>
          t('attributes.menu.restrictions.times'),
        getText: () =>
          `${getFormattedTime({
            date: journeyDate || formatISO(new Date()),
            time: validStartTime ?? 0,
            timeFormat: getTimeFormat(language),
          })}~${getFormattedTime({
            date: journeyDate || formatISO(new Date()),
            time: validEndTime ?? 0,
            timeFormat: getTimeFormat(language),
          })}`,
        'data-testid': 'Restriction Tag Times',
      };
    }
    return null;
  }

  private datesDays({
    days,
    validDateRanges = [],
    isHoliday,
  }: Fields): Restriction | null {
    if (!isHoliday && days.length < 1 && validDateRanges.length < 1) {
      return null;
    }

    const validDateRange = this.getValidDateRanges(this.fields);

    if (validDateRange.length > 0)
      return {
        id: 'dates',
        getLabel: (t: TFunction<'translation', undefined>) =>
          t('attributes.menu.restrictions.dates'),
        getText: () => `${validDateRange}`,
        'data-testid': 'Restriction Tag Dates Days',
      };

    if (days.length > 0 || isHoliday)
      return {
        id: 'days',
        getLabel: (t: TFunction<'translation', undefined>) =>
          t('attributes.menu.restrictions.days'),
        getText: (t: TFunction<'translation', undefined>) =>
          `${this.getDaysTextFn(this.fields)(t)}`,
        'data-testid': 'Restriction Tag Days',
      };

    return null;
  }
}
