import { JAPAN_UNIVERSE, SINGAPORE_UNIVERSE } from '@local/constants';
import { cookieService } from '@local/services';
import { UniverseSlug } from '@local/types';

export const useUniverse = () => {
  const cookieUniverseSlug = cookieService.getUniverse();
  const universe =
    cookieUniverseSlug === UniverseSlug.SG
      ? SINGAPORE_UNIVERSE
      : JAPAN_UNIVERSE;

  return {
    isJapanUniverse: cookieUniverseSlug === UniverseSlug.JP,
    isSingaporeUniverse: cookieUniverseSlug === UniverseSlug.SG,
    universeId: universe.id,
    universeName: universe.name,
    universeSlug: universe.slug,
    universeCurrency: universe.currency,
    universeBudgetLimit: universe.budgetLimit,
  };
};
