import { Launch } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Anchor, Button } from '@tablecheck/tablekit-react-css';
import { useTranslation } from 'react-i18next';

import { Footer } from '@local/components';
import { CONFIG } from '@local/configs';
import { DEFAULT_ICON_SIZE } from '@local/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l4);
`;

const LinksContainer = styled.section`
  display: grid;
  gap: var(--spacing-l4);
  padding-bottom: var(--spacing-l4);

  & > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    text-decoration: none;
    color: var(--color-text);
  }
`;

interface Props {
  onClose: () => void;
}

export function TermsConditionsContent({ onClose }: Props) {
  const [t, { language }] = useTranslation();
  const baseUrl = `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/policy`;
  const tosOptions = [
    {
      title: t('account.terms_conditions.diner_terms'),
      icon: <Launch size={DEFAULT_ICON_SIZE} />,
      href: `${baseUrl}/terms`,
    },
    {
      title: t('account.terms_conditions.privacy_policy'),
      icon: <Launch size={DEFAULT_ICON_SIZE} />,
      href: `${baseUrl}/privacy`,
    },
    {
      title: t('account.terms_conditions.payment_policy'),
      icon: <Launch size={DEFAULT_ICON_SIZE} />,
      href: `${baseUrl}/payment`,
    },
  ];
  return (
    <Wrapper>
      <LinksContainer>
        {tosOptions.map((option) => (
          <Anchor
            key={option.title}
            href={option.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {option.title}
            {option.icon}
          </Anchor>
        ))}
      </LinksContainer>
      <Footer>
        <Button data-variant="tertiary" onClick={onClose}>
          {t('general.close')}
        </Button>
      </Footer>
    </Wrapper>
  );
}
