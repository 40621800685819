import { styled } from '@linaria/react';

export const ActionBtn = styled.button`
  display: grid;
  cursor: pointer;
  font-size: 12px;
  line-height: 22px;
  width: max-content;
  place-content: center;
  justify-items: center;
  padding: var(--spacing-l2);
  min-width: -webkit-fill-available;
  border: 1px solid var(--lighter-grey-border);
  border-radius: var(--border-radius-small);

  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
  &[data-active='true'] {
    background-color: var(--surface-active);
    & svg {
      fill: black;
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(90px, 1fr);
  overflow-x: scroll;
  gap: var(--spacing-l2);
  scrollbar-width: none;
`;

export const ReviewEmoji = styled.span`
  font-size: 20px;
`;
