import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import type { CreateListData } from '@local/types';

import { createList } from '../listApi';

export const useCreateListQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateListData) => createList(data),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [USER_QUERY_KEY, 'lists'] })
        .catch(console.error);
    },
    onError: (error) => {
      console.error('Error updating list:', error);
    },
  });
};
