import { BookmarkAdd, BookmarkFilled } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { IconButton } from '@tablecheck/tablekit-react-css';

import { ICON_SIZE_24 } from '@local/constants';

import { useVenueCardContext } from '../VenueCardContext';

const StyledHeader = styled.div`
  font: var(--h5);
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
  max-width: 90%;

  & > button {
    position: absolute;
    border: none;
    padding: 0;
    right: 0;
  }
`;

export interface HeaderProps {
  icon?: 'bookmarkAdd' | 'bookmarkFilled';
  onIconClick?: () => void;
  buttonTestId?: string;
}

const iconMap = {
  bookmarkFilled: (
    <BookmarkFilled size={ICON_SIZE_24} data-bookmarked fill="var(--primary)" />
  ),
  bookmarkAdd: (
    <BookmarkAdd
      size={ICON_SIZE_24}
      data-bookmarked={false}
      fill="var(--primary)"
    />
  ),
};

export function Header({ icon, onIconClick, buttonTestId }: HeaderProps) {
  const { venue } = useVenueCardContext();
  const showIconButton = icon && onIconClick;

  return (
    <StyledHeader>
      {venue.title}
      {showIconButton && (
        <IconButton
          data-variant="ghost"
          data-testid={buttonTestId || 'Common Venue Card Icon Btn'}
          onClick={(e) => {
            e.stopPropagation();
            onIconClick();
          }}
        >
          {iconMap[icon]}
        </IconButton>
      )}
    </StyledHeader>
  );
}
