import { styled } from '@linaria/react';
import { useAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import { useGetUserQuery } from '@local/auth';
import {
  nextPanelActionAtom,
  panelStateAtom,
  Spinner,
  VenueCard,
} from '@local/components';
import { selectedVenueAtom } from '@local/map-system';
import type { Image } from '@local/types';
import { buildUrl, translate, useInView } from '@local/utils';

import { useExplore } from '../../hooks/useExplore';
import { useSearchFilters } from '../../hooks/useSearchFilters';

import { NotFound } from './NotFound';

const Wrapper = styled.div`
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l6);
  height: calc(100svh - var(--mobile-footer-height) - 175px);
  padding-bottom: 56px;
`;

const HiddenDiv = styled.div`
  visibility: hidden;
`;

interface Props {
  onResetSearch: () => void;
}

export function SearchResultsContent({ onResetSearch }: Props) {
  const navigate = useNavigate();
  const { data: user } = useGetUserQuery();
  const [, { language }] = useTranslation();
  const { ref, isInView } = useInView();
  const { shopSearchQuery, allSavedShopsSet, searchCount, availabilityToShow } =
    useExplore();
  const [searchParams] = useSearchParams();
  const { searchFilters } = useSearchFilters();
  const [, setPanelState] = useAtom(panelStateAtom);
  const [, setNextPanelAction] = useAtom(nextPanelActionAtom);
  const [, setSelectedVenue] = useAtom(selectedVenueAtom);
  const {
    data,
    isLoading,
    isPending,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = shopSearchQuery;

  React.useEffect(() => {
    if (isInView) {
      void fetchNextPage();
    }
  }, [fetchNextPage, isInView]);

  if (isLoading || isPending) return <Spinner isFullPage />;
  if (!isLoading && (!data || searchCount === 0))
    return <NotFound onResetSearch={onResetSearch} />;
  return (
    <Wrapper>
      {data?.shops.map((shop) => {
        // shop.images contains the search image as well so we need to exclude it
        const nonSearchImages = new Set<Image['thumbnail']>();
        for (const image of shop.images) {
          if (nonSearchImages.size >= 3) break;
          if (image.thumbnail !== shop.search_image) {
            nonSearchImages.add(image.thumbnail);
          }
        }
        return (
          <VenueCard
            key={shop._id}
            venue={{
              slug: shop.slug,
              title: translate(shop.name_translations, language),
              cuisines: shop.cuisines,
              imageUrls: [shop.search_image, ...nonSearchImages],
              description: shop.content_body_translations,
              hasTableCheckPay: shop.is_smartpay,
              lunchAvg: shop.budget_lunch_avg,
              dinnerAvg: shop.budget_dinner_avg,
              availability: shop.availability,
            }}
            onClick={() =>
              navigate(buildUrl(`/${language}/${shop.slug}`, searchParams))
            }
          >
            <VenueCard.Header
              icon={
                allSavedShopsSet.has(shop._id)
                  ? 'bookmarkFilled'
                  : 'bookmarkAdd'
              }
              onIconClick={() => {
                setSelectedVenue({
                  venue: {
                    id: shop._id,
                    slug: shop.slug,
                    search_image: shop.search_image || DEFAULT_VENUE_IMAGE,
                    name_translations: shop.name_translations,
                    location_name_translations: shop.location_name_translations,
                    cuisines: shop.cuisines,
                    geocode: { lng: shop.geocode.lon, lat: shop.geocode.lat },
                    budget_lunch_avg: shop.budget_lunch_avg,
                    budget_dinner_avg: shop.budget_dinner_avg,
                    availability: shop.availability,
                  },
                  shouldShowMapVenueCard: false,
                });
                if (user) {
                  setPanelState('save_list');
                } else {
                  setPanelState('login');
                  setNextPanelAction(() => () => setPanelState('save_list'));
                }
              }}
            />
            <VenueCard.Info />
            <VenueCard.TopPicture />
            <VenueCard.TagLine />
            <VenueCard.Description />
            <VenueCard.Budget />
            {availabilityToShow === 'time' && (
              <VenueCard.TimeSlots
                query={{
                  date: searchFilters.date,
                  pax: searchFilters.num_people,
                  time: searchFilters.time,
                }}
              />
            )}
            {availabilityToShow === 'date' && searchFilters.time && (
              <VenueCard.DateTable
                query={{
                  pax: searchFilters.num_people,
                  time: searchFilters.time,
                }}
              />
            )}
          </VenueCard>
        );
      })}

      {isFetchingNextPage && (
        <div>
          <Spinner />
        </div>
      )}

      {hasNextPage && <HiddenDiv ref={ref} />}
    </Wrapper>
  );
}
