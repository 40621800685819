import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Price, Spacer } from '@local/components';
import type { VenueFavorites, VenueInfo } from '@local/types';
import { CurrencyFormat, formatDistance, translate } from '@local/utils';

import { ActionBtns } from '../ActionBtns/ActionBtns';
import { Memo } from '../Memo/Memo';

import {
  Details,
  Location,
  OverviewSection,
  Smartpay,
  SmartpayLogo,
  StyledPrice,
  VenueDetails,
} from './Overview.styles';

export function Overview({
  venueData,
  favoritesData,
  isSaved,
}: {
  venueData: VenueInfo;
  favoritesData?: VenueFavorites;
  isSaved: boolean;
}): JSX.Element {
  const [, { language }] = useTranslation();
  const {
    name_translations,
    stations,
    cuisines,
    address,
    budget_lunch_avg,
    budget_dinner_avg,
    currency,
    allows_smartpay: allowsSmartpay,
    slug,
  } = venueData;

  const title = translate(name_translations, language);
  return (
    <OverviewSection>
      <VenueDetails>
        <h2 data-testid="Venue Page Title">{title}</h2>
        <Details>
          <Location>
            {t('general.city_region', {
              city: address.city,
              region: address.region,
            })}
          </Location>
          {cuisines.length > 0 && (
            <>
              <Spacer showBullet />
              <div>{t(`cuisine.${cuisines[0]}`)}</div>
            </>
          )}
        </Details>
        {stations?.length > 0 && stations[0].name_translations && (
          <Details>
            {t('venue_feat.information.distance_from_station', {
              // TODO: Change to duration when API is ready
              length: formatDistance(stations[0].distance.toString()),
              station: translate(stations[0].name_translations, language),
            })}
          </Details>
        )}

        <Details>
          {!!budget_lunch_avg && (
            <StyledPrice>
              <Price
                amount={budget_lunch_avg}
                currency={currency as CurrencyFormat}
                withAmountLimit
              />{' '}
              {t('general.average_lunch')}
            </StyledPrice>
          )}
          {!!budget_dinner_avg && !!budget_lunch_avg && <Spacer showBullet />}
          {!!budget_dinner_avg && (
            <StyledPrice>
              <Price
                amount={budget_dinner_avg}
                currency={currency as CurrencyFormat}
                withAmountLimit
              />{' '}
              {t('general.average_dinner')}
            </StyledPrice>
          )}
        </Details>

        {allowsSmartpay && (
          <Smartpay>
            <SmartpayLogo
              src="https://cdn0.tablecheck.com/common/images/logos/tc/v1.0.0/logo-symbol.svg"
              alt="Contactless pay logo"
            />
            <span>{t('venue_feat.accepts_smartpay')}</span>
          </Smartpay>
        )}
      </VenueDetails>

      <ActionBtns
        venueData={venueData}
        favoritesData={favoritesData}
        isSaved={isSaved}
      />

      {/* // TODO: will be fixed with DPOR-435 */}
      {isSaved && <Memo venueSlug={slug} />}
    </OverviewSection>
  );
}
