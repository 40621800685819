import { apiService } from '@local/services';
import type { VenueFavorites, VenueFavoritesResponse } from '@local/types';

export const fetchUserFavoritesVenue = async (
  venueSlug: string | undefined,
): Promise<VenueFavoritesResponse> =>
  apiService.get(`v2/user/favorites/${venueSlug}`).json();

export const fetchUserFavorites = async (): Promise<VenueFavoritesResponse> =>
  apiService.get('v2/user/favorites/').json();

export const updateUserFavoritesVenue = async ({
  venueSlug,
  data,
}: {
  venueSlug: string | undefined;
  data: Partial<VenueFavorites>;
}): Promise<VenueFavoritesResponse> =>
  apiService.patch(`v2/user/favorites/${venueSlug}`, { json: data }).json();

export const updateUserFavorites = async ({
  data,
}: {
  data: {
    venueSlug: VenueFavorites['shop_slug'];
    isSubscribed: VenueFavorites['is_subscribed'];
  };
}): Promise<VenueFavoritesResponse> =>
  apiService
    .patch(`v2/user/favorites/${data.venueSlug}`, {
      json: { is_subscribed: data.isSubscribed },
    })
    .json();

export const fetchUserMemoVenue = async (
  venueSlug: string,
): Promise<{
  memo: string;
}> => apiService.get(`v2/hub/shops/${venueSlug}/shop_memos`).json();

export const updateUserMemoVenue = async ({
  venueSlug,
  data,
}: {
  venueSlug: string;
  data: string;
}): Promise<{
  status: number;
}> =>
  apiService
    .patch(`v2/hub/shops/${venueSlug}/shop_memos`, { json: { memo: data } })
    .json();

export const deleteUserMemoVenue = async (venueSlug: string): Promise<void> =>
  apiService.delete(`v2/hub/shops/${venueSlug}/shop_memos`).json();
