// TODO: Move all constants to this file the same way we do for Assets

import { addDays, format } from 'date-fns';

import {
  type ListQueryParamsType,
  type PanelStates,
  type Universe,
  UniverseCurrency,
  UniverseId,
  UniverseName,
  UniverseSlug,
} from '@local/types';

export const TABLET_BREAKPOINT = '767px';

export const ICON_SIZE_16 = 16;
export const DEFAULT_ICON_SIZE = 20;
export const ICON_SIZE_24 = 24;

export const STALETIME_10_SECONDS = 10_000;
export const STALETIME_1_MINUTE = 60_000;
export const STALETIME_5_MINUTES = 300_000;
export const STALETIME_1_HOUR = 3_600_000;

export const FIRST_THREE_ITEMS = 3;
export const MAX_NUM_PEOPLE_COUNT = 11;

export const INPUT_MAX_CHAR_LENGTH = 200;

// Query keys
export const USER_QUERY_KEY = 'user_info';
export const SHOP_SEARCH_QUERY_KEY = 'shopSearch';

// List feat constants
export const LIST_NAME_MAX_CHAR_LENGTH = 50;
export const DEFAULT_LIST_FILTERS: ListQueryParamsType = {
  num_people: '2',
  availability_format: 'date',
  date_min: format(new Date(), 'yyyy-MM-dd'),
  date_max: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
};

// Venue feat constants
export const DPOR_UTM_SOURCE_BETA1 = 'diner_portal_v2_beta1';

// Search constants
export const BUDGET_ZERO = '0';
export const BUDGET_INFINITY = '999999999999';
export const DEFAULT_GEO_DISTANCE = '5km';

export const AUTH_PANEL_STATES: PanelStates[] = [
  'login',
  'sign_up',
  'reset_password',
  'reset_password_confirmation',
];

// Availability constants
export const DINNER_LIMIT_TIME = '21:00';
export const DEFAULT_TIME = '19:00';
export const DEFAULT_PAX = '2';
export const BASE_SUPPORT_SITE_URL = 'https://support-diners.tablecheck.com/';
export const BASE_SUPPORT_EMAIL = 'zakaria.benkhalifa@tablecheck.com';
export const CAREERS_URL = 'https://careers.tablecheck.com/';
export const INSTAGRAM_URL = 'https://www.instagram.com/tablecheck_global/';
export const APP_STORE_URL =
  'https://itunes.apple.com/app/tablecheck/id1183241149';
export const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=jp.kkvesper.tablecheck';
export const X_BASE_URL = 'https://x.com/';
export const FACEBOOK_BASE_URL = 'https://www.facebook.com/';

export const APP_DEPLOY_TARGETS = [
  'local',
  'staging',
  'staging-qa',
  'staging-qa-2',
  'staging-qa-3',
  'staging-ci',
  'staging-snap',
  'testing',
  'pilot',
  'pilot-2',
  'production',
] as const;

export const APP_ENVIRONMENTS = [
  'development',
  'staging',
  'pilot',
  'testing',
  'production',
] as const;

// Universe constants
export const VALID_UNIVERSES = [UniverseName.Japan, UniverseName.Singapore];
export const DEFAULT_LATITUDE_TOKYO = 35.6895;
export const DEFAULT_LONGITUDE_TOKYO = 139.6917;
export const DEFAULT_LONGITUDE_SINGAPORE = 103.8463;
export const DEFAULT_LATITUDE_SINGAPORE = 1.2892;

export const JAPAN_UNIVERSE: Universe = {
  id: UniverseId.JP,
  name: UniverseName.Japan,
  slug: UniverseSlug.JP,
  currency: UniverseCurrency.JPY,
  boundaries: {
    southWest: [122.93457, 24.396308] as maplibregl.LngLatLike,
    northEast: [153.986672, 45.551483] as maplibregl.LngLatLike,
  },
  defaultLocation: {
    lon: DEFAULT_LONGITUDE_TOKYO,
    lat: DEFAULT_LATITUDE_TOKYO,
    country: UniverseSlug.JP,
  },
  budgetLimit: 20000,
  zoomLevel: 12,
};
export const SINGAPORE_UNIVERSE: Universe = {
  id: UniverseId.SG,
  name: UniverseName.Singapore,
  slug: UniverseSlug.SG,
  currency: UniverseCurrency.SGD,
  boundaries: {
    southWest: [103.502835, 1.097545] as maplibregl.LngLatLike,
    northEast: [104.125127, 1.517348] as maplibregl.LngLatLike,
  },
  defaultLocation: {
    lon: DEFAULT_LONGITUDE_SINGAPORE,
    lat: DEFAULT_LATITUDE_SINGAPORE,
    country: UniverseSlug.SG,
  },
  budgetLimit: 200,
  zoomLevel: 12,
};
