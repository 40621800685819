import * as React from 'react';

import { useFetchCuisineListQuery } from './useFetchCuisineListQuery';
import { useSearchFilters } from './useSearchFilters';

export function useCuisineSearch({
  searchFilterView,
}: {
  searchFilterView: string;
}) {
  const { searchFilters } = useSearchFilters();
  const { data: { cuisines: cuisineList = [] } = {}, status } =
    useFetchCuisineListQuery();
  const isCuisineListLoaded = cuisineList.length > 0 && status === 'success';
  const [cuisineSearch, setCuisineSearch] = React.useState('');
  const [selectedCuisines, setSelectedCuisines] = React.useState(
    searchFilters.cuisines ?? [],
  );

  React.useEffect(() => {
    setSelectedCuisines(searchFilters.cuisines ?? []);
  }, [searchFilters.cuisines, searchFilterView]);

  // Filters cuisines and reorders them with selected ones at the top of the list
  const filteredCuisines = React.useMemo(
    () =>
      cuisineList
        .filter((cuisine) =>
          cuisine.field.toLowerCase().includes(cuisineSearch.toLowerCase()),
        )
        .sort((a, b) => {
          const isASelected = selectedCuisines.includes(a.field);
          const isBSelected = selectedCuisines.includes(b.field);
          if (isASelected && !isBSelected) return -1;
          if (!isASelected && isBSelected) return 1;
          return 0;
        }),
    [cuisineList, cuisineSearch, searchFilters.cuisines, searchFilterView],
  );

  React.useEffect(() => {
    setCuisineSearch('');
  }, [searchFilterView]);

  return {
    cuisineSearch,
    setCuisineSearch,
    selectedCuisines,
    setSelectedCuisines,
    filteredCuisines,
    isCuisineListLoaded,
    cuisineList,
  };
}
