import { styled } from '@linaria/react';
import { Badge } from '@tablecheck/tablekit-react-css';
import { format } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { CREDIT_CARD_ICONS, DEFAULT_PAYMENT_ITEM_IMAGE } from '@local/assets';
import { Price } from '@local/components';
import { Payment } from '@local/types';
import { CurrencyFormat, DateFormat, getDateFormat } from '@local/utils';

import { usePayments } from '../../../hooks/usePayments';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: var(--spacing-l8) auto;
  gap: var(--spacing-l3);
  padding: var(--spacing-l4) 0;
  cursor: pointer;
  border-bottom: 1px solid var(--border-transparent);
`;

const Image = styled.img`
  border-radius: var(--border-radius-small);
  width: var(--spacing-l8);
  height: var(--spacing-l8);
  object-fit: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  & > div:first-child {
    font: var(--body-1);
  }

  & > div {
    font: var(--small);
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      font: var(--label);
    }
  }
`;

const PaymentStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  & > span {
    font-weight: normal;
  }
`;

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  & > img {
    width: 30px;
  }
`;

interface Props {
  shopName: string;
  paymentType: Payment['payment_type'];
  paymentStatus: Payment['payment_status'];
  currency: string;
  cardBrand: string;
  cardDigits: string;
  amount: string;
  date: string;
  searchImage: string | null;
  onClick: () => void;
}

export function PaymentItem({
  shopName,
  paymentType,
  paymentStatus,
  currency,
  cardBrand,
  cardDigits,
  amount,
  date,
  searchImage,
  onClick,
}: Props) {
  const [, { language }] = useTranslation();
  const { mapPaymentStatusToStr, mapPaymentTypeToStr, matchStatusToVariant } =
    usePayments();
  const status = mapPaymentStatusToStr(paymentStatus);
  const type = mapPaymentTypeToStr(paymentType, paymentStatus);
  const variant = matchStatusToVariant(status);

  return (
    <Wrapper data-testid="Payment Item" onClick={onClick}>
      <Image src={searchImage ?? DEFAULT_PAYMENT_ITEM_IMAGE} alt={shopName} />
      <Content>
        <div>
          {shopName}
          <Price amount={amount} currency={currency as CurrencyFormat} />
        </div>
        <div>
          <PaymentStatus>
            <Badge data-variant={variant} data-size="x-small">
              {t(`account.payment_status.${status}`)}
            </Badge>
            ({t(`account.payment_type.${type}`)})
          </PaymentStatus>
          <CardInfo>
            <img src={`${CREDIT_CARD_ICONS}${cardBrand}.svg`} alt={cardBrand} />
            {cardDigits}
          </CardInfo>
        </div>
        <div>
          {format(
            new Date(date),
            getDateFormat(language, DateFormat.MonthDayTime),
            {
              locale: dateFnsLocales[language as keyof typeof dateFnsLocales],
            },
          )}
        </div>
      </Content>
    </Wrapper>
  );
}
