import { styled } from '@linaria/react';
import { t } from 'i18next';

import { useSocialAuthProviders } from '@local/auth';
import { Spinner } from '@local/components';
import type { SocialAuth, SocialAuthInfo } from '@local/types';

import { useFetchSocialAuthsQuery } from '../../hooks/useFetchSocialAuthsQuery';

import { SocialAccountItem } from './SocialAccountItem/SocialAccountItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    font: var(--label);
    margin-bottom: var(--spacing-l2);
  }
`;

interface Props {
  onDisconnectClick: ({ id, provider }: SocialAuthInfo) => void;
}

export function SocialAccounts({ onDisconnectClick }: Props) {
  const { data, isLoading } = useFetchSocialAuthsQuery();
  const { iconMap, providers } = useSocialAuthProviders();

  const connectedProvidersDict = (data?.social_auths ?? []).reduce(
    (acc, socialAuth) => {
      acc[socialAuth.provider] = socialAuth.id;
      return acc;
    },
    {} as Record<SocialAuth['provider'], string>,
  );

  const content = () => {
    if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        {providers.map((provider) => {
          const userSocialAuthId = connectedProvidersDict[provider.id];

          return (
            <SocialAccountItem
              key={provider.id}
              provider={provider.id}
              icon={iconMap[provider.id]}
              isConnected={!!userSocialAuthId}
              onConnectClick={provider.onClick}
              onDisconnectClick={() =>
                onDisconnectClick({
                  id: userSocialAuthId,
                  provider: provider.id,
                })
              }
            />
          );
        })}
      </>
    );
  };

  return (
    <Wrapper>
      <p>{t('account.social_accounts')}</p>
      {content()}
    </Wrapper>
  );
}
