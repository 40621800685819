import { useQuery } from '@tanstack/react-query';

import {
  DEFAULT_LATITUDE_TOKYO,
  DEFAULT_LONGITUDE_TOKYO,
  STALETIME_5_MINUTES,
} from '@local/constants';
import { UniverseSlug, type GeolocationResponseType } from '@local/types';

import { fetchGeolocation } from '../searchApi';

const TOKYO_GEOLOCATION: GeolocationResponseType = {
  latitude: DEFAULT_LATITUDE_TOKYO,
  longitude: DEFAULT_LONGITUDE_TOKYO,
  country: UniverseSlug.JP,
};

const getBrowserGeolocation = (): Promise<
  Pick<GeolocationResponseType, 'latitude' | 'longitude'>
> =>
  new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported'));
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        resolve({ latitude, longitude });
      },
      (error) => reject(error),
    );
  });

export const useFetchGeolocation = () =>
  useQuery({
    queryKey: ['geolocation'],
    queryFn: async () => {
      try {
        const browserGeolocation = await getBrowserGeolocation();
        const ipGeolocation = await fetchGeolocation();
        return { ...ipGeolocation, ...browserGeolocation };
      } catch (e) {
        console.error(e);
        const ipGeolocation = await fetchGeolocation();
        return ipGeolocation;
      }
    },
    staleTime: STALETIME_5_MINUTES,
    select: (data) => {
      if (
        data?.country === UniverseSlug.JP ||
        data?.country === UniverseSlug.SG
      ) {
        return data;
      }
      return TOKYO_GEOLOCATION;
    },
  });
