import * as React from 'react';

import { CONFIG } from '@local/configs';
import { cookieService } from '@local/services';
import { OAuthProviders } from '@local/types';

import { getCsrfToken } from '../authApi';

const POPUP_WINDOW_FEATURES =
  'toolbar=no, menubar=1, resizable=1, width=600, height=700, top=100, left=100';

export const useAuthInPopupWindow = () => {
  const [authPopup, setAuthPopup] = React.useState<Window | null>(null);

  const onReceiveMessage = React.useCallback(
    (event: MessageEvent) => {
      if (
        !event.data ||
        event.origin.indexOf(CONFIG.VITE_BASE_AUTH_API_URL) === -1 ||
        event.data?.msg !== 'tc_account'
      ) {
        return;
      }

      const { status } = event.data;
      if (status === 'success') {
        authPopup?.close();
      }
    },
    [authPopup],
  );

  const handleSocialLogin = async (provider: OAuthProviders) => {
    try {
      const authEndpoint = `${CONFIG.VITE_BASE_AUTH_API_URL}/auth/${provider}`;

      if (window && (!authPopup || authPopup.closed)) {
        window.removeEventListener('message', onReceiveMessage);
        const popup = window.open('', 'socialLogin', POPUP_WINDOW_FEATURES);

        if (popup) {
          popup.focus();
          window.addEventListener('message', onReceiveMessage);

          setAuthPopup(popup);

          await getCsrfToken();
          const csrfToken = cookieService.getCsrfToken();
          const form = popup.document.createElement('form');
          form.method = 'POST';
          form.action = authEndpoint;

          const csrfInput = popup.document.createElement('input');
          csrfInput.type = 'hidden';
          csrfInput.name = 'authenticity_token';
          csrfInput.value = csrfToken || '';
          form.appendChild(csrfInput);

          popup.document.body.appendChild(form);
          form.submit();
        }
      } else {
        authPopup?.focus();
      }
    } catch (error) {
      console.error('Social login failed:', error);
    }
  };
  return { handleSocialLogin };
};
