import {
  DirectionBearRight_02 as Direction,
  Link,
  Location,
  Phone,
  Time,
  Train,
} from '@carbon/icons-react';
import { t } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import { CONFIG } from '@local/configs';
import {
  DEFAULT_LATITUDE_TOKYO,
  DEFAULT_LONGITUDE_TOKYO,
  ICON_SIZE_16,
  ICON_SIZE_24,
} from '@local/constants';
import { useMapService } from '@local/map-system';
import type { VenueInfo } from '@local/types';
import { formatDistance, translate } from '@local/utils';

import {
  BusinessHours,
  DirectionButton,
  Info,
  InformationWrapper,
  MapWrapper,
  OpeningHours,
  SectionTitle,
  VenueDetails,
  VenueLink,
} from './Information.styles';

const DEFAULT_VENUE_ZOOM = 12;

export function Information({
  venueInfo,
}: {
  venueInfo: VenueInfo;
}): JSX.Element {
  const [, { language }] = useTranslation();

  const {
    _id: id,
    geocode,
    address,
    name_translations,
    location_name_translations,
    url,
    phone,
    opening_hours,
    closest_station,
    stations,
    search_image,
    slug,
    google_place_ref,
    cuisines,
  } = venueInfo;

  const longitude = geocode?.lon ?? DEFAULT_LONGITUDE_TOKYO;
  const latitude = geocode?.lat ?? DEFAULT_LATITUDE_TOKYO;

  const venues = React.useMemo(
    () => [
      {
        id,
        slug,
        name_translations,
        location_name_translations,
        geocode: {
          lng: geocode.lon,
          lat: geocode.lat,
        },
        search_image: search_image ?? DEFAULT_VENUE_IMAGE,
        cuisines,
      },
    ],
    [],
  );

  useMapService({
    venues,
    language,
    center: [longitude, latitude],
    zoom: DEFAULT_VENUE_ZOOM,
    interactive: false,
  });

  return (
    <InformationWrapper>
      <MapWrapper>
        <div id="map" style={{ width: '100%', height: '100%' }} />
        <DirectionButton
          data-variant="ghost"
          type="button"
          onClick={() => {
            window.open(
              google_place_ref?.length > 0
                ? `https://www.google.com/maps/place/?q=place_id:${google_place_ref}`
                : `https://www.google.com/maps/dir/?api=1&destination=${geocode.lat},${geocode.lon}`,
              '_blank',
            );
          }}
        >
          <Direction size={ICON_SIZE_16} />
          {t('venue_feat.information.directions')}
        </DirectionButton>
      </MapWrapper>

      <Info>
        <Location size={ICON_SIZE_24} />
        <div>
          <SectionTitle>{t('venue_feat.information.address')}</SectionTitle>
          <VenueDetails>
            {`${address.street}, ${address.city}, ${address.region}`}
          </VenueDetails>
        </div>
      </Info>

      {url && (
        <Info>
          <Link href="/#" size={ICON_SIZE_24} />
          <div>
            <SectionTitle>{t('venue_feat.information.website')}</SectionTitle>
            <VenueLink href={url}>{url}</VenueLink>
          </div>
        </Info>
      )}

      {CONFIG.VITE_IS_STORYBOOK ? (
        <Info>
          <Train size={ICON_SIZE_24} />
          <div>
            <SectionTitle>
              {t('venue_feat.information.closest_station')}
            </SectionTitle>
            <VenueDetails>
              <span>
                {t('venue_feat.information.station_walking_time', {
                  station: translate(
                    closest_station.name_translations,
                    language,
                  ),
                  duration: closest_station.walk_time,
                  distance: formatDistance(closest_station.distance),
                })}
              </span>
            </VenueDetails>
          </div>
        </Info>
      ) : (
        <>
          {stations?.length > 0 && (
            <Info>
              <Train size={ICON_SIZE_24} />
              <div>
                <SectionTitle>
                  {t('venue_feat.information.closest_station')}
                </SectionTitle>
                <VenueDetails>
                  <span>
                    {t('venue_feat.information.station_walking_time', {
                      station: translate(
                        stations[0].name_translations,
                        language,
                      ),
                      duration: '5',
                      distance: formatDistance(
                        Math.floor(stations[0].distance).toString(),
                      ),
                    })}
                  </span>
                </VenueDetails>
              </div>
            </Info>
          )}
        </>
      )}

      {phone && (
        <Info>
          <Phone size={ICON_SIZE_24} />
          <div>
            <SectionTitle>{t('venue_feat.information.phone')}</SectionTitle>
            <VenueLink href={`tel:${phone}`}>{phone}</VenueLink>
          </div>
        </Info>
      )}

      {opening_hours && (
        <Info>
          <Time size={ICON_SIZE_24} />
          <div>
            <SectionTitle>{t('general.open')}</SectionTitle>
            <OpeningHours>
              {Object.entries(opening_hours).map(([day, hours]) => (
                <BusinessHours key={day}>
                  <span>{day}</span>
                  <span>{hours || t('general.closed')}</span>
                </BusinessHours>
              ))}
            </OpeningHours>
          </div>
        </Info>
      )}
    </InformationWrapper>
  );
}
