import z from 'zod';

import { APP_DEPLOY_TARGETS, APP_ENVIRONMENTS } from '@local/constants';

const zodBoolean = z
  .enum(['true', 'false'])
  .transform((value) => value === 'true')
  .default('false');

export const baseSchema = z.object({
  BASE_URL: z.string().default('/'),
  VITE_ASSETS_PATH: z.string().default('portal/assets'),
  VITE_BASE_API_URL: z.string().default(`https://staging-qa.tablecheck.com/`),
  VITE_BASE_INTERNAL_API_URL: z
    .string()
    .default(`http://tc-v4-api-staging-qa.monolith-staging-qa/`),
  VITE_BASE_AUTH_API_URL: z
    .string()
    .default(`https://api-booking-staging-qa.tablecheck.com/`),
  VITE_BASE_SEARCH_AUTOCOMPLETE_API_URL: z
    .string()
    .default(`https://staging-qa.tablecheck.com/`),
  VITE_BASE_TABLECHECK_URL: z.string().default(`http://localhost:8080/`),
  VITE_BASE_CDN_IMAGES_URL: z
    .string()
    .default(`https://cdn0.tablecheck.com/common/images/`),
  VITE_BASE_PROD_TABLECHECK_URL: z
    .string()
    .default(`https://www.tablecheck.com/`),
  VITE_BASE_PROTOMAP_API_URL: z
    .string()
    .default(`https://api.protomaps.com/tiles/v4.json?key=902937823637a5aa`),
  VITE_BASE_MOCK_API_URL: z.string().default(`http://localhost:8080/`),
  VITE_BASE_E2E_URL: z.string().default(`http://localhost:8080/`),
  VITE_POSTHOG_HOST: z.string().default('https://us.i.posthog.com'),
  VITE_STORYBLOK_API_URL: z.string().default(`https://api.storyblok.com/`),
  VITE_STORYBLOK_TOKEN: z.string().default(`JS6yaM7dfI1n7lQ6cIAnrwtt`),
  VITE_SENTRY_DSN: z.string().default(''),
  VITE_APP_RELEASE: z.string().default('2.0.0'),
  VITE_POSTHOG_PROJECT_API_KEY: z.string().default(''),
  VITE_IS_MOCKING_ENABLED: zodBoolean,
  VITE_SOCIAL_LOGIN: zodBoolean,
  VITE_IS_STORYBOOK: zodBoolean,
  VITE_IS_HEADLESS_E2E: zodBoolean,
  VITE_APP_ENVIRONMENT: z.enum(APP_ENVIRONMENTS).default('development'),
  VITE_APP_DEPLOY_TARGET: z.enum(APP_DEPLOY_TARGETS).default('local'),
});
