import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';

import { META_APPLE_TOUCH_ICON, META_FAVICON, META_IMAGE } from '@local/assets';

export function Head(): JSX.Element {
  const title = t('meta.title');
  const description = t('meta.description');

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" type="image/x-icon" sizes="16x16" href={META_FAVICON} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={META_APPLE_TOUCH_ICON}
      />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={META_IMAGE} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="TableCheck" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={META_IMAGE} />
    </Helmet>
  );
}
