import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { AuthContent, useGetUserQuery } from '@local/auth';
import {
  Panel,
  panelStateAtom,
  Spinner,
  toastContentAtom,
  toastIsOpenAtom,
  useToast,
} from '@local/components';
import { AUTH_PANEL_STATES, TABLET_BREAKPOINT } from '@local/constants';
import {
  DeleteListContent,
  ListOptionsContent,
  SaveToListContent,
  UpdateListContent,
  useDeleteListQuery,
  useListView,
  useSaveOrUnsaveShopToListQuery,
  useUpdateListDetailQuery,
} from '@local/list';
import { selectedVenueAtom } from '@local/map-system';
import { ListOption, UpdateListData } from '@local/types';
import { useTypedRouteParams } from '@local/utils';

import { ListViewDesktop } from './ListViewDesktop';
import { ListViewMobile } from './ListViewMobile';

interface ListRouteParams {
  listId: string;
}

export function ListViewRoot() {
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const setIsToastOpen = useSetAtom(toastIsOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const selectedVenue = useAtomValue(selectedVenueAtom);
  const { venue } = selectedVenue;

  const { listId } = useTypedRouteParams<ListRouteParams>();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const [t, { language }] = useTranslation();
  const { saveVenueToastContent, updateListToastContent, errorToastContent } =
    useToast();
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const {
    listDetail: { data: listData, error: isListError },
  } = useListView();
  const { mutate: updateList } = useUpdateListDetailQuery(listId);
  const { mutate: deleteList } = useDeleteListQuery(listId);
  const { mutate: saveOrUnsaveShopToList } = useSaveOrUnsaveShopToListQuery();
  const navigate = useNavigate();

  const handleUpdate = (updatedData: UpdateListData) => {
    updateList(
      {
        name: updatedData.name,
        description: updatedData.description,
      },
      {
        onSuccess: () => {
          setToastContent(updateListToastContent('update_list'));
          setIsToastOpen(true);
          setPanelState(null);
        },
      },
    );
  };

  const handleDelete = () => {
    deleteList(undefined, {
      onSuccess: () => {
        setPanelState(null);
        setToastContent(updateListToastContent('delete_list'));
        setIsToastOpen(true);
        navigate(`/${language}/lists`);
      },
    });
  };

  const listOptions: ListOption[] = React.useMemo(() => {
    if (listData?.list.is_favorite) {
      return [
        {
          label: t('list_feat.edit_desc'),
          onClick: () => setPanelState('update_list_desc'),
          testId: 'List Option Edit Desc Btn',
        },
      ];
    }

    return [
      {
        label: t('list_feat.edit_list'),
        onClick: () => setPanelState('update_list'),
        testId: 'List Option Edit Btn',
      },
      {
        label: t('list_feat.delete_list'),
        onClick: () => setPanelState('delete_list'),
        variant: 'danger',
        testId: 'List Option Delete Btn',
      },
    ];
  }, [listData?.list.is_favorite, t, setPanelState]);

  React.useEffect(() => {
    if (isListError) navigate(`/${language}/lists`);
    if (isUserLoading) return;
    setPanelState(user ? null : 'login');
  }, [user, isUserLoading, isListError, navigate, language, setPanelState]);

  if (isUserLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      {isDesktop ? <ListViewDesktop /> : <ListViewMobile />}

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {panelState === 'list_options' && (
          <ListOptionsContent options={listOptions} />
        )}
        {listData &&
          (panelState === 'update_list' ||
            panelState === 'update_list_desc') && (
            <UpdateListContent
              name={listData.list.name}
              description={listData.list.description}
              onSubmit={handleUpdate}
              onCancel={() => setPanelState(null)}
            />
          )}
        {listData && panelState === 'delete_list' && (
          <DeleteListContent
            shopName={listData.list.name}
            onDelete={handleDelete}
            onCancel={() => setPanelState(null)}
          />
        )}
        {panelState === 'save_list' && venue && (
          <SaveToListContent
            venueId={venue.id}
            onCreateListClick={() => setPanelState('create_list')}
            onSubmit={(listsToUpdate: { save: string[]; unsave: string[] }) => {
              saveOrUnsaveShopToList(
                {
                  venueId: venue.id,
                  listIdsToUpdate: listsToUpdate,
                },
                {
                  onSuccess: (response) => {
                    setPanelState(null);
                    setToastContent(
                      saveVenueToastContent(response.lists, setPanelState),
                    );
                    setIsToastOpen(true);
                  },
                  onError: () => {
                    setPanelState(null);
                    setToastContent(
                      errorToastContent(t('toast.unsuccessful_list_save')),
                    );
                    setIsToastOpen(true);
                  },
                },
              );
            }}
            onCancel={() => setPanelState(null)}
          />
        )}
        {AUTH_PANEL_STATES.includes(panelState) && (
          <AuthContent
            subheaderContent={
              <Trans
                i18nKey="auth.login_feat_unlock"
                components={{ bold: <strong /> }}
              />
            }
          />
        )}
      </Panel>
    </>
  );
}
