import { Close } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { IconButton } from '@tablecheck/tablekit-react-css';
import * as React from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import { ICON_SIZE_24 } from '@local/constants';
import 'swiper/css';
import 'swiper/css/pagination';

export const CarouselWrapper = styled.div`
  position: relative;
`;

const ImgCarousel = styled.div`
  cursor: pointer;
  height: 280px;
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  > div {
    width: 100%;
  }

  &[data-show-gallery='true'] {
    width: 100%;
    position: fixed;
    z-index: var(--z-index-4);
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
  }

  & span {
    transition: 200ms left !important;
    background: white !important;
    opacity: 1 !important;
  }

  & img {
    padding: 0 !important;
  }
`;

const VenueImage = styled.img`
  height: 100%;
  flex-shrink: 0;
  object-fit: cover;
  scroll-snap-align: center;
  width: -webkit-fill-available;

  &[data-show-gallery='true'] {
    object-fit: contain;
    border: 1px solid black;
    padding: 20px;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  color: white;
  margin: var(--spacing-l4);
  border-radius: 50%;
`;

const DefaultImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const INITIAL_IMAGES_LOADED = 3;
const NEXT_IMAGES_PRELOADED = 2;
const PRELOADING_DELAY = 1000;

export function ImageCarousel({
  images,
}: {
  images?: { large: string; id: string }[];
}): JSX.Element {
  const [isShowingGallery, setShowGallery] = React.useState(false);
  const [loadedImages, setLoadedImages] = React.useState(INITIAL_IMAGES_LOADED);
  const imagesLength = images ? images.length : 0;
  const hasGalleryImgs = imagesLength > 0;

  React.useEffect(() => {
    const interval = setInterval(() => {
      setLoadedImages((loadedImgs) =>
        Math.min(loadedImgs + NEXT_IMAGES_PRELOADED, imagesLength),
      );

      if (loadedImages >= imagesLength) {
        clearInterval(interval);
      }
    }, PRELOADING_DELAY);
    return () => clearInterval(interval);
  }, [imagesLength, loadedImages]);

  return (
    <ImgCarousel
      data-testid="Venue Img Carousel"
      data-show-gallery={isShowingGallery}
      onClick={() =>
        hasGalleryImgs ? setShowGallery(!isShowingGallery) : null
      }
    >
      {isShowingGallery && (
        <CloseButton type="button" data-variant="bare">
          <Close size={ICON_SIZE_24} />
        </CloseButton>
      )}
      {hasGalleryImgs ? (
        <Swiper
          data-testid="Venue Img Gallery"
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
        >
          {images?.slice(0, loadedImages).map((img) => (
            <SwiperSlide key={img.id}>
              <VenueImage
                src={img.large}
                alt={img.id}
                data-show-gallery={isShowingGallery}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <DefaultImage
          alt="defaultVenueImage"
          data-testid="Venue Default Image"
          src={DEFAULT_VENUE_IMAGE}
        />
      )}
    </ImgCarousel>
  );
}
