import { styled } from '@linaria/react';
import { useTranslation } from 'react-i18next';

import {
  DOWNLOAD_APP_STORE_LOGO,
  DOWNLOAD_GOOGLE_PLAY_LOGO,
  QR_CODE,
  TABLECHECK_LOGO,
} from '@local/assets';
import { CONFIG } from '@local/configs';
import {
  APP_STORE_URL,
  DEFAULT_ICON_SIZE,
  GOOGLE_PLAY_URL,
} from '@local/constants';

import { useFooter } from './useFooter';

const Wrapper = styled.footer`
  background-color: var(--surface-raised);
  width: 100vw;
  text-align: -webkit-center;
  align-self: center;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-l8);
  max-width: var(--desktop-content-width);
  text-align-last: left;
  padding: var(--spacing-l5) 0;
`;

const Section = styled.section`
  display: grid;
  gap: var(--spacing-l2);
  height: fit-content;

  & h3 {
    font-size: 16px;
  }
  & button {
    cursor: pointer;
    font-size: 14px;
  }
`;

const LegalFooter = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  max-width: var(--desktop-content-width);
  padding: var(--spacing-l4) 0;
  text-align-last: left;
  color: var(--text-subtle);
  font-size: 14px;
  gap: var(--spacing-l2);
  align-items: center;
`;

const TableCheckLogo = styled.img`
  cursor: pointer;
  height: 12px;
  filter: brightness(0) saturate(100%) invert(52%) sepia(6%) saturate(0%)
    hue-rotate(201deg) brightness(80%) contrast(91%);
`;

const SNSLogos = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-l2);
  justify-self: right;
  & svg:hover {
    color: black;
  }
`;

const Hr = styled.hr`
  margin: 0 var(--spacing-l5);
  border: 1px solid var(--border-transparent);
  max-width: var(--desktop-content-width);
`;

const AppStoresLogo = styled.img`
  cursor: pointer;
  height: 40px;
`;

const AppStoreWrapper = styled.div`
  display: flex;
  gap: var(--spacing-l2);
`;

const QrCodeSection = styled(AppStoreWrapper)`
  font-size: 16px;
  text-align: left;

  & span {
    align-content: center;
  }

  // TODO: This should be removed when the QR code is added
  & img {
    background: grey;
    height: 56px;
    width: 56px;
    margin: var(--spacing-l1);
    border-radius: var(--border-radius-small);
  }
`;

const Spacer = styled.div`
  height: -webkit-fill-available;
`;

export function DesktopFooter() {
  const [t, { language }] = useTranslation();
  const { exploreOptions, companyOptions, legalOptions, snsOptions } =
    useFooter();

  return (
    <>
      <Spacer />
      <Wrapper data-testid="Desktop Footer">
        <Content>
          <Section>
            <h3>{t('layout.tablecheck_app')}</h3>
            <QrCodeSection>
              <img alt="QRCode" src={QR_CODE} />
              <span>{t('layout.scan_to_download')}</span>
            </QrCodeSection>

            <AppStoreWrapper>
              <AppStoresLogo
                src={DOWNLOAD_APP_STORE_LOGO}
                alt="AppStore"
                onClick={() =>
                  window.open(`${APP_STORE_URL}`, '_blank', 'noreferrer')
                }
              />
              <AppStoresLogo
                src={DOWNLOAD_GOOGLE_PLAY_LOGO}
                alt="AppStore"
                onClick={() =>
                  window.open(`${GOOGLE_PLAY_URL}`, '_blank', 'noreferrer')
                }
              />
            </AppStoreWrapper>
          </Section>
          <Section>
            <h3>{t('layout.explore')}</h3>
            {exploreOptions.map((option) => (
              <button key={option.title} type="button" onClick={option.action}>
                {option.title}
              </button>
            ))}
          </Section>
          <Section>
            <h3>{t('layout.company')}</h3>
            {companyOptions.map((option) => (
              <button key={option.title} type="button" onClick={option.action}>
                {option.title}
              </button>
            ))}
          </Section>
          <Section>
            <h3>{t('layout.legal')}</h3>
            {legalOptions.map((option) => (
              <button key={option.title} type="button" onClick={option.action}>
                {option.title}
              </button>
            ))}
          </Section>
        </Content>
        <Hr />
        <LegalFooter>
          <TableCheckLogo
            src={TABLECHECK_LOGO}
            alt="TableCheck"
            onClick={() => {
              window.open(
                `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/join`,
                '_blank',
                'noreferrer',
              );
            }}
          />
          <span>{t('layout.all_rights_reserved')}</span>
          <SNSLogos>
            {snsOptions.map((option) => (
              <option.icon
                key={option.link}
                size={DEFAULT_ICON_SIZE}
                onClick={() => window.open(option.link, '_blank', 'noreferrer')}
              />
            ))}
          </SNSLogos>
        </LegalFooter>
      </Wrapper>
    </>
  );
}
