import { Group, Time } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { useReactSelectConfig } from '@tablecheck/tablekit-react-select';
import { format, set } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';
import { t } from 'i18next';
import {
  Form,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ReactSelect from 'react-select';

import { Calendar, Spinner } from '@local/components';
import { CONFIG } from '@local/configs';
import {
  DEFAULT_ICON_SIZE,
  DEFAULT_PAX,
  MAX_NUM_PEOPLE_COUNT,
} from '@local/constants';
import type { AvailabilityFormInputsType } from '@local/types';
import {
  buildBookingFormUrl,
  DateFormat,
  generate30MinIntervals,
  generatePaxValues,
  getDefaultDate,
  getDefaultTime,
  getDateFormat,
} from '@local/utils';

import { useFetchVenueAvailQuery } from '../../hooks/useFetchVenueAvailabilityQuery';

const AvailabilityWrapper = styled.div`
  display: grid;
  grid-column: 2 / 3;
  padding: var(--spacing-l3);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-large);
  height: min-content;
  gap: var(--spacing-l4);
`;
const FormWrapper = styled(Form)`
  display: grid;
  gap: var(--spacing-l4);
`;
const StyledSelect = styled(ReactSelect)`
  & * {
    color: black !important;
  }
`;
const TimeslotWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-l2);
`;
const NoAvailMsg = styled.span`
  background-color: var(--warning-surface);
  color: var(--warning-text);
  padding: var(--spacing-l2) var(--spacing-l3);
`;

interface Props {
  venueSlug: string;
}

const mergeDateTime = (dateString: string, timeString: string): string => {
  const date = new Date(dateString);
  const [hours, minutes] = timeString.split(':').map(Number);
  const mergedDateTime = set(date, {
    hours,
    minutes,
    seconds: 0,
    milliseconds: 0,
  });
  return mergedDateTime.toISOString();
};

const GroupIcon = <Group size={DEFAULT_ICON_SIZE} />;
const TimeIcon = <Time size={DEFAULT_ICON_SIZE} />;

export function Availability({ venueSlug }: Props): JSX.Element {
  const now = new Date();
  const [, { language }] = useTranslation();
  const [searchParams] = useSearchParams();
  const {
    date: dateParam,
    time: timeParam,
    num_people: numPeopleParam,
  } = Object.fromEntries(searchParams);

  const { control, handleSubmit, setValue, ...rest } =
    useForm<AvailabilityFormInputsType>({
      defaultValues: {
        date: dateParam ?? getDefaultDate(now),
        time: timeParam ?? getDefaultTime(now),
        num_people: numPeopleParam ?? DEFAULT_PAX,
      },
    });

  const date = useWatch({ name: 'date', control });
  const time = useWatch({ name: 'time', control });
  const numPeople = useWatch({ name: 'num_people', control });
  const selectedDate = new Date(date!);

  const { styles: paxStyles, components: paxComponents } = useReactSelectConfig(
    {
      dataTestId: 'Venue Pax Select',
      isMulti: false,
      icon: GroupIcon,
    },
  );
  const { styles: timeStyles, components: timeComponents } =
    useReactSelectConfig({
      dataTestId: 'Venue Time Select',
      isMulti: false,
      icon: TimeIcon,
    });

  const { data: availData, isLoading } = useFetchVenueAvailQuery(
    venueSlug,
    numPeople!,
    mergeDateTime(date!, time!),
  );

  const onSubmit: SubmitHandler<AvailabilityFormInputsType> = () => {
    const searchParamsObject = Object.fromEntries(searchParams);
    const submitURL = buildBookingFormUrl(
      `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${venueSlug}/reserve`,
      searchParamsObject,
    );
    return window.open(submitURL, '_blank');
  };

  const availabilityContent = () => {
    if (isLoading) return <Spinner />;
    if (!availData || availData.length === 0)
      return (
        <NoAvailMsg data-testid="Venue Unavailable Msg">
          <Trans
            i18nKey="availability.no_avail_message"
            values={{
              date: format(
                selectedDate,
                getDateFormat(language, DateFormat.MonthDay),
                {
                  locale:
                    dateFnsLocales[language as keyof typeof dateFnsLocales],
                },
              ),
            }}
            components={{ underline: <u /> }}
          />
        </NoAvailMsg>
      );
    if (availData)
      return (
        <TimeslotWrapper>
          {availData.map((timeslot) => {
            const slot = Object.keys(timeslot)[0];
            const isAvailable = Object.values(timeslot)[0];
            const formattedDate = format(selectedDate, 'yyyy-MM-dd');
            const bookingFormURL = buildBookingFormUrl(
              `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${venueSlug}/reserve`,
              {
                start_date: formattedDate,
                start_time: slot,
                ...(numPeople && { num_people: numPeople }),
              },
            );
            return (
              <Button
                key={slot}
                data-testid="Venue Timeslot Btn"
                data-variant="primary"
                disabled={!isAvailable}
                data-disabled={!isAvailable}
                onClick={() => window.open(bookingFormURL, '_blank')}
              >
                {slot}
              </Button>
            );
          })}
        </TimeslotWrapper>
      );
  };

  return (
    <AvailabilityWrapper>
      <FormProvider {...{ control, handleSubmit, setValue, ...rest }}>
        <FormWrapper onSubmit={() => void handleSubmit(onSubmit)()}>
          <Calendar
            date={selectedDate}
            minDate={now}
            onDateSelected={(e) => setValue('date', e.date.toString())}
            shouldShowArrows
          />
          <StyledSelect
            id={`pax-${numPeople}`}
            styles={paxStyles}
            components={paxComponents}
            isSearchable={false}
            options={generatePaxValues().map((value) => ({
              label: t(`general.pax_number`, {
                pax_number: value,
                count: Number(value) ?? MAX_NUM_PEOPLE_COUNT,
              }),
              value,
            }))}
            placeholder={t('general.pax_number', {
              pax_number: numPeople,
              count: Number(numPeople) ?? MAX_NUM_PEOPLE_COUNT,
            })}
            onChange={(option) => {
              setValue('num_people', (option as { value: string }).value);
            }}
            value={t('general.pax_number', {
              pax_number: numPeople,
              count: Number(numPeople) ?? MAX_NUM_PEOPLE_COUNT,
            })}
          />
          <StyledSelect
            id={`time-${time}`}
            data-time={time}
            styles={timeStyles}
            components={timeComponents}
            isSearchable={false}
            options={generate30MinIntervals(date).map((value) => ({
              label: value,
              value,
            }))}
            placeholder={time}
            onChange={(option) => {
              setValue('time', (option as { value: string }).value);
            }}
            value={time}
          />
          {availabilityContent()}
          {availData && (
            <Button type="submit" data-variant="ghost">
              {t('availability.more_avail')}
            </Button>
          )}
        </FormWrapper>
      </FormProvider>
    </AvailabilityWrapper>
  );
}
