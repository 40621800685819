import { CONFIG } from '@local/configs';
import { apiService } from '@local/services';
import type {
  AllPaymentsResponse,
  EreceiptResponse,
  PaymentProfilesResponse,
  PaymentResponse,
  RawMembershipAuthsResponse,
  SocialAuthResponse,
  UserResponseDataType,
} from '@local/types';

import type { ChangePasswordSchema } from './components/ChangePassword/changePasswordSchema';
import type { EditProfileSchema } from './components/EditProfileForm/editProfileSchema';

const accountApi = apiService.extend({
  prefixUrl: CONFIG.VITE_BASE_AUTH_API_URL,
});

export const editProfile = async (
  data: EditProfileSchema,
): Promise<UserResponseDataType> =>
  accountApi.put(`v2/user`, { json: data }).json<UserResponseDataType>();

export const changePassword = async (
  data: ChangePasswordSchema,
): Promise<void> => {
  try {
    await accountApi.put(`v2/user`, { json: data }).json();
  } catch (error: unknown) {
    const { response } = error as {
      response?: { json: () => Promise<unknown> };
    };
    if (response) {
      const errorData = await response.json();
      throw errorData;
    } else {
      throw new Error(
        'Change Password: Network error or unexpected error occurred',
      );
    }
  }
};

export const deleteAccount = async () => accountApi.delete(`v2/user`);

export const getMemberships = async (
  locale: string,
): Promise<RawMembershipAuthsResponse> =>
  accountApi
    .get(`v2/user/membership_auths?locale=${locale}`)
    .json<RawMembershipAuthsResponse>();

export const deleteMembership = async (membershipAuthId: string) =>
  accountApi.delete(`v2/user/membership_auths/${membershipAuthId}`);

export const getCreditCards = async (): Promise<PaymentProfilesResponse> =>
  accountApi.get(`v2/user/payment_profiles`).json();

export const deleteCreditCard = async (paymentProfileId: string) =>
  accountApi.delete(`v2/user/payment_profiles/${paymentProfileId}`);

export const getAllPayments = async (
  locale: string,
  page: number,
): Promise<AllPaymentsResponse> =>
  accountApi
    .get(`v2/user/payments?locale=${locale}&page=${page}`)
    .json<AllPaymentsResponse>();

export const getSinglePayment = async (
  paymentId: string,
  locale: string,
): Promise<PaymentResponse> =>
  accountApi
    .get(`v2/user/payments/${paymentId}?locale=${locale}`)
    .json<PaymentResponse>();

export const getSocialAuths = async (): Promise<SocialAuthResponse> =>
  accountApi.get(`v2/user/social_auths`).json<SocialAuthResponse>();

export const deleteSocialAuth = async (socialAuthId: string) =>
  accountApi.delete(`v2/user/social_auths/${socialAuthId}`);

export const generateEreceipt = async (
  paymentId: string,
  locale: string,
  customerName?: string,
): Promise<EreceiptResponse> =>
  accountApi
    .put(`v2/user/ereceipts/${paymentId}?locale=${locale}`, {
      json: { customer_name: customerName },
    })
    .json<EreceiptResponse>();
