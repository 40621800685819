/* eslint-disable react/require-default-props */
/*  eslint-disable react/jsx-props-no-spreading */

import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';

import { i18n as globalI18n } from '@local/i18n';

import { AnalyticsProvider } from '../Contexts/AnalyticsProvider';
import { TcThemeProvider } from '../Contexts/ThemeProvider';

export function Providers({
  children,
  i18n,
  helmetProps,
  queryClient,
}: {
  children: React.ReactNode;
  i18n?: typeof globalI18n.instance;
  helmetProps?: Record<string, unknown>;
  queryClient: QueryClient;
}): JSX.Element {
  const dehydratedState =
    typeof window !== 'undefined' ? window.__REACT_QUERY_STATE__ : null;

  return (
    <HelmetProvider {...helmetProps}>
      <AnalyticsProvider>
        <QueryClientProvider client={queryClient}>
          <HydrationBoundary state={dehydratedState}>
            <I18nextProvider i18n={i18n ?? globalI18n.instance}>
              <TcThemeProvider>{children}</TcThemeProvider>
            </I18nextProvider>
          </HydrationBoundary>
        </QueryClientProvider>
      </AnalyticsProvider>
    </HelmetProvider>
  );
}
/* eslint-enable react/require-default-props */
/*  eslint-enable react/jsx-props-no-spreading */
