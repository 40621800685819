import {
  BookmarkFilled,
  CheckmarkFilled,
  MisuseOutline,
} from '@carbon/icons-react';
import { styled } from '@linaria/react';
import * as RadixToast from '@radix-ui/react-toast';
import { t } from 'i18next';

import { DEFAULT_ICON_SIZE } from '@local/constants';
import type { SavedShopToListResponse } from '@local/types';

export const useToast = () => {
  const ToastAction = styled(RadixToast.Action)`
    text-decoration: underline;
    cursor: pointer;
  `;

  const Btn = styled.button`
    background-color: transparent;
    border: 0;
    padding: 0;
    text-decoration: underline;
    color: var(--white);
  `;

  const saveVenueToastContent = (
    lists: SavedShopToListResponse['lists'],
    actionBtn?: (state: 'login' | 'save_list' | 'create_list' | null) => void,
  ) => {
    const savedCount = lists.saved.length;
    const unsavedCount = lists.unsaved.length;

    let text = '';
    let dataTestId = 'Toast Save Venue';
    if (savedCount === 1) {
      text = t('toast.saved_to', {
        venue: lists.saved[0].name,
      });
    } else if (savedCount > 1) {
      text = t('toast.saved_to_more_than_one', {
        venue: lists.saved[0].name,
        count: savedCount - 1,
      });
    } else if (unsavedCount === 1) {
      text = t('toast.removed_from', {
        venue: lists.unsaved[0].name,
      });
      dataTestId = 'Toast Unsave Venue';
    } else if (unsavedCount > 1) {
      text = t('toast.removed_from_more_than_one', {
        venue: lists.unsaved[0].name,
        count: unsavedCount - 1,
      });
      dataTestId = 'Toast Unsave Venue';
    }

    return (
      <>
        <BookmarkFilled size={DEFAULT_ICON_SIZE} />
        <span data-testid={dataTestId}>{text}</span>
        {!!actionBtn && (
          <RadixToast.ToastAction asChild altText={t('general.edit')}>
            <Btn onClick={() => void actionBtn('save_list')}>
              {t('general.edit')}
            </Btn>
          </RadixToast.ToastAction>
        )}
      </>
    );
  };

  const createVenueToastContent = (
    newList: string,
    actionBtn: (state: 'save_list' | 'create_list' | 'login' | null) => void,
  ) => (
    <>
      <BookmarkFilled size={DEFAULT_ICON_SIZE} />
      <span data-testid="Toast Create Venue">
        {t('toast.saved_to', {
          venue: newList,
        })}
      </span>
      <ToastAction asChild altText={t('general.edit')}>
        <Btn onClick={() => void actionBtn('save_list')}>
          {t('general.edit')}
        </Btn>
      </ToastAction>
    </>
  );

  const subscribeVenueToastContent = (
    isSubscribed: boolean,
    venueTitle: string,
  ) => (
    <>
      <CheckmarkFilled size={DEFAULT_ICON_SIZE} />
      <span
        data-testid={
          isSubscribed ? 'Toast Subscribe Venue' : 'Toast Unsubscribe Venue'
        }
      >
        {t(
          isSubscribed ? 'toast.subscribed_venue' : 'toast.unsubscribed_venue',
          {
            venue: venueTitle,
          },
        )}
      </span>
    </>
  );

  const updateListToastContent = (action: 'update_list' | 'delete_list') => {
    const config = {
      update_list: {
        testId: 'Toast Update List',
        text: t('toast.updated_list'),
      },
      delete_list: {
        testId: 'Toast Delete List',
        text: t('toast.deleted_list'),
      },
    };
    const { testId, text } = config[action];

    return (
      <>
        <CheckmarkFilled size={DEFAULT_ICON_SIZE} />
        <span data-testid={testId}>{text}</span>
      </>
    );
  };

  const updateMemoToastContent = (action: string) => (
    <>
      <CheckmarkFilled size={DEFAULT_ICON_SIZE} />
      <span data-testid="Toast Update Memo">
        {t(action === 'save_memo' ? 'toast.saved_memo' : 'toast.deleted_memo')}
      </span>
    </>
  );

  const successToastContent = (content: string, dataTestId?: string) => (
    <>
      <CheckmarkFilled size={DEFAULT_ICON_SIZE} />
      <span data-testid={dataTestId ?? 'Toast Success'}>{content}</span>
    </>
  );

  const errorToastContent = (content: string) => (
    <>
      <MisuseOutline size={DEFAULT_ICON_SIZE} />
      <span data-overflow="visible" data-testid="Toast Error">
        {content}
      </span>
    </>
  );

  return {
    saveVenueToastContent,
    createVenueToastContent,
    updateListToastContent,
    subscribeVenueToastContent,
    updateMemoToastContent,
    errorToastContent,
    successToastContent,
  };
};
