import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';

export const Info = styled.section`
  display: grid;
  gap: 8px;
  grid-template-columns: 24px auto;
  margin-bottom: var(--spacing-l4);
  width: 100%;
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const VenueLink = styled.a`
  text-decoration: underline;
`;

export const VenueDetails = styled.div`
  color: var(--text-subtle);
  display: flex;
  & :nth-child(2) {
    margin: 0 var(--spacing-l2);
  }
`;

export const BusinessHours = styled.div`
  color: var(--text-subtle);
  display: flex;
  justify-content: space-between;
`;

export const OpeningHours = styled(BusinessHours)`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-l2);
  margin-top: var(--spacing-l2);
`;

export const MapWrapper = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
  margin-bottom: var(--spacing-l4);
  & > div {
    border-radius: var(--border-radius-small);
    border: 1px solid var(--border);
  }
`;

export const DirectionButton = styled(Button)`
  position: absolute;
  bottom: var(--spacing-l4);
  right: var(--spacing-l4);
  background-color: white;
  font-size: 14px;
  line-height: 16px;
  padding: var(--spacing-l2);
`;

export const InformationWrapper = styled.section`
  word-break: break-all;
  overflow-wrap: break-word;
`;
