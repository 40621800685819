import { format, parseISO } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';

import { Payment } from '@local/types';
import { DateFormat, getDateFormat } from '@local/utils';

export function usePayments() {
  const mapPaymentStatusToStr = (paymentStatus: Payment['payment_status']) => {
    switch (paymentStatus) {
      case 'paid':
      case 'cancel_fee':
        return 'paid';
      case 'refunded':
        return 'refunded';
      case 'authorized':
        return 'deposit';
      case 'pending':
      case 'pending_3ds':
        return 'pending';
      case 'stored':
        return 'stored';
      case 'voided':
        return 'voided';
      default:
        return paymentStatus;
    }
  };

  const mapPaymentTypeToStr = (
    paymentType: Payment['payment_type'],
    paymentStatus: Payment['payment_status'],
  ) => {
    switch (paymentType) {
      case 'prepay':
        return 'prepay';
      case 'smartpay':
        return 'contactless_pay';
      case 'deposit':
      case 'register':
        return paymentStatus === 'paid' || paymentStatus === 'cancel_fee'
          ? 'card_authorized'
          : 'auth_hold';
      default:
        return paymentType;
    }
  };

  const matchStatusToVariant = (status: string) => {
    switch (status) {
      case 'paid':
        return 'success';
      case 'refunded':
        return 'info';
      case 'deposit':
        return 'warning';
      case 'voided':
        return 'neutral';
      default:
        return 'neutral';
    }
  };

  const groupPaymentsByMonth = (payments: Payment[], language: string) =>
    payments.reduce((paymentsMap, payment) => {
      const monthYear = format(
        parseISO(payment.created_at),
        getDateFormat(language, DateFormat.YearMonth),
        {
          locale: dateFnsLocales[language as keyof typeof dateFnsLocales],
        },
      );
      const capitalizedMonthYear =
        monthYear.charAt(0).toUpperCase() + monthYear.slice(1);

      if (!paymentsMap.has(capitalizedMonthYear)) {
        paymentsMap.set(capitalizedMonthYear, []);
      }
      paymentsMap.get(capitalizedMonthYear)?.push(payment);
      return paymentsMap;
    }, new Map<string, Payment[]>());

  return {
    mapPaymentStatusToStr,
    mapPaymentTypeToStr,
    matchStatusToVariant,
    groupPaymentsByMonth,
  };
}
