import { Renew } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetUserQuery } from '@local/auth';
import {
  nextPanelActionAtom,
  panelStateAtom,
  VenueCard,
  ViewSwitcherBtn,
} from '@local/components';
import { DEFAULT_ICON_SIZE, TABLET_BREAKPOINT } from '@local/constants';
import { selectedVenueAtom } from '@local/map-system';
import {
  SearchFilterBar,
  SearchFilterPanel,
  SearchInput,
  SearchResultsContent,
  SearchResultsPanel,
  searchResultsPanelAtom,
  searchViewAtom,
  SNAP_END_POINT,
  SNAP_MIDDLE_POINT,
  SNAP_START_POINT,
  useExplore,
  useFetchGeolocation,
  useSearchFilters,
  useSearchInput,
} from '@local/search';
import type { MapServiceApi, SearchFilterViewType } from '@local/types';
import { buildUrl, translate } from '@local/utils';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  flex-direction: column;
  gap: var(--spacing-l4);
  margin-top: var(--spacing-l4);
  background: transparent;
  &[data-has-focus='true'] {
    background: var(--surface);
    transition: background 0.3s ease-in-out;
  }

  &[data-has-focus='false'] {
    div[data-is-mobile='true'] {
      box-shadow: var(--elevation-large);
      background: var(--surface);
    }
    div > button[data-size='small'] {
      box-shadow: var(--elevation-medium);
    }
  }
`;
const MapSection = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;

  &[data-hidden='true'] {
    display: none;
  }
`;
const MapSearchBtn = styled(Button)`
  position: absolute;
  top: 150px;
  border-radius: var(--border-radius-full);
  z-index: var(--zindex-card);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: var(--elevation-medium);
  color: var(--primary);

  &[data-hidden='true'] {
    display: none;
  }
`;
const MapVenueCard = styled(VenueCard)`
  position: absolute;
  width: 95svw;
  max-width: var(--map-card-width);
  bottom: calc(var(--mobile-footer-height) + var(--search-panel-header-height));
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--border-radius-large);
  padding: var(--spacing-l2);
`;

interface Props {
  map: MapServiceApi;
}

export function ExploreMobile({ map }: Props): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [, { language }] = useTranslation();
  const { data: user } = useGetUserQuery();
  const { data: geolocation } = useFetchGeolocation();
  const { updateSearchFilters, searchFilters } = useSearchFilters();
  const [, setPanelState] = useAtom(panelStateAtom);
  const [, setNextPanelAction] = useAtom(nextPanelActionAtom);
  const { shopSearchQuery, allSavedShopsSet, availabilityToShow } =
    useExplore();

  const [selectedVenue, setSelectedVenue] = useAtom(selectedVenueAtom);
  const { venue, shouldShowMapVenueCard } = selectedVenue;

  const [searchFilterView, setSearchFilterView] =
    React.useState<SearchFilterViewType>('none');
  const [searchView, setSearchView] = useAtom(searchViewAtom);
  const [{ snap }, setSearchResultsPanel] = useAtom(searchResultsPanelAtom);

  const shouldShowSearchResults = searchView === 'default';
  const isResultsPanelFullHeight = snap === SNAP_END_POINT;
  const shouldShowSearchFilterBar = searchView === 'default';
  const shouldShowMap = searchView === 'default' && !isResultsPanelFullHeight;
  const { searchInputRef } = useSearchInput();

  React.useEffect(() => {
    // opens the search panel halfway on first page load
    setSearchResultsPanel((prev) => ({
      ...prev,
      snap: SNAP_MIDDLE_POINT,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        id="search"
        data-has-focus={searchView === 'focused' || isResultsPanelFullHeight}
        data-testid="Explore Page Root"
      >
        <SearchInput ref={searchInputRef} />
        {shouldShowSearchFilterBar && (
          <SearchFilterBar setSearchFilterView={setSearchFilterView} />
        )}
        {shouldShowSearchResults && (
          <SearchResultsPanel>
            <SearchResultsContent
              onResetSearch={() => {
                if (map.instance && geolocation) {
                  map.instance.flyTo({
                    center: [geolocation.longitude, geolocation.latitude],
                  });
                }
              }}
            />
          </SearchResultsPanel>
        )}
        <SearchFilterPanel
          isOpen={searchFilterView !== 'none'}
          onClose={() => setSearchFilterView('none')}
          searchFilterView={searchFilterView}
        />
        {!shouldShowMap && (
          <ViewSwitcherBtn
            onClick={() => {
              setSearchView('default');
              setSearchResultsPanel((prev) => ({
                ...prev,
                snap: SNAP_START_POINT,
              }));
            }}
          />
        )}
      </Container>

      <MapSection
        data-testid="Explore Page Map"
        id="map"
        onTouchStart={() => {
          setSearchResultsPanel((prev) => ({
            ...prev,
            snap: SNAP_START_POINT,
          }));
        }}
        style={
          {
            '--geolocate-control-bottom':
              snap === SNAP_MIDDLE_POINT
                ? `calc(40svh - (100svh - ${TABLET_BREAKPOINT}) * 0.1)`
                : 'calc(var(--mobile-footer-height) + var(--search-panel-header-height))',
          } as React.CSSProperties
        }
        data-hidden={!shouldShowMap}
      >
        {venue && shouldShowMapVenueCard && shouldShowMap && (
          <MapVenueCard
            data-testid="Map Venue Card"
            venue={{
              slug: venue.slug,
              title: translate(venue.name_translations, language),
              cuisines: venue.cuisines,
              lunchAvg: venue.budget_lunch_avg,
              dinnerAvg: venue.budget_dinner_avg,
              imageUrls: [venue.search_image],
              availability: venue.availability,
            }}
            onClick={() =>
              navigate(buildUrl(`/${language}/${venue.slug}`, searchParams))
            }
          >
            <VenueCard.Header
              icon={
                allSavedShopsSet.has(venue.id)
                  ? 'bookmarkFilled'
                  : 'bookmarkAdd'
              }
              onIconClick={() => {
                if (user) {
                  setPanelState('save_list');
                } else {
                  setPanelState('login');
                  setNextPanelAction(() => () => setPanelState('save_list'));
                }
              }}
              buttonTestId="Map Venue Card Bookmark Btn"
            />
            <VenueCard.Info />
            <VenueCard.Budget />
            <VenueCard.SidePicture />
            {availabilityToShow === 'time' && (
              <VenueCard.TimeSlots
                query={{
                  date: searchFilters.date,
                  pax: searchFilters.num_people,
                  time: searchFilters.time,
                }}
              />
            )}
            {availabilityToShow === 'date' && searchFilters.time && (
              <VenueCard.DateTable
                query={{
                  pax: searchFilters.num_people,
                  time: searchFilters.time,
                }}
              />
            )}
          </MapVenueCard>
        )}
        {map.movementDetail?.shouldPerformSearch && (
          <MapSearchBtn
            data-variant="tertiary"
            data-hidden={!shouldShowMap}
            aria-busy={
              shopSearchQuery.status === 'pending' ||
              shopSearchQuery.fetchStatus === 'fetching'
            }
            onClick={() => {
              setSelectedVenue({ venue: null, shouldShowMapVenueCard: false });
              updateSearchFilters({
                geo_latitude: map.movementDetail?.center.lat,
                geo_longitude: map.movementDetail?.center.lng,
                geo_distance: `${map.movementDetail?.radiusInKm}km`,
                auto_geolocate: false,
              });
            }}
          >
            <Renew size={DEFAULT_ICON_SIZE} />
            {t('search.action_btns.search_area')}
          </MapSearchBtn>
        )}
      </MapSection>
    </>
  );
}
