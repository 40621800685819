import { styled } from '@linaria/react';
import { ButtonProps } from '@tablecheck/tablekit-react-css';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { SwiperSlide } from 'swiper/react';

import { CardCarousel } from '@local/components';
import { CONFIG } from '@local/configs';
import { TABLET_BREAKPOINT } from '@local/constants';
import { imgFormat } from '@local/utils';

import { CampaignCard } from './CampaignCard';
import { DesktopContentWrapper } from './DesktopContentWrapper';
import { MobileContentWrapper } from './MobileContentWrapper';

const DesktopWrapper = styled.div`
  &[data-campaigns-count='few'] {
    --campaigns-displayed: 2;
  }
  &[data-campaigns-count='many'] {
    --campaigns-displayed: 3;
  }
  & .swiper-slide {
    width: calc((100% / var(--campaigns-displayed)) - var(--spacing-l2));
    max-width: calc(
      (var(--desktop-content-width) / var(--campaigns-displayed)) +
        var(--spacing-l2)
    );
    & > div {
      width: auto;
    }
  }
`;
const MobileWrapper = styled.div`
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  & div {
    width: 100%;
    min-width: 80svw;
    max-width: min-content;
    height: auto;
  }
`;

export function CampaignSection() {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const [t, { language }] = useTranslation();
  const locale = language !== 'ja' ? 'en' : 'ja';

  const campaignsData = React.useMemo(
    () => [
      {
        backgroundColor: 'white',
        title: t('campaigns.fastpass.title'),
        description: t('campaigns.fastpass.description'),
        imageUrl: `${CONFIG.VITE_BASE_CDN_IMAGES_URL}banners/fastpass/v8.0.0/fastpass.${imgFormat}`,
        link: `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${locale}/promo/fastpass/`,
      },
      {
        backgroundColor: '#7935D2',
        textColor: 'white',
        buttonVariant: 'ghost' as ButtonProps['data-variant'],
        title: t('campaigns.tc_pay.title'),
        description: t('campaigns.tc_pay.description'),
        imageUrl: `${CONFIG.VITE_BASE_CDN_IMAGES_URL}banners/contactless-pay/v4.0.0/tc-pay.${imgFormat}`,
        link: `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}ja/promo/contactless-pay-restaurants/`,
      },
    ],
    [t, locale],
  );

  return (
    <>
      {isDesktop ? (
        <DesktopContentWrapper>
          <DesktopWrapper
            data-campaigns-count={campaignsData.length <= 2 ? 'few' : 'many'}
          >
            <CardCarousel id="campaigns">
              {campaignsData.map((campaign) => (
                <SwiperSlide key={campaign.title}>
                  <CampaignCard {...campaign} />
                </SwiperSlide>
              ))}
            </CardCarousel>
          </DesktopWrapper>
        </DesktopContentWrapper>
      ) : (
        <MobileContentWrapper>
          <MobileWrapper>
            {campaignsData.map((campaign) => (
              <CampaignCard key={campaign.title} {...campaign} />
            ))}
          </MobileWrapper>
        </MobileContentWrapper>
      )}
    </>
  );
}
