import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

import { createPasswordSchema } from '../PasswordRequirements/passwordSchema';

export const signUpSchema = z
  .object({
    first_name: z.string().trim().min(1),
    last_name: z.string().trim().min(1),
    email: z.string().trim().email(),
    password: z.string(),
    phone: z.string().refine((value) => isValidPhoneNumber(value)),
    locale: z.string(),
  })
  .refine(
    (data) => createPasswordSchema(data.email).safeParse(data.password).success,
    {
      path: ['password'],
    },
  );

export type SignUpSchemaType = z.infer<typeof signUpSchema>;
