import { LocaleCode } from '@tablecheck/locales';
import { addMinutes, format, setHours, setMinutes } from 'date-fns';

// Default format is the most common in the world
export enum DateFormat {
  MonthDayTime = 'd MMM H:mm', // 19 dic 23:30, varies depending on locale
  YearMonthDayTime = 'd MMM yyyy, H:mm', // 19 dic 2024, 23:30
  MonthDay = 'd MMM', // 19 dic
  MonthDayShort = 'd/M', // 19/12
  YearMonthDay = 'd MMM yyyy', // 19 dic 2024
  YearMonth = 'MMM yyyy', // dic 2024
}

export enum TimeFormat {
  Time24H = 'H:mm',
  Time12H = 'h:mm aaa',
}

const LocalizedDateFormats = {
  Kanji: {
    [DateFormat.MonthDayTime]: 'Mo do (eee) H:mm', // 12月 19日 (木) 23:30
    [DateFormat.YearMonthDayTime]: 'Yo Mo do H:mm', // 2024年 12月 19日 23:30
    [DateFormat.MonthDay]: 'Mo do (eee)', // 12月 19日 (木)
    [DateFormat.MonthDayShort]: 'M/d', // 12/19
    [DateFormat.YearMonthDay]: 'Yo Mo do', // 2024年 12月 19日
    [DateFormat.YearMonth]: 'Yo Mo', // 2024年 12月
  },
  Korea: {
    [DateFormat.MonthDayTime]: 'MMM do H:mm', // 12월 19일 23:30
    [DateFormat.YearMonthDayTime]: 'Y년 MMM do H:mm', // 2024년 12월 19일 23:30
    [DateFormat.MonthDay]: 'MMM do', // 12월 19일
    [DateFormat.MonthDayShort]: 'M/d', // 12/19
    [DateFormat.YearMonthDay]: 'Y년 MMM do', // 2024년 12월 19일
    [DateFormat.YearMonth]: 'Y년 MMM', // 2024년 12월
  },
  USA: {
    [DateFormat.MonthDayTime]: 'MMM do, H:mm', // Dec 19th, 23:30
    [DateFormat.YearMonthDayTime]: 'MMM do, yyyy, H:mm', // Dec 19th, 2024, 23:30
    [DateFormat.MonthDay]: 'MMM do', // Dec 19th
    [DateFormat.MonthDayShort]: 'M/d', // 12/19
    [DateFormat.YearMonthDay]: 'MMM do, yyyy', // Dec 19th, 2024
    [DateFormat.YearMonth]: 'MMM yyyy', // Dec 2024
  },
  Arabic: {
    [DateFormat.MonthDayTime]: 'd MMM H:mm',
    [DateFormat.YearMonthDayTime]: 'd MMM yyyy H:mm',
    [DateFormat.MonthDay]: 'd MMM',
    [DateFormat.MonthDayShort]: 'd/M',
    [DateFormat.YearMonthDay]: 'MMM do yyyy',
    [DateFormat.YearMonth]: 'MMM yyyy',
  },
};

export const getTimeFormat = (locale: string): string =>
  [
    LocaleCode.Arabic,
    LocaleCode.Spanish,
    LocaleCode.English,
    LocaleCode.Malay,
  ].indexOf(locale as LocaleCode) !== -1
    ? TimeFormat.Time12H
    : TimeFormat.Time24H;

export const getDateFormat = (
  locale: string,
  dateFormat: DateFormat,
): string => {
  if ([LocaleCode.English].indexOf(locale as LocaleCode) !== -1) {
    return LocalizedDateFormats.USA[dateFormat];
  }
  if (
    locale === LocaleCode.Japanese ||
    locale === LocaleCode.ChineseSimplified ||
    locale === LocaleCode.ChineseTraditional
  ) {
    return LocalizedDateFormats.Kanji[dateFormat];
  }
  if (locale === LocaleCode.Korean) {
    return LocalizedDateFormats.Korea[dateFormat];
  }
  if (locale === LocaleCode.Arabic) {
    return LocalizedDateFormats.Arabic[dateFormat];
  }
  return dateFormat;
};

export const roundTimeTo30Min = (date: Date): string => {
  const minutes = date.getMinutes();
  const roundedMinutes = Math.ceil(minutes / 30) * 30;
  let roundedDate = setMinutes(date, roundedMinutes);
  if (roundedMinutes === 60) {
    roundedDate = addMinutes(setMinutes(date, 0), 60);
  }
  return format(roundedDate, 'HH:mm');
};

export const generate30MinIntervals = (date?: string): string[] => {
  let startTime: Date;
  const today = new Date();
  const timeValues: string[] = [];
  if (date && new Date(date).toDateString() === today.toDateString()) {
    const [hours, minutes] = roundTimeTo30Min(today).split(':').map(Number);
    startTime = setMinutes(setHours(today, hours), minutes);
  } else {
    startTime = setMinutes(setHours(today, 0), 0);
  }

  const endTime = setMinutes(setHours(today, 23), 30);
  while (startTime <= endTime) {
    timeValues.push(format(startTime, 'HH:mm'));
    startTime = addMinutes(startTime, 30);
  }
  return timeValues;
};
