import { Wikis } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import {
  LocaleCode,
  locales,
  ordered as orderedLocales,
} from '@tablecheck/locales';
import { Button } from '@tablecheck/tablekit-react-css';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import {
  DEFAULT_ICON_SIZE,
  ICON_SIZE_16,
  TABLET_BREAKPOINT,
} from '@local/constants';
import { Locales, i18n } from '@local/i18n';

import { Panel } from '../Panel/Panel';

const LanguageContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: var(--spacing-l3);
  padding: var(--spacing-l4);
  overflow-y: auto;
`;

const Item = styled(Button)`
  display: flex;
  padding: var(--spacing-l2) var(--spacing-l3);
  flex: 30%;
  gap: 0;
  text-align: left;
  flex-wrap: wrap;
  & > * {
    width: 100%;
  }
  &[data-is-active='true'] {
    background-color: var(--surface-raised-active);
    color: var(--text);
    border: 1px solid var(--brand-primary);
  }
`;
const SubtleText = styled.small`
  color: var(--text-subtle);
`;

export function LanguageSelector() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [t, { language }] = useTranslation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const iconSize = isDesktop ? DEFAULT_ICON_SIZE : ICON_SIZE_16;

  const handleLocaleChange = (localeCode: LocaleCode) => {
    void i18n.instance.changeLanguage(localeCode);
    navigate(Locales.getUrlWithNewLocale(window.location, localeCode));
    setIsOpen(false);
  };
  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        data-testid="Language Selector Btn"
        data-variant="bare"
      >
        <Wikis size={iconSize} aria-label={t('general.select_language')} />
        {language.toUpperCase()}
      </Button>
      <Panel
        isOpen={isOpen}
        title={t('general.select_language')}
        onClose={() => setIsOpen(false)}
      >
        <LanguageContent data-testid="Language Selector Panel">
          {orderedLocales.map((locale) => {
            const isActive = language === locale.code;
            return (
              <Item
                key={locale.code}
                data-variant="tertiary"
                type="button"
                data-is-active={isActive}
                data-testid={`Language Selector Item ${locale.code}`}
                onClick={() => void handleLocaleChange(locale.code)}
              >
                <span>{locale.label}</span>
                <SubtleText data-is-active={isActive}>
                  {isActive
                    ? t('general.selected')
                    : locales.en.translations[locale.code]}
                </SubtleText>
              </Item>
            );
          })}
        </LanguageContent>
      </Panel>
    </>
  );
}
