import {
  BUDGET_INFINITY,
  BUDGET_ZERO,
  DEFAULT_GEO_DISTANCE,
} from '@local/constants';
import type { ShopSearchQueryParamsType } from '@local/types';

const BASE_HEIGHT = 750;

// Makes sure the search result header is always visible whatever the screen height
const calculateSnapPoint = (): number => {
  const height =
    typeof window !== 'undefined' ? window.innerHeight : BASE_HEIGHT;
  const baseValue = 0.59;
  const reductionFactor = 0.00015; // Amount to reduce per pixel of height
  const heightDiff = Math.max(0, height - BASE_HEIGHT);
  return Number((baseValue - heightDiff * reductionFactor).toFixed(4));
};

export const SNAP_START_POINT = calculateSnapPoint();
export const SNAP_MIDDLE_POINT = 0.79;
export const SNAP_END_POINT = 1;
export const SNAP_POINTS = [
  SNAP_START_POINT,
  SNAP_MIDDLE_POINT,
  SNAP_END_POINT,
];
export const SEARCH_SUGGESTIONS_DEBOUNCE_TIME = 300;
export const SEARCH_SUGGESTIONS_MIN_LENGTH = 2;

export const DEFAULT_SEARCH_FILTERS: ShopSearchQueryParamsType = {
  cuisines: [],
  search_text: '',
  name: '',
  shop_universe_id: '',
  sort_by: 'distance',
  budget_dinner_avg_min: BUDGET_ZERO,
  budget_dinner_avg_max: BUDGET_INFINITY,
  geo_distance: DEFAULT_GEO_DISTANCE,
  service_mode: 'dining',
  is_smartpay: false,
  hub_shop_list_ids: [],
};
