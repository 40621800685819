import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useAtom } from 'jotai';

import { CONFIG } from '@local/configs';
import {
  BackgroundImg,
  CampaignSection,
  Catchphrase,
  OverlayContent,
  StoryblokSection,
} from '@local/home';
import { SearchInput, searchViewAtom, useSearchInput } from '@local/search';
import { imgFormat, useUniverse } from '@local/utils';

const Container = styled.div`
  &[data-has-focus='true'] {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100svw;
    height: 100svh;
    background-color: white;
    & form {
      padding: var(--spacing-l4);
      padding-bottom: 0;
      min-width: 100svw;
    }
  }
`;
const Content = styled.div`
  display: grid;
  margin: var(--spacing-l4);
  margin-top: var(--spacing-l7);
`;
export function HomeMobile(): JSX.Element {
  const [searchView] = useAtom(searchViewAtom);
  const { searchInputRef } = useSearchInput();
  const heroBannerImg = `${CONFIG.VITE_BASE_CDN_IMAGES_URL}banners/hero-new-dpor/v1.0.0/hero-banner-japan-mobile.${imgFormat}`;
  const { isJapanUniverse } = useUniverse();

  return (
    <div data-testid="Home Page Root">
      {/* //TODO: Temporary image until Sean provides a static one DPOR-717 */}
      <BackgroundImg
        src={heroBannerImg}
        alt="TableCheck Diner Portal Home Banner"
      />
      <OverlayContent>
        <Catchphrase>{t('homepage.catchphrase')}</Catchphrase>
        <Container
          data-testid="Home Search Panel"
          data-has-focus={searchView === 'focused'}
        >
          <SearchInput
            dataTestId="Home Search Bar"
            hasNoShadow={searchView !== 'focused'}
            ref={searchInputRef}
          />
        </Container>
      </OverlayContent>

      <Content>
        {isJapanUniverse && <CampaignSection />}
        <StoryblokSection type="cuisines" />
        <StoryblokSection type="area" />
      </Content>
    </div>
  );
}
