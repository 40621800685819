import {
  getTranslation,
  Translation,
  TranslationsArray,
} from '@tablecheck/i18n';
import { LocaleCode } from '@tablecheck/locales';

export const translate = (
  rawTranslation: Translation | TranslationsArray | string | undefined,
  locale: string,
): string => {
  if (!rawTranslation) return '';

  return getTranslation(
    typeof rawTranslation === 'string'
      ? rawTranslation
      : ([] as TranslationsArray).concat(rawTranslation).map((translation) => ({
          locale: translation.locale as LocaleCode,
          text: translation.translation,
        })),
    locale as LocaleCode,
  );
};

// the locale of the best match translation can differ from the current application locale
// even if the app locale is japanese, we need to ensure we never word wrap non-japanese languages
export const translateForMarkup = (
  ...args: Parameters<typeof translate>
): [string, string | undefined] => {
  const bestMatch = translate(...args);
  const [rawTranslations] = args;

  if (rawTranslations && Array.isArray(rawTranslations)) {
    const foundTranslation = rawTranslations.find(
      ({ translation }) => translation === bestMatch,
    );
    return [bestMatch, foundTranslation ? foundTranslation.locale : undefined];
  }
  return [bestMatch, undefined];
};
