/// <reference types="../../../definitions/window.d.ts" />
/// <reference types="vite/client" />

import ky, { KyInstance, Options } from 'ky';

import { CONFIG } from '@local/configs';
import { AuthToken } from '@local/types';

import { cookieService } from './cookieService';

const isSSR = import.meta.env.SSR;
const shouldUseNativeHttp = !isSSR && window?.cordova?.plugin?.http;

// NOTE: https://github.com/tablecheck/diner-frontend/blob/cb6e2ca0c447969c3229b30cbda31b4a519c9a94/src/utils/fetch.js#L8-L12
const cordovaRequestHandler = async (request: Request) => {
  const cordovaOptions: {
    headers: Record<string, string>;
    method: string;
    data?: string;
    serializer?: 'json';
  } = {
    method: request.method,
    headers: {},
  };
  if (request.headers) {
    request.headers.forEach((value, key) => {
      cordovaOptions.headers[key] = value;
    });
  }
  if (request.method) {
    cordovaOptions.method = request.method;
  }

  if (request.body) {
    cordovaOptions.data = JSON.stringify(await request.json());
  }
  if (request.method === 'POST' || request.method === 'PUT') {
    cordovaOptions.serializer = 'json';
  }
  return new Promise<Response>((resolve, reject) => {
    window.cordova.plugin.http.sendRequest(
      request.url,
      cordovaOptions,
      (response) => {
        let data;
        try {
          data = JSON.parse(response.data);
        } catch (error) {
          console.error('Error: Parse JSON', error);
        }
        return resolve(new Response(JSON.stringify(data), response));
      },
      (errResponse) => {
        reject(new Error(errResponse.error));
      },
    );
  });
};

const reactivateMsw = async () => {
  // why we need to reactivate MSW? see this: https://github.com/mswjs/msw/issues/2115, msw stops intercepting the request after some idle time
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage('MOCK_ACTIVATE');
    // Small wait for changes to propagate
    await new Promise((resolve) => {
      setTimeout(resolve, 10);
    });
  }
};

export const refreshToken = async (data: AuthToken): Promise<AuthToken> =>
  ky
    .post(`v2/user/token`, {
      prefixUrl: CONFIG.VITE_BASE_AUTH_API_URL,
      json: {
        grant_type: 'refresh_token',
        refresh_token: data.refresh_token,
      },
    })
    .json<AuthToken>();

const isValidReqUrl = (url: string): boolean => {
  const VALID_REQUEST_ORIGINS = [
    CONFIG.VITE_BASE_API_URL,
    CONFIG.VITE_BASE_INTERNAL_API_URL,
    CONFIG.VITE_BASE_AUTH_API_URL,
  ];
  const urlOrigin = new URL(url).origin;
  return VALID_REQUEST_ORIGINS.some(
    (origin) => urlOrigin === new URL(origin).origin,
  );
};

const addAuthHeader = async (request: Request): Promise<void> => {
  if (!isValidReqUrl(request.url)) {
    return;
  }
  let authToken = cookieService.getAuthToken();

  if (!authToken) {
    return;
  }
  if (cookieService.isTokenExpired(authToken)) {
    try {
      authToken = await refreshToken(authToken);
      cookieService.setAuthToken(authToken);
    } catch (error) {
      console.error('Failed to refresh token:', error);
      cookieService.removeAuthToken();
      return;
    }
  }
  request.headers.set('Authorization', `Bearer ${authToken.access_token}`);
};

const create = (defaultOptions: Options): KyInstance => {
  let beforeRequestHooks = defaultOptions?.hooks?.beforeRequest ?? [];

  beforeRequestHooks = [addAuthHeader, ...beforeRequestHooks];
  if (CONFIG.VITE_IS_MOCKING_ENABLED) {
    beforeRequestHooks.push(reactivateMsw);
  }
  if (shouldUseNativeHttp) {
    beforeRequestHooks.push(cordovaRequestHandler);
  }
  return ky.create({
    ...defaultOptions,
    retry: 3,
    hooks: {
      ...(defaultOptions?.hooks ?? {}),
      beforeRequest: beforeRequestHooks,
    },
  });
};

const shouldUseInternalApi =
  isSSR && CONFIG.VITE_APP_ENVIRONMENT !== 'development';

const apiService = create({
  prefixUrl: shouldUseInternalApi
    ? CONFIG.VITE_BASE_INTERNAL_API_URL
    : CONFIG.VITE_BASE_API_URL,
});
// override the create method to allow for custom options
export { apiService };
