import { styled } from '@linaria/react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TABLECHECK_LOGO } from '@local/assets';
import { LanguageSelector, UniverseSelector } from '@local/components';
import { searchViewAtom } from '@local/search';
import { useRouting, useUniverse } from '@local/utils';

const Header = styled.header`
  display: grid;
  justify-self: start;
  grid-template-columns: 1fr 1fr;
  padding: var(--spacing-l2) var(--spacing-l4);
  width: 100svw;
  border-bottom: 1px solid var(--lighter-grey-border);
  height: var(--mobile-header-height);
  font-size: 14px;
  z-index: var(--z-index-1);
  background-color: white;
  > :first-child {
    justify-self: start;
  }
  > :last-child {
    justify-self: end;
  }
`;

const TCLogo = styled.img`
  height: 20px;
  align-self: center;
`;

const SelectorsWrapper = styled.div`
  display: flex;
`;

export function MobileHeader() {
  const navigate = useNavigate();
  const [, { language }] = useTranslation();
  const routing = useRouting();
  const searchView = useAtomValue(searchViewAtom);
  const shouldHideHeader =
    routing.isExplorePage || routing.isVenuePage || searchView === 'focused';
  const { universeName } = useUniverse();

  if (shouldHideHeader) return null;
  return (
    <Header data-testid="Mobile Main Header">
      <TCLogo
        src={TABLECHECK_LOGO}
        alt="TableCheck"
        onClick={() => navigate(`/${language}/${universeName}`)}
      />
      <SelectorsWrapper>
        <UniverseSelector />
        <LanguageSelector />
      </SelectorsWrapper>
    </Header>
  );
}
