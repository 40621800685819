export enum UniverseName {
  Japan = 'japan',
  Singapore = 'singapore',
}

export enum UniverseSlug {
  JP = 'JP',
  SG = 'SG',
}

export enum UniverseCurrency {
  JPY = 'JPY',
  SGD = 'SGD',
}

export enum UniverseId {
  JP = '57e0b91744aea12988000001',
  SG = '5f570b47129d0d00075da5d8',
}

export interface Universe {
  id: UniverseId;
  name: UniverseName;
  slug: UniverseSlug;
  currency: UniverseCurrency;
  defaultLocation: {
    lon: number;
    lat: number;
    country: UniverseSlug;
  };
  boundaries: {
    southWest: maplibregl.LngLatLike;
    northEast: maplibregl.LngLatLike;
  };
  budgetLimit: number;
  zoomLevel: number;
}
