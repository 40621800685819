import { Location } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  DEFAULT_ICON_SIZE,
  ICON_SIZE_16,
  TABLET_BREAKPOINT,
  VALID_UNIVERSES,
} from '@local/constants';
import { cookieService } from '@local/services';
import { UniverseSlug } from '@local/types';
import { useUniverse } from '@local/utils';

import { Panel } from '../Panel/Panel';

const UniverseTrigger = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--spacing-l2);
  padding: var(--spacing-l2);
  border-radius: var(--border-radius-small);
  &:hover {
    background-color: var(--grey-100);
  }
`;

const Item = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-l1);
  width: 100%;
  &[data-is-active='true'] {
    background-color: var(--surface-raised-active);
    color: var(--text);
    border: 1px solid var(--brand-primary);
  }
`;

const Title = styled.span`
  font-weight: var(--label-font-weight);
`;

const Text = styled.small`
  color: var(--text-subtle);
`;

const SelectorWrapper = styled.div`
  display: flex;
  gap: var(--spacing-l2);
  padding: var(--spacing-l4);
  padding-top: 0;
  flex-direction: column;
  &[data-is-desktop='true'] {
    flex-direction: row;
  }
`;

function pathnameWithNewUniverse(pathname: string) {
  const [japan, singapore] = VALID_UNIVERSES;
  // Split into segments and filters out ones that are empty strings
  const segments = pathname.split('/').filter(Boolean);
  if (segments[1] === japan) {
    segments[1] = singapore;
  } else if (segments[1] === singapore) {
    segments[1] = japan;
  }
  return `/${segments.join('/')}`;
}

export function UniverseSelector() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const iconSize = isDesktop ? DEFAULT_ICON_SIZE : ICON_SIZE_16;
  const { universeName, universeSlug } = useUniverse();

  return (
    <>
      <UniverseTrigger
        onClick={() => setIsOpen(true)}
        data-testid="Universe Selector Btn"
        data-value={universeSlug}
      >
        <Location size={iconSize} aria-label={universeName} />
        {t(`monolith.countries.${universeSlug}`)}
      </UniverseTrigger>

      <Panel
        isOpen={isOpen}
        title={t(`universe.select_region`)}
        onClose={() => setIsOpen(false)}
      >
        <SelectorWrapper
          data-is-desktop={isDesktop}
          data-testid="Universe Selector Panel"
        >
          <Item
            data-is-active={universeSlug === UniverseSlug.JP}
            data-variant="tertiary"
            type="button"
            data-testid="Universe Selector Japan"
            onClick={() => {
              cookieService.setUniverse(UniverseSlug.JP);
              setIsOpen(false);
              navigate(pathnameWithNewUniverse(pathname), {
                replace: true,
              });
            }}
          >
            <Title>{t(`monolith.countries.JP`)}</Title>
            <Text>{t('universe.japan_text')}</Text>
          </Item>
          <Item
            data-is-active={universeSlug === UniverseSlug.SG}
            data-variant="tertiary"
            type="button"
            data-testid="Universe Selector Singapore"
            onClick={() => {
              cookieService.setUniverse(UniverseSlug.SG);
              setIsOpen(false);
              navigate(pathnameWithNewUniverse(pathname), {
                replace: true,
              });
            }}
          >
            <Title>{t(`monolith.countries.SG`)}</Title>
            <Text>{t('universe.singapore_text')}</Text>
          </Item>
        </SelectorWrapper>
      </Panel>
    </>
  );
}
