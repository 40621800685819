import { z } from 'zod';

import { baseSchema } from './baseSchema';

export const stagingQaConfig: Partial<z.infer<typeof baseSchema>> = {
  VITE_BASE_TABLECHECK_URL: `https://staging-qa.tablecheck.com/`,
  VITE_BASE_INTERNAL_API_URL: `http://tc-v4-api-staging-qa.monolith-staging-qa/`,
  VITE_BASE_PROTOMAP_API_URL: `https://maps.ingress.production.tablecheck.com/tiles/20241111.json`,
};

export const stagingSnapConfig: Partial<z.infer<typeof baseSchema>> = {
  VITE_BASE_API_URL: `https://staging-snap.tablecheck.com/`,
  VITE_BASE_INTERNAL_API_URL: `http://tc-v4-api-staging-snap.monolith-staging-snap/`,
  VITE_BASE_AUTH_API_URL: `https://api-booking-staging-snap.tablecheck.com/`,
  VITE_BASE_SEARCH_AUTOCOMPLETE_API_URL: `https://staging-snap.tablecheck.com/`,
  VITE_BASE_TABLECHECK_URL: `https://staging-snap.tablecheck.com/`,
  VITE_BASE_PROTOMAP_API_URL: `https://maps.ingress.production.tablecheck.com/tiles/20241111.json`,
};
