import {
  Bookmark,
  Calendar,
  Home,
  LogoFacebook,
  LogoInstagram,
  LogoX,
  Search,
  UserAvatar,
} from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CONFIG } from '@local/configs';
import {
  BASE_SUPPORT_EMAIL,
  BASE_SUPPORT_SITE_URL,
  CAREERS_URL,
  FACEBOOK_BASE_URL,
  ICON_SIZE_24,
  INSTAGRAM_URL,
  X_BASE_URL,
} from '@local/constants';
import { useRouting, useUniverse } from '@local/utils';

export function useFooter() {
  const navigate = useNavigate();
  const [t, { language }] = useTranslation();
  const { universeName } = useUniverse();

  return {
    shouldShowFooter: !useRouting().isVenuePage,
    exploreOptions: [
      {
        title: t('layout.home'),
        action: () => navigate(`/${language}/${universeName}`),
      },
      {
        title: t('auth.login'),
        action: () => navigate(`/${language}/account/edit`),
      },
      {
        title: t('account.help'),
        action: () =>
          window.open(`${BASE_SUPPORT_SITE_URL}hc/${language}`, '_blank'),
      },
      {
        title: t('account.share_feedback'),
        action: () => {
          window.location.href = `mailto:${BASE_SUPPORT_EMAIL}?subject=[DPOR] User feedback on beta`;
        },
      },
    ],
    companyOptions: [
      {
        title: t('layout.about_us'),
        action: () => {
          window.open(
            `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/join/about-us/`,
            '_blank',
          );
        },
      },
      {
        title: t('layout.team'),
        action: () => {
          window.open(
            `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/join/about-us/team`,
            '_blank',
          );
        },
      },
      {
        title: t('layout.careers'),
        action: () => {
          window.open(CAREERS_URL, '_blank');
        },
      },
      {
        title: t('layout.press'),
        action: () => {
          window.open(
            `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/join/about-us/press/press-release/`,
            '_blank',
          );
        },
      },
    ],
    legalOptions: [
      {
        title: t('account.terms_conditions.diner_terms'),
        action: () => {
          window.open(
            `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/policy/terms`,
            '_blank',
          );
        },
      },
      {
        title: t('account.terms_conditions.privacy_policy'),
        action: () => {
          window.open(
            `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/policy/privacy`,
            '_blank',
          );
        },
      },
      {
        title: t('account.terms_conditions.payment_policy'),
        action: () => {
          window.open(
            `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/policy/payment`,
            '_blank',
          );
        },
      },
    ],
    snsOptions: [
      {
        icon: LogoInstagram,
        link: `${INSTAGRAM_URL}?hl=${language}`,
      },
      {
        icon: LogoX,
        link: `${X_BASE_URL}tablecheck?lang=${language}`,
      },
      {
        icon: LogoFacebook,
        link: `${FACEBOOK_BASE_URL}TableCheckGlobal`,
      },
    ],
    mobileActionBtns: [
      {
        key: 'home',
        path: `/${language}/${universeName}`,
        icon: <Home size={ICON_SIZE_24} />,
        testid: 'Mobile Footer Home Btn',
      },
      {
        key: 'explore',
        path: `/${language}/${universeName}/search`,
        icon: <Search size={ICON_SIZE_24} />,
        testid: 'Mobile Footer Explore Btn',
      },
      {
        key: 'lists',
        path: `/${language}/lists`,
        icon: <Bookmark size={ICON_SIZE_24} />,
        testid: 'Mobile Footer Lists Btn',
      },
      {
        key: 'bookings',
        path: `/${language}/account/reservations/upcoming`,
        icon: <Calendar size={ICON_SIZE_24} />,
        testid: 'Mobile Footer Reservations Btn',
      },
      {
        key: 'account',
        path: `/${language}/account`,
        icon: <UserAvatar size={ICON_SIZE_24} />,
        testid: 'Mobile Footer Account Btn',
      },
    ],
  };
}
