import { useMutation } from '@tanstack/react-query';

import { changePassword } from '../accountApi';
import { ChangePasswordSchema } from '../components/ChangePassword/changePasswordSchema';

export const useChangePasswordQuery = () =>
  useMutation({
    mutationFn: (data: ChangePasswordSchema) => changePassword(data),
    onError: (error) => error,
  });
