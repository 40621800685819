/* eslint-disable react-refresh/only-export-components */
import { styled } from '@linaria/react';
import { Drawer } from 'vaul';

const Overlay = styled(Drawer.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  height: 100%;
  z-index: var(--zindex-modal);
`;

const Content = styled(Drawer.Content)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  background-color: white;
  border-radius: 16px 16px 0 0;
  padding: var(--spacing-l4);
  border: 1px solid var(--grey-200);
  outline: none;
  z-index: var(--zindex-modal);
  & > * {
    width: 100%;
  }
`;

const Close = styled(Drawer.Close)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const Title = styled(Drawer.Title)`
  font: var(--h2);
`;

const Header = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-l5);
`;

const Body = styled.div`
  padding-top: var(--spacing-l4);
  display: inline-block;
  flex-direction: column;
  flex-wrap: wrap;

  overflow-y: none;
  min-height: max-content;
  max-height: var(--panel-body-height);
  & > * {
    width: 100%;
  }
`;

const Handle = styled(Drawer.Handle)`
  height: 4px;
  background: var(--grey-200);
  width: 24px;
  top: 0;
  border-radius: 2px;
`;

const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 85svh;
  flex: 1;
  overflow-y: auto;
  gap: var(--spacing-l4);
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  background: var(--surface);
  bottom: 0;
  gap: var(--spacing-l4);
  & > * {
    flex: 1;
  }
`;
export const TcDrawer = {
  Overlay,
  Content,
  Close,
  Title,
  Header,
  Body,
  Handle,
  ScrollableContent,
  Footer,
};

/* eslint-enable react-refresh/only-export-components */
