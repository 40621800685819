import { styled } from '@linaria/react';
import { t } from 'i18next';

import { CONFIG } from '@local/configs';

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100vh;
  padding: 25vh var(--spacing-l3);
  text-align: center;
`;

export const NotFoundMessage = styled.h2`
  color: grey;
`;

export const Logo = styled.img`
  height: 30px;
  width: 100%;
`;

export function NotFound() {
  return (
    <NotFoundWrapper data-testid="Not Found Root">
      <Logo
        alt="TableCheck logo"
        src={`${CONFIG.VITE_BASE_CDN_IMAGES_URL}logos/tc/v1.0.0/logo.svg`}
      />
      <NotFoundMessage>{t('errors.page_not_found')}</NotFoundMessage>
      <span>{t('tablecheck.tablecheck_inc')}</span>
    </NotFoundWrapper>
  );
}
