import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';

import { deleteMembership } from '../accountApi';

export const useDeleteMembershipQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteMembership,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY, 'memberships'],
      });
    },
    onError: (error) => console.error(error),
  });
};
