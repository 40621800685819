import {
  DirectionBearRight_02 as Direction,
  Phone,
  Share,
} from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import { DEFAULT_ICON_SIZE } from '@local/constants';
import type { ReservationVenueInfo, Reservation } from '@local/types';

import { useActionBtns } from './useActionBtns';

export const ActionBtnsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-l3);
  height: 40px;
`;

export const CTAButton = styled(Button)`
  flex-grow: 1;
  max-width: 324px;
`;

export const ActionBtn = styled.button`
  padding: 10px;
  border: 1px solid var(--lighter-grey-border);
  border-radius: var(--border-radius-large);
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export function ActionBtns({
  reservation,
  venue,
}: {
  reservation: Reservation;
  venue: ReservationVenueInfo;
}): JSX.Element {
  const { handleShare, handleDirections, handleCall, handleManageReservation } =
    useActionBtns(reservation, venue);

  return (
    <ActionBtnsWrapper data-testid="Reservation Actions Btns">
      <CTAButton data-variant="secondary" onClick={handleManageReservation}>
        {t('reservations_feat.manage')}
      </CTAButton>

      <ActionBtn onClick={handleDirections}>
        <Direction size={DEFAULT_ICON_SIZE} />
      </ActionBtn>
      <ActionBtn onClick={handleCall}>
        <Phone size={DEFAULT_ICON_SIZE} />
      </ActionBtn>
      <ActionBtn onClick={() => void handleShare()}>
        <Share size={DEFAULT_ICON_SIZE} />
      </ActionBtn>
    </ActionBtnsWrapper>
  );
}
