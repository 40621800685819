import { styled } from '@linaria/react';
import { TabButton } from '@tablecheck/tablekit-react-css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetUserQuery } from '@local/auth';
import { Scope } from '@local/types';

import { useFetchFutureReservations } from '../../hooks/useFetchFutureReservations';
import { useFetchPastReservations } from '../../hooks/useFetchPastReservations';

export const ScopeSelectorWrapper = styled.div`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0.5px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid var(--lighter-grey-border);
  }
`;

export function ScopeSelector({
  scope,
  setScope,
}: {
  scope: Scope;
  setScope: React.Dispatch<React.SetStateAction<Scope>>;
}): JSX.Element {
  const [t, { language }] = useTranslation();
  const navigate = useNavigate();
  const { data: user } = useGetUserQuery();
  const { data: reservationsFutureData } = useFetchFutureReservations(
    user,
    scope,
  );
  const { data: reservationsPastData } = useFetchPastReservations(user, scope);

  return (
    <ScopeSelectorWrapper data-testid="Scope Selector">
      <TabButton
        aria-controls="future-reservations"
        aria-selected={scope === 'future'}
        onClick={() => {
          setScope('future');
          navigate(`/${language}/account/reservations/upcoming`);
          window.scrollTo(0, 0);
        }}
      >
        {t('reservations_feat.upcoming')}&nbsp;
        {!!reservationsFutureData && (
          <span data-testid="Total Reservations">
            ({reservationsFutureData.pages[0].meta.record_count})
          </span>
        )}
      </TabButton>

      <TabButton
        aria-controls="past-reservations"
        aria-selected={scope === 'past'}
        onClick={() => {
          setScope('past');
          navigate(`/${language}/account/reservations/past`);
          window.scrollTo(0, 0);
        }}
      >
        {t('reservations_feat.past')}&nbsp;
        {!!reservationsPastData && (
          <span data-testid="Total Reservations">
            ({reservationsPastData.pages[0].meta.record_count})
          </span>
        )}
      </TabButton>
    </ScopeSelectorWrapper>
  );
}
