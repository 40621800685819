import { styled } from '@linaria/react';

export const Catchphrase = styled.span`
  max-width: 300px;
  line-height: 1.3;
`;

export const OverlayContent = styled.div`
  padding: 0 var(--spacing-l4);
  position: absolute;
  top: 15svh;
  display: flex;
  flex-direction: column;
  width: 100svw;

  & > span {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: var(--spacing-l5);
    color: white;
  }
  & form {
    padding: 0;
    max-width: var(--search-input-width);
    div[data-no-shadow='true'] {
      background: var(--surface);
    }
  }
  & [data-search-results='true'] {
    position: static;
    margin: 0 calc(var(--spacing-l4) * -1);
    width: var(--search-input-width);
  }
`;

export const BackgroundImg = styled.img`
  position: relative;
  width: 100svw;
  height: 45svh;
  object-fit: cover;
`;
