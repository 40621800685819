import { styled } from '@linaria/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { ContentCard } from '@local/types';
import { buildUrl, useUniverse } from '@local/utils';

const Wrapper = styled.div`
  cursor: pointer;
  p {
    font: var(--label);
  }
`;

const Image = styled.img`
  height: 112px;
  margin-bottom: var(--spacing-l1);
  border-radius: var(--border-radius-large);
  object-fit: cover;
`;

interface Props {
  card: ContentCard;
  defaultParams: { date: string; time: string; num_people: string };
}

export function StoryblokCard({ card, defaultParams }: Props) {
  const navigate = useNavigate();
  const [t, { language }] = useTranslation();
  const { universeName } = useUniverse();
  const isCuisineType = 'key' in card;
  const { text, url, dataTestId } = isCuisineType
    ? {
        text: t(`cuisine.${card.key.toLowerCase().replace(/ /g, '_')}`),
        url: buildUrl(`/${language}/${universeName}/search`, {
          ...defaultParams,
          'cuisines[]': card.key,
        }),
        dataTestId: 'Cuisine Card',
      }
    : {
        text: t(`area.${card.name.toLowerCase().replace(/ /g, '_')}`),
        url: buildUrl(`/${language}/${universeName}/search`, {
          ...defaultParams,
          geo_latitude: card.latitude,
          geo_longitude: card.longitude,
          geo_distance: card.distance,
          auto_geolocate: 'false',
        }),
        dataTestId: 'Area Card',
      };

  return (
    <Wrapper data-testid={dataTestId} onClick={() => navigate(url)}>
      <Image src={card.image.filename} alt={text} />
      <p>{text}</p>
    </Wrapper>
  );
}
