import { useMutation } from '@tanstack/react-query';

import type { ResetPasswordDataType } from '@local/types';

import { resetPassword } from '../authApi';

export const useResetPasswordQuery = () =>
  useMutation({
    mutationFn: (data: ResetPasswordDataType) => resetPassword(data),
    onError: (error) => console.error(error),
  });
