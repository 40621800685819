import { useAtom } from 'jotai';
import * as React from 'react';

import {
  DEFAULT_LATITUDE_TOKYO,
  DEFAULT_LONGITUDE_TOKYO,
} from '@local/constants';
import type {
  MapMovementDetail,
  MapServiceApi,
  UseMapServiceOptions,
} from '@local/types';

import { MapService } from '../map/mapService';
import { selectedVenueAtom } from '../map/mapStore';

export const DEFAULT_ZOOM_LEVEL = 10;
const MAP_EVENTS = {
  MOVEMENT: 'mapMovement',
  VENUE_SELECTED: 'venueSelected',
  LOADED: 'mapLoaded',
};

export const useMapService = ({
  venues = [],
  language,
  center = [DEFAULT_LONGITUDE_TOKYO, DEFAULT_LATITUDE_TOKYO],
  zoom,
  containerId,
  interactive = true,
  geolocateControl,
  cameraTransition = 'flyTo',
}: UseMapServiceOptions): MapServiceApi => {
  const mapServiceRef = React.useRef<MapService | null>(null);
  const [selectedVenue, setSelectedVenue] = useAtom(selectedVenueAtom);

  const [selectedVenueId, setSelectedVenueId] = React.useState<string | null>(
    null,
  );
  const [movementDetail, setMovementDetail] =
    React.useState<MapMovementDetail | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    mapServiceRef.current = new MapService({
      venues,
      language,
      center,
      zoom,
      containerId,
      interactive,
      hideControlsOnSelect: geolocateControl?.hideOnSelect,
    });

    const handleVenueSelected = (event: CustomEvent<string>) => {
      setSelectedVenueId(event.detail);
    };
    const handleMapMovement = (event: CustomEvent<MapMovementDetail>) => {
      setMovementDetail(event.detail);
    };
    const handleMapLoaded = () => {
      setIsLoading(false);
    };

    mapServiceRef.current.eventTarget.addEventListener(
      MAP_EVENTS.VENUE_SELECTED,
      handleVenueSelected as EventListener,
    );
    mapServiceRef.current.eventTarget.addEventListener(
      MAP_EVENTS.MOVEMENT,
      handleMapMovement as EventListener,
    );
    mapServiceRef.current.eventTarget.addEventListener(
      MAP_EVENTS.LOADED,
      handleMapLoaded as EventListener,
    );

    return () => {
      mapServiceRef.current?.cleanup();
      mapServiceRef.current?.eventTarget.removeEventListener(
        MAP_EVENTS.VENUE_SELECTED,
        handleVenueSelected as EventListener,
      );
      mapServiceRef.current?.eventTarget.removeEventListener(
        MAP_EVENTS.MOVEMENT,
        handleMapMovement as EventListener,
      );
      mapServiceRef.current?.eventTarget.removeEventListener(
        MAP_EVENTS.LOADED,
        handleMapLoaded as EventListener,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isLoading || !interactive) return;

    mapServiceRef.current?.updateVenues(venues);
    mapServiceRef.current?.fitBoundsToMarkers(cameraTransition);
  }, [venues, isLoading, interactive, cameraTransition]);

  React.useEffect(() => {
    if (selectedVenueId) {
      const foundVenue =
        venues.find((venue) => venue.id === selectedVenueId) ?? null;
      setSelectedVenue({
        venue: foundVenue,
        shouldShowMapVenueCard: !!foundVenue,
      });
    } else {
      setSelectedVenue({ venue: null, shouldShowMapVenueCard: false });
    }
  }, [selectedVenueId, venues, setSelectedVenue]);

  React.useEffect(() => {
    if (!isLoading && language) {
      mapServiceRef.current?.changeLanguage(language);
    }
  }, [language, isLoading]);

  React.useEffect(() => {
    if (isLoading || !geolocateControl?.auto) return;

    if (geolocateControl?.usersLocation)
      mapServiceRef.current?.instance.flyTo({
        center: [
          geolocateControl?.usersLocation.longitude,
          geolocateControl?.usersLocation.latitude,
        ],
      });
    mapServiceRef.current?.triggerGeolocate();
  }, [isLoading, geolocateControl?.usersLocation, geolocateControl?.auto]);

  return {
    isLoading,
    movementDetail,
    selectedVenue,
    instance: mapServiceRef.current?.instance,
  };
};
