import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { useGetUserQuery } from '@local/auth';
import { Spinner } from '@local/components';
import { TABLET_BREAKPOINT } from '@local/constants';
import { useFetchAllListsQuery } from '@local/list';
import { useFetchUserFavoritesVenueQuery } from '@local/user';
import { useTypedRouteParams } from '@local/utils';
import {
  useFetchExperiencesQuery,
  useFetchGalleryQuery,
  useFetchMenuItemsQuery,
  useFetchVenueDataQuery,
} from '@local/venue';

import { VenueDesktop } from './VenueDesktop';
import { VenueMobile } from './VenueMobile';

interface VenueRouteParams {
  venueSlug: string;
}

export function VenueRoot() {
  const { venueSlug } = useTypedRouteParams<VenueRouteParams>();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });

  const { data: user } = useGetUserQuery();
  const { data: venueData, status: venueStatus } =
    useFetchVenueDataQuery(venueSlug);
  const { data: favoritesData } = useFetchUserFavoritesVenueQuery(
    venueSlug,
    user,
  );
  const { data: gallery } = useFetchGalleryQuery(venueSlug);
  const { data: menuItemsData } = useFetchMenuItemsQuery(venueSlug);
  const menuItems = menuItemsData?.menu_items ?? [];
  const { data: experiences, status: experiencesStatus } =
    useFetchExperiencesQuery(venueSlug);
  const { data: allLists } = useFetchAllListsQuery(user);

  const shouldShowVenue =
    !!venueData && venueData?.shops.length > 0 && venueStatus === 'success';

  // TODO: will be removed with DPOR-435
  const [allSavedShopsSet, setAllSavedShopsSet] = React.useState<Set<string>>(
    new Set<string>(),
  );
  React.useEffect(() => {
    if (allLists?.lists)
      setAllSavedShopsSet(
        new Set<string>(allLists.lists.flatMap((item) => item.shop_ids)),
      );
    else setAllSavedShopsSet(new Set<string>());
  }, [allLists, user]);

  if (!shouldShowVenue) return <Spinner isFullPage />;

  const shouldShowExperiences =
    !!experiences &&
    experiences.menu_items.length > 0 &&
    experiencesStatus === 'success';

  return (
    <div data-testid="Venue Page Root">
      {isDesktop ? (
        <VenueDesktop
          venueSlug={venueSlug}
          venueData={venueData}
          gallery={gallery}
          menuItems={menuItems}
          favoritesData={favoritesData}
          allSavedShopsSet={allSavedShopsSet}
          experiences={experiences}
          shouldShowMenuItems={menuItems.length > 0}
          shouldShowExperiences={shouldShowExperiences}
        />
      ) : (
        <VenueMobile
          venueSlug={venueSlug}
          venueData={venueData}
          gallery={gallery}
          menuItems={menuItems}
          favoritesData={favoritesData}
          allSavedShopsSet={allSavedShopsSet}
          experiences={experiences}
          shouldShowMenuItems={menuItems.length > 0}
          shouldShowExperiences={shouldShowExperiences}
        />
      )}
    </div>
  );
}
