import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';

import { updateUserMemoVenue } from '../userApi';

export const useUpdateUserMemoQuery = (venueSlug: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: string) => updateUserMemoVenue({ venueSlug, data }),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [USER_QUERY_KEY, 'memo', venueSlug],
        })
        .catch(console.error);
    },
    onError: (error) => {
      console.error('Error updating user memo:', error);
    },
  });
};
