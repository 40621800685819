import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';

import { editProfile } from '../accountApi';
import type { EditProfileSchema } from '../components/EditProfileForm/editProfileSchema';

export const useEditProfileQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EditProfileSchema) => editProfile(data),
    onSuccess: (userData) => {
      queryClient.setQueryData([USER_QUERY_KEY], userData);
    },
    onError: (error) => console.error(error),
  });
};
