import { ChevronRight, FavoriteFilled } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Checkbox, IconButton } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import { DEFAULT_ICON_SIZE, ICON_SIZE_24 } from '@local/constants';

const ListCardWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--spacing-l3);
  padding: var(--spacing-l4) 0;
  cursor: pointer;
  flex-shrink: 0;
  border-bottom: 1px solid var(--lighter-grey-border);

  &[data-disabled='true'] {
    border-bottom: 1px solid #0000003a;
    cursor: not-allowed;
    opacity: 0.5;
  }

  & > img {
    height: 48px;
    width: 48px;
    border-radius: var(--border-radius-small);
    object-fit: cover;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: var(--border-radius-small);
  background-color: var(--surface-raised-hover);
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--spacing-l3);
  overflow: hidden;
`;

const CardText = styled.h4`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l1);
  font: var(--body-1);
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;

  & > span {
    font: var(--body-2);
    color: var(--text-subtle);
  }
`;

interface BaseProps {
  name: string;
  shopIds: string[];
  isFavorite: boolean;
  imageUrl: string;
  isDisabled?: boolean;
}

interface SelectableListCardProps extends BaseProps {
  type: 'selectable';
  id: string;
  isChecked: boolean;
  onSelect: (id: string) => void;
}

interface ClickableListCardProps extends BaseProps {
  type: 'clickable';
  onClick: () => void;
}

type ListCardProps = SelectableListCardProps | ClickableListCardProps;

export function ListCard({
  name,
  shopIds,
  isFavorite,
  imageUrl,
  isDisabled,
  ...props
}: ListCardProps) {
  const isSelectable = props.type === 'selectable';
  const { isChecked, id } = props as SelectableListCardProps;
  const handleInteraction = () => {
    if (isSelectable) {
      props.onSelect(props.id);
    } else if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <ListCardWrapper
      onClick={handleInteraction}
      data-testid={isSelectable ? 'List Selectable Card' : 'List Card'}
      data-checked={isChecked}
      data-disabled={isDisabled}
    >
      {isFavorite ? (
        <IconWrapper>
          <FavoriteFilled size={ICON_SIZE_24} fill="var(--text-subtle)" />
        </IconWrapper>
      ) : (
        <img src={imageUrl || DEFAULT_VENUE_IMAGE} alt={name} />
      )}
      <CardContent>
        <CardText>
          {isFavorite ? t('list_feat.favorites') : name}
          <span>
            {t('list_feat.venue_number', {
              count: shopIds.length,
            })}
          </span>
        </CardText>
        {isSelectable ? (
          <Checkbox
            name={id}
            checked={isChecked}
            onChange={() => {}}
            disabled={isDisabled}
          />
        ) : (
          <IconButton data-variant="bare">
            <ChevronRight size={DEFAULT_ICON_SIZE} />
          </IconButton>
        )}
      </CardContent>
    </ListCardWrapper>
  );
}
