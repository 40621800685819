import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
// Custom hook for tracking page views
export const usePageViews = () => {
  const location = useLocation();
  const posthog = usePostHog();

  React.useEffect(() => {
    // Capture page view
    if (typeof posthog !== 'undefined' && typeof window !== 'undefined') {
      posthog.capture('$pageview', {
        path: location.pathname,
        url: window.location.href,
        referrer: document.referrer,
      });
    }
  }, [location, posthog]);
};
