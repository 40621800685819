import { useInfiniteQuery } from '@tanstack/react-query';

import { SHOP_SEARCH_QUERY_KEY, STALETIME_5_MINUTES } from '@local/constants';
import { useUniverse } from '@local/utils';

import { fetchShopBySearchParams } from '../searchApi';

export const useShopSearchQuery = (
  queryParams: URLSearchParams,
  isEnabled = true,
) => {
  const { universeId } = useUniverse();
  return useInfiniteQuery({
    queryKey: [SHOP_SEARCH_QUERY_KEY, queryParams.toString()],
    queryFn: ({ pageParam }) => {
      if (pageParam) queryParams.append('search_after', pageParam);
      return fetchShopBySearchParams(queryParams, universeId);
    },
    staleTime: STALETIME_5_MINUTES,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta.last_page) return;
      return lastPage?.meta.search_after;
    },
    initialPageParam: '',
    select: (data) => ({
      pages: data.pages,
      meta: data.pages.at(-1)?.meta,
      shops: data.pages.flatMap((page) => page?.shops ?? []),
    }),
    enabled: isEnabled,
  });
};
