import { styled } from '@linaria/react';
import * as RadixToast from '@radix-ui/react-toast';
import { useAtom } from 'jotai';
import { useMediaQuery } from 'react-responsive';

import { TABLET_BREAKPOINT } from '@local/constants';

import { toastContentAtom, toastIsOpenAtom } from './toastStore';

const TOAST_OPEN_DURATION = 3000;

const ToastViewPort = styled(RadixToast.Viewport)`
  bottom: 84px;
  left: var(--spacing-l4);
  position: fixed;
  z-index: var(--zindex-modal);
  width: 100%;
  &[data-is-desktop='true'] {
    bottom: 24px;
    max-width: fit-content;
    min-width: 360px;
    left: auto;
    right: var(--spacing-l4);
  }

  // TODO: Remove this once the toast component is set globally
  & > li:empty {
    display: none;
  }
`;

const ToastRoot = styled(RadixToast.Root)`
  display: grid;
  grid-template-columns: 20px auto 40px;
  gap: var(--spacing-l2);
  background-color: var(--grey-950);
  color: var(--white);
  padding: var(--spacing-l3);
  margin-right: var(--spacing-l6);
  border-radius: var(--border-radius-large);
  align-items: center;

  & span {
    overflow: hidden;
    white-space: nowrap;
    &[data-overflow='visible'] {
      overflow: visible;
    }
  }
`;

export function Toast() {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  const [isToastOpen, setIsToastOpen] = useAtom(toastIsOpenAtom);
  const [toastContent] = useAtom(toastContentAtom);
  return (
    <RadixToast.Provider>
      <ToastViewPort data-is-desktop={isDesktop} />
      <ToastRoot
        open={isToastOpen}
        onOpenChange={setIsToastOpen}
        duration={TOAST_OPEN_DURATION}
      >
        {toastContent}
      </ToastRoot>
    </RadixToast.Provider>
  );
}
