import { useQuery } from '@tanstack/react-query';

import { STALETIME_1_HOUR } from '@local/constants';
import { useUniverse } from '@local/utils';

import { fetchSearchSuggestions } from '../searchApi';
import { SEARCH_SUGGESTIONS_MIN_LENGTH } from '../searchConstants';

export const useFetchSearchSuggestionsQuery = (
  searchText: string,
  locale: string,
) => {
  const { universeId } = useUniverse();
  return useQuery({
    queryKey: ['searchSuggestions', searchText, locale],
    queryFn: () => fetchSearchSuggestions(searchText, locale, universeId),
    staleTime: STALETIME_1_HOUR,
    enabled: searchText.length >= SEARCH_SUGGESTIONS_MIN_LENGTH,
    select: (data) => ({
      suggestions: [
        ...(data.cuisines ?? []),
        ...(data.locations ?? []),
        ...(data.shops ?? []),
      ],
    }),
  });
};
